<template>
  <div class="load-more">
    <button :class="[ctaClass, { 'disabled': isLoadMoreDisabled || productsLength === +productsTotalCount }]"
            :disabled="isLoadMoreDisabled || productsLength === +productsTotalCount"
            @click.prevent="updateList"
            :aria-label="label"
            v-tag:useractionevent="{ category: pageCategory, action: 'select::list pagination', label: `${getLoadMoreQuery.pageNbr - 1}`}"
    >
     <span class="btn__wrapper"> <slot/> </span>
    </button>
    <loader :show="productsLoading">
      <slot name="loading"/>
    </loader>
  </div>
</template>
<script>
  import Loader from '@Feature/Loader/code/Scripts/components/loader';
  import { tag } from '@Foundation/analyticsHandler';
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: 'load-more',
    directives: {
      tag
    },
    components: {
      Loader
    },
    props: {
      label: { type: String, default: '' },
      pageCategory: { type: String, default: '' },
      productListId: { type: String, required: true, default: '' },
      apiLink: { type: String, required: true, default: '' },
      productsTotalCount: { type: [String, Number], required: false, default: undefined },
      urlQuery: { type: Object, required: true },
       ctaClass: {type: String, default: 'cta cta--light'},
    },

    computed: {
      ...mapGetters(['productsLoading', 'getListType', 'getLoadMoreQuery','getItemToFocus', 'isLoadMoreDisabled']),
      searchPageQuery() {
        return window.location.search !== '' ? (window.location.search.match(/(q=).*?(&)/g)[0]).slice(2,-1) : '';
      },
      productsLength() {
        return this.$store.getters.getProducts(this.productListId).length;
      },
      listType() {
        return this.urlQuery.section.toLowerCase();
      }
    },

    created() {
      this.$store.commit('loadMoreQuery', { q: this.searchPageQuery, ...this.urlQuery });
      this.$store.commit('setListType', this.listType);
    },

    methods: {
      ...mapActions(['loadMore']),
      updateList() {
        this.loadMore({ listId: this.productListId, url: this.apiLink, query: this.getLoadMoreQuery, searchPageQuery: this.searchPageQuery, type: this.getListType })
          .then((data) => {
             document.querySelectorAll(`#${this.productListId} .product-list__item`)[this.getItemToFocus].querySelector('a').focus();
          }).catch((err) => {
                // silence console output type error for unit test as we dont have the dom of the "product list"
                // TypeError: Cannot read property 'querySelector' of undefined
          });
      }
    }
  };
</script>
<style lang='scss' src="./load-more.scss"></style>
