/**
 * Create a directive which set the position of the element from the binding.value
 * above the element with this directive
 */
export const setposition = {
  bind: (el, binding) => {
    const elementToUpdate = el.querySelector(binding.value);
    let interval = false;
    interval = setInterval(() => {
      /* istanbul ignore else */
      if (el.clientHeight > 0) {
        clearInterval(interval);
        setTimeout(() => {
          elementToUpdate.style.bottom = `${el.clientHeight + 1}px`;
        }, 300);
      }
    }, 100);
  }
};
