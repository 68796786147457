<template>
  <div class="countdown-page">
    <h3>{{ title }}</h3>
    <div class="brand-logo">
      <span>
        <responsive-image :srcset="srcsetLogo" animate="">
          <img src="/frontend/master/images/placeholder.png" alt="placeholder">
          <noscript>
            <img :src="this.imageUrl" alt="">
          </noscript>
        </responsive-image>
      </span>
      <span>
        <responsive-image :srcset="srcsetIcon" animate="">
          <img src="/frontend/master/images/placeholder.png" alt="placeholder">
          <noscript>
            <img :src="this.imageUrl" alt="">
          </noscript>
        </responsive-image>
      </span>
    </div>
    <h2>{{ waitingText }} {{ countdownLeft }} {{ secondsText }}</h2>
    <p>{{ description }}</p>
  </div>
</template>
<script>
  import ResponsiveImage from '@Feature/ResponsiveImage/code/Scripts/components/responsive-image.vue';

  export default {
    name: 'countdown',
    components: { ResponsiveImage },
    props: {
      imageUrl: { type: String, default: '' },
      srcsetLogo: {
        type: [Object, String],
        required: false,
        default: undefined
      },
      srcsetIcon: {
        type: [Object, String],
        required: false,
        default: undefined
      },
      description: {
        type: String,
        required: false,
        default: undefined
      },
      timerText: {
        type: String,
        required: false,
        default: undefined
      },
      title: {
        type: String,
        required: false,
        default: undefined
      },
      secondsText: {
        type: String,
        required: false,
        default: undefined
      },
      waitingText: {
        type: String,
        required: false,
        default: undefined
      },
      ctaLink: {
        type: String,
        required: false,
        default: undefined
      }
    },
    data() {
      return {
        countdownLeft: 0,
      };
    },
    created() {
      const timeLeft = parseInt(this.timerText);
      this.countdownLeft = timeLeft;
      this.countDownTimer();
    },
    methods: {
      countDownTimer () {
        if (this.countdownLeft > 0) {
          setTimeout(() => {
            this.countdownLeft -= 1;
            this.countDownTimer();
          }, 1000);
        } else {
           window.location.href = this.ctaLink;
        }
      }
    },
  };
</script>
<style lang="scss" src="./countdown.scss"></style>
