<template>
    <div class="true-or-false">
        <div class="true-or-false__title" v-html="initialData.Title">
        </div>
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in slides" :key="index" :data-index="index">
                    <div class="true-or-false__container--cards__card"
                        :class="[{ 'fliper-active': toggleFlipIdx === index }]">
                        <div class="card-view" :class="[{ 'fliper': toggleFlipIdx === index }]">
                            <div class="front">
                                <div class="true-or-false__container--cards__card-imagewrapper">
                                    <img class="swiper-lazy" :alt="item.ImageAlt" :src="item.ImageSrc" />
                                </div>
                                <div class="true-or-false__container--cards__card_content">
                                    <div class="true-or-false__container--cards__card_content-title"
                                        v-html="item.Question">
                                    </div>
                                    <div class="true-or-false__container--cards__card_content-footer">
                                        <button @click.prevent="toggleFlip(index)" :data-index="index">
                                            <svg width="40" height="41" viewBox="0 0 40 41" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0 20.5059C0 9.46017 8.95431 0.505859 20 0.505859C31.0457 0.505859 40 9.46017 40 20.5059C40 31.5516 31.0457 40.5059 20 40.5059C8.95431 40.5059 0 31.5516 0 20.5059Z"
                                                    fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M28.401 15.953L17.2019 25.9698L11.5377 19.0871L12.4643 18.3245L17.3339 24.2418L27.601 15.0586L28.401 15.953Z"
                                                    fill="black" />
                                            </svg>
                                        </button>
                                        <button @click.prevent="toggleFlip(index)" :data-index="index">
                                            <svg width="40" height="41" viewBox="0 0 40 41" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0 20.5059C0 9.46017 8.95431 0.505859 20 0.505859C31.0457 0.505859 40 9.46017 40 20.5059C40 31.5516 31.0457 40.5059 20 40.5059C8.95431 40.5059 0 31.5516 0 20.5059Z"
                                                    fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M19.1514 20.5058L11.5757 28.0815L12.4242 28.9301L19.9999 21.3543L27.5757 28.9301L28.4242 28.0815L20.8485 20.5058L28.4242 12.9301L27.5757 12.0815L19.9999 19.6573L12.4242 12.0815L11.5757 12.9301L19.1514 20.5058Z"
                                                    fill="black" />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="back true-or-false__container--cards__card_content">
                                <div class="true-or-false__container--cards__card_content--text">
                                    <div class="true-or-false__container--cards__card_content-icon">
                                        <div v-if="item.TrueOrFalse">
                                            <svg width="40" height="41" viewBox="0 0 40 41" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0 20.5059C0 9.46017 8.95431 0.505859 20 0.505859C31.0457 0.505859 40 9.46017 40 20.5059C40 31.5516 31.0457 40.5059 20 40.5059C8.95431 40.5059 0 31.5516 0 20.5059Z"
                                                    fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M28.401 15.953L17.2019 25.9698L11.5377 19.0871L12.4643 18.3245L17.3339 24.2418L27.601 15.0586L28.401 15.953Z"
                                                    fill="black" />
                                            </svg>
                                        </div>
                                        <div v-if="!item.TrueOrFalse">
                                            <svg width="40" height="41" viewBox="0 0 40 41" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0 20.5059C0 9.46017 8.95431 0.505859 20 0.505859C31.0457 0.505859 40 9.46017 40 20.5059C40 31.5516 31.0457 40.5059 20 40.5059C8.95431 40.5059 0 31.5516 0 20.5059Z"
                                                    fill="white" />
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M19.1514 20.5058L11.5757 28.0815L12.4242 28.9301L19.9999 21.3543L27.5757 28.9301L28.4242 28.0815L20.8485 20.5058L28.4242 12.9301L27.5757 12.0815L19.9999 19.6573L12.4242 12.0815L11.5757 12.9301L19.1514 20.5058Z"
                                                    fill="black" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="true-or-false__container--cards__card_content-header"
                                        v-html="item.Title">

                                    </div>
                                    <div class="true-or-false__container--cards__card_content-description"
                                        v-html="item.Description">

                                    </div>
                                    <div class="true-or-false__container--cards__card_content-cta"
                                        v-if="item.CtaLink != null">
                                        <a :href="item.CtaLink" @click="btnTagging(item.CtaLink)"
                                            class="cta--secondary">{{
                                                ctaLabel }}</a>
                                    </div>
                                </div>
                                <div class="true-or-false__container--cards__card_content--close">
                                    <button @click="toggleFlip(-1), toggleFlipIdx = -1" class="flip__card--close"
                                        data-index="-1">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                            viewBox="0 0 24 24" fill="none">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M11.1514 11.9999L3.57568 19.5757L4.42421 20.4242L11.9999 12.8485L19.5757 20.4242L20.4242 19.5757L12.8485 11.9999L20.4242 4.42421L19.5757 3.57568L11.9999 11.1514L4.42421 3.57568L3.57568 4.42421L11.1514 11.9999Z"
                                                fill="black" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="!desktopScreen" class="swiper-pagination" slot="pagination"
                :class="initialData.ListItems.length === 1 ? 'disable' : ''"></div>
            <div class="true-or-false-carousel__navigation" v-if="desktopScreen">
                <div @click="prev()"
                    class="true-or-fasle-carousel__arrow true-or-faslecarousel__arrow--left swiper-button-prev"
                    :class="(swiperAtBegain === true ? '' : 'disabled') || (initialData.ListItems.length === 1 ? 'disabled' : '')">
                    <svg width="42" height="8" viewBox="0 0 42 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M0 3.99996L3.85762 0.146484L4.56547 0.853599L1.91619 3.50002L42 3.50003V4.50003L1.91628 4.50002L4.56548 7.14649L3.85762 7.85359L0 3.99996Z"
                            fill="black" />
                    </svg>
                </div>
                <div @click="next()" :class="(swiperAtEnd === true ? '' : 'disabled') || (initialData.ListItems.length === 1 ? 'disabled' : '')"
                    class="true-or-fasle-carousel__arrow true-or-fasle-carousel__arrow--right swiper-button-next">
                    <svg width="42" height="8" viewBox="0 0 42 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M42 3.99996L38.1424 0.146484L37.4345 0.853599L40.0838 3.50002L1.90735e-05 3.50003V4.50003L40.0837 4.50002L37.4345 7.14649L38.1424 7.85359L42 3.99996Z"
                            fill="black" />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper';
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
export default {
    name: 'true-or-false',
    props: {
        initialData: { type: Object, required: true },
        ctaLabel: { type: String, required: true },
        pageCtegory: { type: String, default: 'pagecategory' },
        navTaggingLabel: { type: [String, Array], required: false, default: '' },
    },
    data() {
        return {
            // slides: ['a', 'b', 'c', 'd'],
            swiper: null,
            activeIndex: 0,
            toggleFlipIdx: null,
            desktopScreen: window.matchMedia('(min-width: 768px)').matches,
            swiperAtEnd: true,
            swiperAtBegain: false,
        };
    },
    computed: {
        slides() {
            return this.initialData.ListItems
        },
        slidesLoop() {
            return this.slides.length > 2
        }
    },
    mounted() {
        const self = this;
        self.initSwiper();
        self.$nextTick(() => {
            self.swiper.on('click', () => {
                self.attachEventListeners();
            });
            self.attachEventListeners();
        });
        if (!this.desktopScreen) {
            this.swiper.pagination.bullets.on('click', (e) => {
                let index = parseInt(e.target.dataset.index);
                this.navigationTagging(index, 'dot');
            })
        }

    },
    methods: {
        initSwiper() {
            this.swiper = new Swiper('.true-or-false .swiper-container', {
                loop: this.slidesLoop,
                slidesPerView: 3,
                centeredSlides: true,
                spaceBetween: 0,
                speed: 350,
                noSwiping: false,
                allowTouchMove: !this.desktopScreen ? true : false,
                keyboard: true,
                a11y: true,
                effect: 'coverflow',
                coverflowEffect: {
                    depth: 380,
                    modifier: 1,
                    slideShadows: false,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    type: "bullets",
                    renderBullet: (index) => {
                        return `<div class="swiper-pagination-bullet" data-type="dot" data-index="${index}"></div>`;
                    }
                },
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 1.2,
                        coverflowEffect: {
                            depth: 0
                        }
                    },
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 3,
                    }
                },
                on: {
                    realIndexChange: this.onSlideChange
                }
            });
        },
        toggleFlip(index) {
            let doc = document;
            this.toggleFlipIdx = index;
            doc.querySelectorAll('.true-or-false__container--cards__card').forEach(item => {
                item.classList.remove('fliper-active');
                item.classList.remove('fliper');
            });
            if (index !== -1) {
                let element = doc.querySelectorAll('[data-swiper-slide-index="' + index + '"]');
                element.forEach(e => {
                    e.getElementsByClassName('true-or-false__container--cards__card')[0].classList.add('fliper-active');
                    e.getElementsByClassName('card-view')[0].classList.add('fliper');
                });
            }
        },
        onSlideChange() {
            // this.toggleFlipIdx = null;
            this.attachEventListeners();
        },
        attachEventListeners(target) {
            const buttons = target || document.querySelectorAll('.swiper-slide-duplicate button');
            buttons.forEach(button => {
                button.removeEventListener('click', this.handleClick);
                button.addEventListener('click', this.handleClick)
            });
        },
        handleClick(event) {
            if (event) {
                const index = event.currentTarget.getAttribute('data-index');
                const currentTargetHasClass = event.currentTarget.closest('.true-or-false__container--cards__card').classList;
                this.toggleFlip(parseInt(index, 10));
                if (!currentTargetHasClass.contains('fliper')) {
                    currentTargetHasClass.add('fliper');
                }
                if (event.currentTarget.classList.contains('flip__card--close')) {
                    currentTargetHasClass.remove('fliper');
                }
            }
        },
        next() {
            this.swiperAtBegain = true;
            if (!this.slidesLoop) {
                this.swiperAtEnd = false;
            }
            let index = parseInt(this.swiper.realIndex);
            let currentIndex = index + 1;
            let itemCount = this.slides.length;
            if (currentIndex === itemCount) {
                currentIndex = 0;
            }
            console.log('next' + currentIndex);
            this.navigationTagging(currentIndex, 'arrow');

        },
        prev() {
            if (!this.slidesLoop) {
                this.swiperAtEnd = true;
                this.swiperAtBegain = false;
            }
            let index = parseInt(this.swiper.realIndex)
            let currentIndex = index - 1;
            if (parseInt(index) === 0) {
                currentIndex = this.slides.length - 1;
            }
            this.navigationTagging(currentIndex, 'arrow');
        },
        decodeHtmlstring(html) {
            let div = document.createElement("div");
            div.innerHTML = html;
            let datas = div.innerText.replaceAll('\n', ' ');
            return datas;
        },
        btnTagging(link) {
            let url = window.location.origin + link;
            let cta = this.ctaLabel ? this.ctaLabel.toLowerCase() : '';
            let moduleName = this.decodeHtmlstring(this.initialData.Title);
            AnalyticsHandler.pushDataLayer({
                event: 'uaevent',
                event_name: 'body_button_click',
                ecommerce: 'undefined',
                eventCategory: '',
                eventAction: '',
                eventLabel: '',
                cta_name: cta,
                link_url: url,
                module_name: moduleName.toLowerCase(),
            });
        },
        navigationTagging(index, nav) {
            let slidername = this.navTaggingLabel[index];
            let activeIndex = parseInt(index) + 1;
            AnalyticsHandler.pushDataLayer({
                cta_name: `${nav}::${slidername}::${activeIndex}`,
                ecommerce: "undefined",
                event: "uaevent",
                eventAction: "select::slider navigation elements",
                eventCategory: this.pageCtegory.toLowerCase(),
                eventLabel: `${nav}::${slidername}::${activeIndex}`,
                event_name: "slider_button_click"
            });

        }
    },
};
</script>
<style src="./true-or-false.scss" lang="scss"></style>
