var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.index == 0)?_c('div',{staticClass:"ingredient-alpha",attrs:{"data-index":this.index}},[_c('span',[_vm._v(_vm._s(this.dataGroup))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"ingredient"},[_c('a',{directives:[{name:"tag",rawName:"v-tag:useractionevent",value:({
          category: `${_vm.pageCategory}`,
          event_name: 'body_button_click',
          action: 'select::ingredient',
          label: `${_vm.title.replace(/<\/?[^>]+(>|$)/g, '').toLowerCase()}::${_vm.ctaLink != null ? _vm.ctaLink.toLowerCase() : 'none'}`,
          module_name: 'ingredient',
          cta_name: `${_vm.title.replace(/<\/?[^>]+(>|$)/g, '').toLowerCase()}`,
          link_url: `${_vm.ctaLink != null ? _vm.ctaLink.toLowerCase() : 'none'}`,
        }),expression:"{\n          category: `${pageCategory}`,\n          event_name: 'body_button_click',\n          action: 'select::ingredient',\n          label: `${title.replace(/<\\/?[^>]+(>|$)/g, '').toLowerCase()}::${ctaLink != null ? ctaLink.toLowerCase() : 'none'}`,\n          module_name: 'ingredient',\n          cta_name: `${title.replace(/<\\/?[^>]+(>|$)/g, '').toLowerCase()}`,\n          link_url: `${ctaLink != null ? ctaLink.toLowerCase() : 'none'}`,\n        }",arg:"useractionevent"}],attrs:{"href":_vm.ctaLink != null ? _vm.ctaLink : '#',"title":_vm.title}},[_c('p',{staticClass:"title"},[_c('strong',{domProps:{"innerHTML":_vm._s(_vm.title)}})]),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(_vm.description)}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }