var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"simpleList",staticClass:"simple-list"},[_c('div',{staticClass:"title__wrapper",attrs:{"slot":"header"},slot:"header"},[_c('h2',{staticClass:"heading",domProps:{"innerHTML":_vm._s(_vm.headingTitle)}})]),_vm._v(" "),_c('ul',_vm._l((_vm.simpleData),function(item,index){return _c('li',{key:index,staticClass:"simple-list__item"},[_c('a',{directives:[{name:"tag",rawName:"v-tag:useractionevent",value:({
          category: `${_vm.pageCategory}`,
          event_name: 'body_button_click',
          action: 'select::ingredient',
          label: `${_vm.concerntext(item.concerntext)}::${item.link != null ? _vm.currentUrl() + item.link.toLowerCase() : 'none'}`,
          module_name: 'ingredient',
          cta_name: `${_vm.concerntext(item.concerntext)}`,
          link_url: `${item.link != null ? _vm.currentUrl() + item.link.toLowerCase() : 'none'}`,
        }),expression:"{\n          category: `${pageCategory}`,\n          event_name: 'body_button_click',\n          action: 'select::ingredient',\n          label: `${concerntext(item.concerntext)}::${item.link != null ? currentUrl() + item.link.toLowerCase() : 'none'}`,\n          module_name: 'ingredient',\n          cta_name: `${concerntext(item.concerntext)}`,\n          link_url: `${item.link != null ? currentUrl() + item.link.toLowerCase() : 'none'}`,\n        }",arg:"useractionevent"}],staticClass:"simple-list__link",attrs:{"href":item.link}},[_c('span',{domProps:{"innerHTML":_vm._s(item.concerntext)}})])])}),0),_vm._v(" "),(_vm.simpleData.length > 14)?_c('div',{staticClass:"btn btn--black btn--back"},[_c('a',{staticClass:"btn__wrapper",on:{"click":function($event){return _vm.gotoTop()}}},[_vm._v(_vm._s(_vm.backTo))])]):_vm._e(),_vm._v(" "),_c('br'),_vm._v(" "),(_vm.listObject.length !== _vm.simpleData.length)?_c('div',{staticClass:"btn btn--black btn--loadmore"},[_c('a',{staticClass:"btn__wrapper",on:{"click":function($event){return _vm.loadMoreData()}}},[_vm._v(_vm._s(_vm.loadMore)+" ")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }