<template>
  <div class="star-review">
    <div class="reviews" :style="{'border-color':ActiveColor}">
      <div class="review-col1">
        <div class="pfd__star-rating star-rating">
          <a href="#" class="star-rating__caption">
            <span class="star-count" :style="{'color':ActiveColor}"><em>{{ overallRating }}</em>/5</span>
            <star-rating :rtl="isRtlEnabled" :increment="1" :max-rating="5" :inactive-color="InactiveColor" :active-color="ActiveColor" :border-color="ActiveColor" :border-width="1" :star-size="28" :rating="overallRating" :read-only="true" :show-rating="false" aria-hidden="true"/>
          </a>
        </div>
        <div class="review-col2">
          <h3 class="review-author"><span>{{ authorName }}</span> <span class="review-date">{{ date }} </span></h3>
          <p class="review-text" :style="{'color':ActiveColor}" @click="redirectionTag()"><a href="#">{{ reviewText }}</a></p>
        </div>
      </div>
      <div class="review-col3">
        <h3 class="review-head">{{ title }}</h3>
        <p class="review-desc" v-html="description"/>
      </div>
    </div>
  </div>
</template>

<script>
  import StarRating from 'vue-star-rating';
  import { AnalyticsHandler } from '@Foundation/analyticsHandler';

  export default {
    name: 'star-review',
    components: { StarRating },
    props: {
      authorName: { type: String, required: true, default: 'Lena-taste' },
      date: { type: String, required: true, default: 'il ya 3 ans' },
      description: { type: String, required: true, default: 'Test' },
      title: { type: String, required: true, default: 'Test' },
      reviewText: { type: String, required: true, default: 'View all reviews' },
      InactiveColor: { type: String, required: true, default: '#fff' },
      ActiveColor: { type: String, required: true, default: '#009FE3' },
      AverageRating: { type: Number, required: true, default: 4 },
      StarRatingCount: { type: String, required: false, default: '4/5' },
      productName: { type: String, required: false, default: '' },
      productId: { type: String, required: false, default: '' },
      isRtlEnabled: { type: String, required: false, default: 'false' }
    },
    data() {
      return {
        overallRating: this.AverageRating
      };
    },
    methods: {
      redirectionTag() {
        AnalyticsHandler.pushDataLayer({
          'event': 'uaevent',
          'event_name': 'read_review',
          'ecommerce': 'undefined',
          'eventCategory': 'product detail page',
          'eventAction': 'read review',
          'eventLabel': `${[this.productName]}::${[this.productId]}`,
          'product_info': `${[this.productName]}::${[this.productId]}`,
        });
      }
    },
  };
</script>
<style lang="scss" src="./star-review.scss"></style>
