<template>
  <div class="custom-form__captcha">
    <load-script :src="captchaApi"/>
    <div :id="captchaId" class="g-recaptcha"/>
    <slot/>
  </div>
</template>

<script>
  import LoadScript from '@Foundation/loadscript/loadscript';

  export default {
    name: 'captcha',

    components: { LoadScript },

    props: {
      explicitlyRender: { type: Boolean, default: true },
      captchaId: { type: String, default: 'captcha-container' },
      captchaSitekey: { type: String, required: true, default: '6LeFm7sZAAAAAMEWOd536OD9qOz4Jr592rVHBjNA' },
      googleApi: { type: String, default: 'https://www.google.com/recaptcha/api.js' },
    },

    data() {
      return {
        hasError: false
      };
    },

    computed: {
      captchaApi() {
        return this.explicitlyRender ? `${this.googleApi}?onload=onCaptchaAPIReady&render=explicit` : this.googleApi;
      }
    },

    watch: {
      hasError(value) {
        this.$emit('captchaError', value);
      }
    },

    created() {
      /** create a global method to render captcha viw google API (explicitly render)
       * https://developers.google.com/recaptcha/docs/display
       */
      window.onCaptchaAPIReady = this.onCaptchaAPIReady;
    },

    /* eslint-disable no-undef */
    methods: {
      onCaptchaAPIReady() {
        /* istanbul ignore next */
        setTimeout(() => {
          if (typeof grecaptcha === 'undefined' && typeof grecaptcha.render !== 'function') {
            this.onCaptchaAPIReady();
          } else {
            grecaptcha.render(this.captchaId, {
              sitekey: this.captchaSitekey,
              callback: this.checkCaptcha
            });
          }
        }, 100);
      },

      checkCaptcha() {
        /* istanbul ignore next */
        grecaptcha.getResponse().length !== 0 ? this.hasError = false : this.hasError = true;
        localStorage.captchaResponse = grecaptcha.getResponse();
      }
    }
  };
</script>
<style lang="scss"></style>
