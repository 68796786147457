<template>
  <div class="generic-input generic-form__component" :class="computedClasses">
    <input
      v-if="type !== 'textarea'"
      class="generic-input__field form-control"
      ref="genericInput"
      :type="type"
      :name="name"
      :id="id"
      :aria-describedby="ariaDescriptionIds"
      :aria-invalid="materialHasErrors"
      :placeholder="placeholder"
      v-model="valueCopy"
      :disabled="disabled || readonly"
      :autocomplete="autocomplete"
      :required="required"
      :minlength="minlengthIfText"
      :maxlength="maxlengthIfText"
      @focus="handleFocus(true)"
      @blur="handleFocus(false)"
      @input="handleModelInput"
    >
    <textarea
      v-else
      class="generic-input__field form-control"
      ref="genericInput"
      v-model="valueCopy"
      :name="name"
      :id="id"
      :placeholder="placeholder"
      :disabled="disabled || readonly"
      :autocomplete="autocomplete"
      :aria-invalid="materialHasErrors"
      :aria-describedby="ariaDescriptionIds"
      :required="required"
      :minlength="minlengthIfText"
      :maxlength="maxlengthIfText"
      @focus="handleFocus(true)"
      @blur="handleFocus(false)"
      @input="handleModelInput"
    />
    <span class="generic-input-bar"/>

    <label class="generic-form__label" :for="id" v-show="isLabelVisible">
      <slot/><span class="required" v-if="required" aria-hidden="true">*</span>
    </label>

    <div v-show="hasDescription" :id="`description-${id}`">
      <div v-if="showErrors" class="generic-errors">
        <div v-for="(error, key) in computedErrors" class="generic-errors__item" :key="key">
          {{ error }}
        </div>
      </div>
      <slot name="helperText"/>
      <div v-if="isValueInBlockList && isValueInBlockList.length > 0" class="generic-errors">
        <div v-if="isValueInBlockList.includes(valueCopy)" class="generic-errors__item">{{ blackListWordsMessage }}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'generic-input',

    props: {
      id: {
        type: String,
        default: null
      },
      name: {
        type: String,
        default: null
      },
      type: {
        type: String,
        default: 'text'
      },
      value: {
        type: String,
        default: null
      },
      label: {
        type: String,
        default: null
      },
      placeholder: {
        type: String,
        default: null
      },
      ApiKey: {
        type: String,
        default: null
      },
      readonly: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      min: {
        type: String,
        default: null
      },
      max: {
        type: String,
        default: null
      },
      step: {
        type: Number,
        default: null
      },
      minlength: {
        type: Number,
        default: null
      },
      maxlength: {
        type: Number,
        default: null
      },
      required: {
        type: Boolean,
        default: true
      },
      autocomplete: {
        type: String,
        default: null
      },
      errorMessages: {
        type: [Array, String],
        default: null
      },
      blackListWords: {
        type: Object,
        default: null
      },
      blackListWordsMessage: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        interacted: false,
        valueCopy: null,
        focus: false
      };
    },
    computed: {
      maxlengthIfText() {
        return this.maxlength && this.type === 'text' ? this.maxlength : null;
      },
      minlengthIfText() {
        return this.minlength && this.type === 'text' ? this.minlength : null;
      },
      ariaDescriptionIds() {
        return this.hasDescription ? `description-${this.id}` : null;
      },
      hasDescription() {
        return this.computedErrors || this.$slots.helperText;
      },
      computedErrors () {
        return typeof this.errorMessages === 'string'
          ? [this.errorMessages] : this.errorMessages;
      },
      materialHasErrors() {
        return Boolean(this.errorMessages && this.errorMessages.length);
      },
      isValueInBlockList() {
        return JSON.parse(JSON.stringify(this.blackListWords));
      },
      computedClasses () {
        return {
          'generic--active': this.focus,
          'generic--disabled': this.disabled,
          'generic--readonly': this.readonly,
          'generic--has-errors': this.showErrors,
          'generic--raised': Boolean(
            this.focus ||
              this.valueCopy || // has value
              (this.placeholder && !this.valueCopy)) // has placeholder
        };
      },
      isLabelVisible() {
        return Boolean(this.label != null && this.label !== '');
      },
      showErrors() {
        return this.materialHasErrors;
      },
    },
    watch: {
      value (newValue) {
        // This watch works from the code side of the 2-way-binding:
        this.copyValue(newValue);
      }
    },
    beforeMount () {
      // Here we are following the Vue2 convention on custom v-model:
      // https://github.com/vuejs/vue/issues/2873#issuecomment-223759341
      this.copyValue(this.value);
    },
    methods: {
      handleModelInput (event) {
        if (this.ApiKey === 'firstName' || this.ApiKey === 'lastName') {
          this.valueCopy = this.valueCopy.toString().toLowerCase();
          this.valueCopy = this.valueCopy.charAt(0).toUpperCase() + this.valueCopy.slice(1);
          event.target.value = this.valueCopy;
        }
        this.$emit('input', event.target.value, event);
      },
      handleFocus (focused) {
        if (!focused) {
          this.interacted = true;
        }

        this.focus = focused;
        if (this.ApiKey === 'address1') {
          if (!isNaN(this.valueCopy)) {
            this.valueCopy = '';
          }
        }
      },
      copyValue (value) {
        this.valueCopy = value;
      },
      reset () {
        this.focus = false;
      }
    }
  };
</script>
