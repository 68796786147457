import { throttle } from '@Foundation/utilities';

export const footerPadding = { // eslint-disable-line
  name: 'footer-padding',
  inserted: (el) => {
    window.addEventListener('scroll', throttle(() => {
      window.pageYOffset + window.innerHeight > document.body.clientHeight - 10 ? el.classList.add('hidden') : el.classList.remove('hidden');
    }, 160));
  }
};
