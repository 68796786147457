import { VueModule } from '@Foundation/utilities';
import Ingredient from './code/Scripts/components/Ingredient/ingredient.vue';
import IngredientStared from './code/Scripts/components/Ingredient/ingredientStared.vue';
import IngredientList from './code/Scripts/components/Ingredient/ingredientList.vue';

export default VueModule({
  components: {
    Ingredient,
    IngredientStared,
    IngredientList
  }
});
