<template>
  <section class="proven-efficacy">
    <div class="proven-efficacy-wrapper">
      <h3 class="main-title" v-html="provenEfficacyTitle"/>
      <swiper :options="swiperOption" class="swiper" ref="swiper" @slideChange="provenTagging">
        <swiper-slide v-for="(item, index1) in efficacyData" :data-index="index1" :key="index1 + 0"
                      :class="{ 'swiper-highligher': currentLink === index1 }">
          <div class="card card-bg-image" :style= "[{ backgroundImage: 'url(' + item.DataCardImage + ')'}]" :class="[ item.Disclaimer && item.Disclaimer!=' ' ? 'with-disclaimer':'no-disclaimer']" v-if="item.ProvenEfficacyType == 'ProvenEfficacyData'">
            <div v-if="item.Disclaimer && item.Disclaimer!=' '"/>
            <div class="data-container">
              <div class="data-figure" v-html="item.DataFigure"/>
              <div class="data-description" v-html="item.DataDescription"/>
            </div>
            <p class="data-disclaimer" v-if="item.Disclaimer && item.Disclaimer!=' '" v-html="item.Disclaimer"/>
          </div>
          <div class="card card-bg-grey" v-else >
            <p class="quotes" >&lsquo;&lsquo;</p>
            <p class="quotes-txt" v-html="item.DataDescription"/>
            <p class="name" v-html="item.AuthorName"/>
          </div>
        </swiper-slide>
      </swiper>
      <div class="carousel-navigation">
        <div v-if="(desktopScreen && efficacyData.length > 3) || !desktopScreen" class="carousel__arrow carousel__arrow--left" slot="button-prev" @click="prev()" :class="{ 'disabled': swiperAtBegain }"/>
        <div v-if="(desktopScreen && efficacyData.length > 3) || !desktopScreen" class="carousel__arrow carousel__arrow--right" slot="button-next" @click="next()" :class="{ 'disabled': swiperAtEnd }"/>
      </div>
    </div>
  </section>
</template>

<script>
  import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
  import { AnalyticsHandler } from '@Foundation/analyticsHandler';
  export default {
    name: 'proven-efficacy',
    components: { Swiper, SwiperSlide },
    props: {
      efficacyData: {
        type: Array,
        default: undefined
      },
      provenEfficacyTitle: {
        type: String,
        default: ''
      },
      colorTheme: {
        type: String,
        required: false,
        default: '#009FE3'
      },
      pageCategory: {
        type: String, required: false, default: ''
      }
    },
    data() {
      return {
        desktopScreen: window.matchMedia('(min-width: 1024px)').matches,
        currentLink: undefined,
        swiperAtEnd: false,
        swiperAtBegain: true,
        swiperOption: {
          pagination: '.swiper-pagination',
          spaceBetween: 16,
          navigation: {
            nextEl: '.carousel__arrow carousel__arrow--left',
            prevEl: '.carousel__arrow carousel__arrow--right',
            hiddenClass: '.swiper-button-hidden',
          },
          breakpoints: {
            1024: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 3,
            },
            320: {
              slidesPerView: 1,
            },
          },
        },

      };
    },
    created() {
      document.documentElement.style.setProperty('--active-color', this.colorTheme);
      if (window.innerWidth >= 1024) {
        if (this.efficacyData.length <= 3) {
          this.swiperOption.allowTouchMove = false;
        } else {
          this.swiperOption.allowTouchMove = true;
        }
      }
    },
    destroyed() {
      document.documentElement.style.removeProperty('--active-color');
    },
    methods: {
      prev() {
        this.$refs.swiper.$swiper.slidePrev();
        this.swiperAtEnd = this.$refs.swiper.$swiper.isEnd;
        this.swiperAtBegain = this.$refs.swiper.$swiper.isBeginning;
      },
      next() {
        this.$refs.swiper.$swiper.slideNext();
        this.swiperAtBegain = this.$refs.swiper.$swiper.isBeginning;
        this.swiperAtEnd = this.$refs.swiper.$swiper.isEnd;
      },
      provenTagging() {
        let indexValue = this.$refs.swiper.$swiper.activeIndex+1;
        let visualName = this.decodeHtmlstring(this.efficacyData[indexValue - 1].ImageName);
        let provenEfficacyTitle = this.decodeHtmlstring(this.provenEfficacyTitle).toLowerCase();
        AnalyticsHandler.pushDataLayer({
          'event': 'uaevent',
          'ecommerce': 'undefined',
          'event_name': 'slider_button_click',
          'eventCategory': this.pageCategory,  
          'eventAction': 'select::slider navigation elements',
          'eventLabel': `${provenEfficacyTitle}::none::${[indexValue]}`, 
          'cta_name': `${provenEfficacyTitle}::none::${[indexValue]}`, 
          'module_name': `slider navigate::${provenEfficacyTitle}`,
        });
      },
      decodeHtmlstring(html) {
        let div = document.createElement("div");
        div.innerHTML = html;
        let datas=div.innerText.replaceAll('\n', ' ');
        return datas;
      },
    }
  };
</script>
<style lang="scss" src="./proven-efficacy.scss"></style>
