<template>
  <div>
    <div class="ingredient-alpha" v-if="this.index == 0" :data-index="this.index"><span>{{this.dataGroup}}</span></div>
    <div class="ingredient">
      <a :href="ctaLink != null ? ctaLink : '#'" :title="title" 
      v-tag:useractionevent="{
            category: `${pageCategory}`,
            event_name: 'body_button_click',
            action: 'select::ingredient',
            label: `${title.replace(/<\/?[^>]+(>|$)/g, '').toLowerCase()}::${ctaLink != null ? ctaLink.toLowerCase() : 'none'}`,
            module_name: 'ingredient',
            cta_name: `${title.replace(/<\/?[^>]+(>|$)/g, '').toLowerCase()}`,
            link_url: `${ctaLink != null ? ctaLink.toLowerCase() : 'none'}`,
          }">
        <p class="title"><strong v-html="title"/></p>
        <div v-html="description"/>
      </a>
    </div>
  </div>
</template>

<script>
 import { tag } from '@Foundation/analyticsHandler';
  export default {
    name: 'incredient',
    directives: {
      tag
    },
    props: {
      index: {
        type: Number,
        default: 1,
      },
      dataGroup: {
        type: String,
        required: false,
      },
      title: {
        type: String,
        required: false,
        default: ''
      },
      description: {
        type: String,
        required: false,
        default: ''
      },
      ctaLink: {
        type: String,
        required: false,
        default: '#'
      },
      pageCategory: {
        type: String,
        default: ''
      }
    },
  };
</script>
