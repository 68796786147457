<template>
  <div v-if="initialData.IsEnable" class="lead-magnet">
   <div class="lead-magnet__content" v-html="initialData.HtmlDiv"></div>
    <div class="lead-magnet__img">
      <responsive-image :srcset="srcset">
        <img src="/frontend/master/images/placeholder.png" :alt="initialData.ImageAlt">
        <noscript>
          <img :src="initialData.image" :alt="initialData.ImageAlt">
        </noscript>
      </responsive-image>
      </div>
  </div>
</template>

<script>
import ResponsiveImage from '@Feature/ResponsiveImage/code/Scripts/components/responsive-image.vue';


export default {
  name: 'lead-magnet',
  components: { 
    ResponsiveImage
    },
  props: {   
    initialData: {
      type: Object,
      default: () => {},
    },
    srcset: {
      type: Object,
      default: () => {},
    },
  },

 
  mounted() {    
    const scriptTag = document.createElement('script');
    scriptTag.type = 'text/javascript';
    scriptTag.text = this.initialData.Script;
    document.head.append(scriptTag);
  },
};
</script>

<style lang="scss" src="./lead-magnet.scss"></style>