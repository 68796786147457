<template>
<div class="sustainability-wrapper" v-show="show">
   <div class="row">
      <div class="col-xs-12 col-sm-12">
         <div class="sustainability-container">
            
            <div class="product-note">
               
                    
                     <div class="collapse__hamburger-wrapper">
                  
                     <div class="product-note__description richtext">
                        <div class="sustainability">
                          
                           <div class="sustainability__section-">
                              <div class="sustainability__section_one">
                              <h2 class="product-note__title heading " v-html="initialData.SocialAndEnvironmentalImpactTitle"></h2>
                              <p v-html="initialData.SocialAndEnvironmentalImpactDescription">
                           </p>
                              </div>
                              <div class="sustainability__section_two">
                              <h4 class="sustainability__section-title overall-impact" >{{initialData.GlobalEnvironmentalImpactTitle}}</h4>
                                    <div class="sustainability__footprint">
                                       <div class="sustainability__diagram"><img :src="formatted.length >= 1 ? formatted[0].image :''"  class=" lazyloaded" > <!-- :src="initialData.GlobalGradeImage"--></div>
                                       <div class="sustainability__indicators">
                                          <div class="sustainability__indicator">
                                             <h6 class="sustainability__footprint-title sustainability__footprint-title_lower">
                                                {{initialData.CarbonFootprintTitle}}
                                             </h6>
                                             <div class="sustainability__indicator-line" 
                                             :class="[formatted[0] && formatted[0].CarbonFootPintGrade === 'A' ? 'position-1' : formatted[0] && formatted[0].CarbonFootPintGrade === 'B' ? 'position-2' : formatted[0] && formatted[0].CarbonFootPintGrade === 'C' ? 'position-3' : formatted[0] && formatted[0].CarbonFootPintGrade === 'D' ? 'position-4' : 'position-5']" v-if="formatted[0] && formatted[0].CarbonFootPintGrade">
                                             
                                             <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                viewBox="0 0 62.56 62.56" style="enable-background:new 0 0 62.56 62.56;" xml:space="preserve">

                                             <g>
                                                <defs>
                                                   <rect id="SVGID_1_" width="62.56" height="62.56"/>
                                                </defs>
                                                <clipPath id="SVGID_00000150086585617406771340000003952002893441095317_">
                                                   <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
                                                </clipPath>
                                                <path style="clip-path:url(#SVGID_00000150086585617406771340000003952002893441095317_);fill:#FFFFFF;" d="M31.28,62.17
                                                   c17.06,0,30.88-13.83,30.88-30.88c0-17.06-13.83-30.88-30.88-30.88S0.4,14.23,0.4,31.28C0.4,48.34,14.23,62.17,31.28,62.17"/>
                                                
                                                   <path style="clip-path:url(#SVGID_00000150086585617406771340000003952002893441095317_);fill:none;stroke:#878787;stroke-width:0.25;stroke-miterlimit:10;" d="
                                                   M31.28,62.17c17.06,0,30.88-13.83,30.88-30.88c0-17.06-13.83-30.88-30.88-30.88S0.4,14.23,0.4,31.28
                                                   C0.4,48.34,14.23,62.17,31.28,62.17"/>
                                                
                                                   <circle style="clip-path:url(#SVGID_00000150086585617406771340000003952002893441095317_);fill:none;stroke:#878787;stroke-width:0.25;stroke-miterlimit:10;" cx="31.28" cy="31.28" r="31.16"/>
                                                <path style="clip-path:url(#SVGID_00000150086585617406771340000003952002893441095317_);fill:#878787;" d="M37.1,21.73
                                                   c-2.62,0-4.9,0.91-6.71,2.69s-2.8,4.12-2.8,6.66c0,2.72,0.88,5,2.62,6.84c1.81,1.92,4.15,2.9,6.87,2.9c2.56,0,5-1.01,6.79-2.88
                                                   c1.74-1.79,2.67-4.14,2.67-6.79c0-2.51-0.98-4.87-2.85-6.71C41.9,22.69,39.56,21.73,37.1,21.73 M37.08,36.27
                                                   c-2.54,0-4.85-2.23-4.85-5.13c0-2.7,2.15-4.84,4.9-4.84c2.59,0,4.74,2.15,4.74,4.84C41.87,34.15,39.72,36.27,37.08,36.27"/>
                                                <path style="clip-path:url(#SVGID_00000150086585617406771340000003952002893441095317_);fill:#878787;" d="M17.04,26.29
                                                   c1.81,0,3.29,0.96,4.28,2.77h4.87c-1.19-4.53-4.87-7.36-9.17-7.36c-2.62,0-4.9,0.93-6.71,2.72c-1.84,1.79-2.8,4.12-2.8,6.66
                                                   c0,2.72,0.88,5,2.62,6.84c1.81,1.92,4.12,2.93,6.84,2.93c4.43,0,7.93-2.72,9.15-7.2h-4.9c-0.98,1.71-2.51,2.64-4.25,2.64
                                                   c-2.54,0-4.82-2.26-4.82-5.18C12.14,28.42,14.29,26.29,17.04,26.29"/>
                                                <path style="clip-path:url(#SVGID_00000150086585617406771340000003952002893441095317_);fill:#878787;" d="M55.06,33.31
                                                   c0-1.6-1.22-2.7-3.22-2.7c-1.96,0-3.21,1.33-3.22,3.45h2.28c0-0.06-0.01-0.14-0.01-0.21c0-0.84,0.38-1.32,0.94-1.32
                                                   c0.56,0,0.89,0.38,0.89,1.02c0,1.29-1.34,2.82-4.05,4.43v1.95h6.37v-2.06h-3.22C53.98,36.49,55.06,34.8,55.06,33.31"/>
                                             </g>
                                             </svg>
                                             </div>
                                             <div class="sustainability__indicator-information carbon">
                                                <p v-if="formatted[0] && formatted[0].CarbonFootPrintvalueperml">{{formatted[0].CarbonFootPrintvalueperml}} {{formatted[0].CarbonFootprintUnit}}<sup v-if="footnote1">(1)</sup> {{initialData.CarbonFootprintPerDosageUse}}
                                                   
                                                </p>
                                                <p v-if="formatted[0] &&  formatted[0].CarbonFootPrintvalueperdosageuse">{{formatted[0].CarbonFootPrintvalueperdosageuse}} {{formatted[0].CarbonFootprintUnit}} {{formatted[0].CarbonFootprintperml}}
                                                   
                                                </p>
                                             </div>
                                          </div>
                                          <div class="sustainability__indicator">
                                             <h6 class="sustainability__footprint-title sustainability__footprint-title_lower  ">
                                             
                                             {{initialData.WaterFootprintTitle}}
                                             </h6>
                                             <div class="sustainability__indicator-line " 
                                             :class="[formatted[0] &&  formatted[0].WaterFootPintGrade === 'A' ? 'position-1' : formatted[0] && formatted[0].WaterFootPintGrade === 'B' ? 'position-2' : formatted[0] && formatted[0].WaterFootPintGrade === 'C' ? 'position-3' : formatted[0] && formatted[0].WaterFootPintGrade === 'D' ? 'position-4' : 'position-5']" v-if="formatted[0] && formatted[0].WaterFootPintGrade" >
                                                   <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                                      viewBox="0 0 62.56 62.56" style="enable-background:new 0 0 62.56 62.56;" xml:space="preserve">

                                                   <g>
                                                      <defs>
                                                         <rect id="SVGID_1_" x="0" width="62.56" height="62.56"/>
                                                      </defs>
                                                      <clipPath id="SVGID_00000127747925350089840260000012253267060159287439_">
                                                         <use xlink:href="#SVGID_1_"  style="overflow:visible;"/>
                                                      </clipPath>
                                                      <path style="clip-path:url(#SVGID_00000127747925350089840260000012253267060159287439_);fill:#FFFFFF;" d="M31.28,62.17
                                                         c17.06,0,30.88-13.83,30.88-30.88c0-17.06-13.83-30.88-30.88-30.88S0.4,14.23,0.4,31.28C0.4,48.34,14.23,62.17,31.28,62.17"/>
                                                      
                                                         <path style="clip-path:url(#SVGID_00000127747925350089840260000012253267060159287439_);fill:none;stroke:#878787;stroke-width:0.25;stroke-miterlimit:10;" d="
                                                         M31.28,62.17c17.06,0,30.88-13.83,30.88-30.88c0-17.06-13.83-30.88-30.88-30.88S0.4,14.23,0.4,31.28
                                                         C0.4,48.34,14.23,62.17,31.28,62.17"/>
                                                      
                                                         <path style="clip-path:url(#SVGID_00000127747925350089840260000012253267060159287439_);fill:none;stroke:#878787;stroke-width:0.25;stroke-miterlimit:10;" d="
                                                         M31.28,62.44c17.21,0,31.16-13.95,31.16-31.16S48.49,0.12,31.28,0.12S0.13,14.08,0.13,31.28S14.08,62.44,31.28,62.44z"/>
                                                      <path style="clip-path:url(#SVGID_00000127747925350089840260000012253267060159287439_);fill:#878787;" d="M31.91,12.75
                                                         c-0.32-0.4-0.95-0.4-1.26,0c-2.68,3.53-12.71,17.32-12.71,23.81c0,7.46,6,13.55,13.35,13.55c7.34,0,13.35-6.09,13.35-13.55
                                                         C44.63,30.07,34.6,16.28,31.91,12.75 M35.78,45.38c-0.16,0.08-0.32,0.08-0.47,0.08c-0.47,0-0.95-0.32-1.11-0.72
                                                         c-0.24-0.64,0.08-1.28,0.63-1.6c4.66-1.92,4.26-7.7,4.19-7.78c-0.08-0.64,0.39-1.28,1.1-1.28c0.63-0.08,1.26,0.4,1.26,1.12
                                                         C41.71,37.77,40.76,43.3,35.78,45.38"/>
                                                   </g>
                                                   </svg>
                                              </div>
                                             <div class="sustainability__indicator-information">
                                                <p v-if="formatted[0] &&  formatted[0].WaterFootPrintvalueperdosageuse">{{formatted[0].WaterFootPrintvalueperdosageuse}}<sup v-if="footnote2">(2)</sup> {{initialData.WaterFootprintPerDosageUse}}
                                                </p>
                                                 <p v-if="formatted[0] &&  formatted[0].waterFootPrintvalueperml && formatted[0].WaterFootprintperml ">{{formatted[0].waterFootPrintvalueperml}} {{formatted[0].WaterFootprintperml}} 
                                                </p>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div><span style="display: none;"></span></div>
                              </div>
                           </div>
                           
                           
                           <div class="sustainability__section">
                              <div class="sustainability__text-block">
                              
                               <p class="sustainability__table" v-if="formatted[0] &&  formatted[0].IsRefillable">{{initialData.RefillableMethodologyDescription}}</p>
                               <p class="sustainability__table" v-if="formatted[0] &&  formatted[0].AlternateProductOption !=''"><span v-html="formatted[0].AlternateProductOption"></span></p>
                              </div>
                             

                              <div class="sustainability__text-block" style="border-top:none">
                              
                             <h4 class="sustainability__section-title">{{initialData.ManufactoringConditions}}</h4> <!-- 2-->
                                 
                              <span v-if="formatted[0] && formatted[0].MadeatSubcontractorPlant != true">                               
                                <p class="sustainability__table" >{{formatted[0].MadeinaResponsiblePlant}}</p>
                                <p class="sustainability__table" v-if="formatted[0] && formatted[0].WasteRecovery">{{initialData.WasteRecoveryGlobal}} : {{formatted[0].WasteRecovery}}</p>
                                <p class="sustainability__table" v-if="formatted[0] &&  formatted[0].ReneweableEnergy">{{initialData.RenewableEnergyGlobal}} : {{formatted[0].ReneweableEnergy}} </p>
                              </span>
                              <span v-if="formatted[0] &&  formatted[0].MadeatSubcontractorPlant == true">
                                <p class="sustainability__table" >{{formatted[0].SubcontractorManufacturingConditions}} <sup v-if="footnote3">(3)</sup></p>
                               </span>
                                 
                              </div>
                           </div>

                           <div class="sustainability__section">
                             
                              <div class="sustainability__text-block">
                                 <h4 class="sustainability__section-title">{{initialData.EnvironmentalImpactOfPackaging}}</h4> <!-- 3-->
                                 <p class="sustainability__table certificate-power" v-if="((formatted[0] && formatted[0].CardboardPaperCertification != 0) || (formatted[0] && formatted[0].CardboardPaperCertification != '')) && initialData.EnablePackageCertification != false "><span v-html="initialData.PackagingCertification"></span> : {{formatted[0].CardboardPaperCertification}}</p>
                                <span v-if="formatted[0] &&  formatted[0].MadeatSubcontractorPlant == true"> 
                                 <p class="sustainability__table" v-if="formatted[0] && formatted[0].PartOfCycledMaterial" >{{formatted[0].PackagingTypeValue}} {{initialData.PartOfRecycledMaterials}}<sup v-if="footnote4">(4)</sup> : {{formatted[0].PartOfCycledMaterial}}
                                 </p>
                                 <p class="sustainability__table" v-if="formatted[0] && formatted[0].Recycable" >{{initialData.RecycableGlobal}}<sup v-if="footnote5">(5)</sup> : {{formatted[0].Recycable}} </p>
                                 </span> 
                                 <span v-if="formatted[0] &&  formatted[0].MadeatSubcontractorPlant != true"> 
                                 <p class="sustainability__table" v-if="formatted[0] && formatted[0].PartOfCycledMaterial && formatted[0].PackagingTypeValue ">{{formatted[0].PackagingTypeValue}} {{initialData.PartOfRecycledMaterials}}<sup v-if="footnote3">(3)</sup> : {{formatted[0].PartOfCycledMaterial}}
                                 </p>
                                 <p class="sustainability__table" v-if="formatted[0] && formatted[0].Recycable">{{initialData.RecycableGlobal}}<sup v-if="footnote4">(4)</sup> : {{formatted[0].Recycable}} </p>
                                 </span> 
                                 <p class="sustainability__table" v-if="formatted[0] && formatted[0].ReffilableorRechargeable" >{{initialData.RefillableOrRechargeableGlobal}}: {{formatted[0].ReffilableorRechargeable}} </p>
                                
                              </div>
                           </div>

                           <div class="sustainability__section">
                              <div class="sustainability__text-block">
                                 <h4 class="sustainability__section-title">{{initialData.SocialImpactProductTitle}}</h4> <!-- 4-->
                                 <p v-html="initialData.SocialImpactProductDescription"></p>
                                  <div class="sustainability__footnote__suplier" v-if="(formatted[0] && formatted[0].supportinclusivesuppliersvalue > 1) && initialData.SupportsInclusiveSupplier != '' "><span v-if="initialData.SupportsInclusiveSupplier">{{initialData.SupportsInclusiveSupplier}}</span>&nbsp;<span v-html="initialData.SupportsInclusiveSupplierGreaterThanone"></span> </div>
                              
                              <div class="sustainability__text-block">
                                 <p v-html="initialData.LearnMoreText"></p>
                                 <p>
                              <div>
                                 <a :href="initialData.LearnMoreCTALink" rel="noopener noreferrer"  :class="ctaClass">
                                    <span class="btn__wrapper">
                                        {{initialData.LearnMoreCTAText}}
                                    </span>
                                  </a>
                              </div>                         
                           </p>
                           <div class="sustainability__footnote sustainability__footnote__2" v-if="formatted[0] && formatted[0].FootNotes">*<span v-if="footnote1"> (1){{footnote1}}</span> <span v-if="footnote2"> (2){{footnote2}}</span><span v-if="footnote3"> (3){{footnote3}}</span><span v-if="footnote4"> (4){{footnote4}}</span><span v-if="footnote5"> (5){{footnote5}}</span></div>
                              </div>
                           </div>
                        </div>
                        <div>
                           
                        </div>
                     </div>
                  <!---->
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</div>
</template>
<script>
   export default {
    name: 'sustainability-module',
    
    props: {
      initialData: {
        type: Object,
        default() {
          return undefined;
        }
      },
      variantData: {
        type: Array,
        default() {
          return undefined;
        }
      },
      ctaClass: {type: String, default: 'cta--secondary cta--light'},
        
    },
    data(){
        return {
        activeVariant: [],
        show : false,
        currentVariant :'',
        formatted:[],
        defaultVaraintValue :[],
        footnote1:'',
        footnote2:'',
        footnote3:'',
        footnote4:'',
        footnote5:''
       
      };
    },
    
    mounted() {
    let length = this.variantData.length;
           
     this.variantloop();
     this.getDefaultVariant();
     let defaultVariant = this.defaultVaraintValue;
     let defaultSelected = this.activeVariant.includes(defaultVariant);
     this.test_data(defaultVariant);
     if(defaultSelected){
          
        this.show = true;
     }
     let emitresult='';

        window.eventBus.$on('selectedVarientSize', payload => {           
            if(payload) {
               emitresult = payload.replaceAll(' ','');
               this.test_data(emitresult);
               this.footnote(this.formatted[0].FootNotes[0]);
               let selected = this.activeVariant.includes(emitresult);
               if (selected) {
                  this.show = true;
                  //this.test_data(emitresult);
                   
               } else{
                  this.show = false;
               }
            } 
         });
         if(this.formatted[0] && this.formatted[0].FootNotes){
            this.footnote(this.formatted[0].FootNotes[0]);
         }      

    },
   computed: {
        
    },
    methods:{
         footnote(footnote){
            let footNoteLength = Object.keys(footnote).length;
            
                       
            if(footNoteLength === 1){
               this.footnote1 = footnote.FootNote1;
            } 
            else if(footNoteLength === 2){
               this.footnote1 = footnote.FootNote1;
               this.footnote2 = footnote.FootNote2;
            }
           else if(footNoteLength === 3){
               this.footnote1 = footnote.FootNote1;
               this.footnote2 = footnote.FootNote2;
               this.footnote3 = footnote.FootNote3;
            }
            else if(footNoteLength === 4){
               this.footnote1 = footnote.FootNote1;
               this.footnote2 = footnote.FootNote2;
               this.footnote3 = footnote.FootNote3;
               this.footnote4 = footnote.FootNote4;
            }
             else if(footNoteLength === 5){
               this.footnote1 = footnote.FootNote1;
               this.footnote2 = footnote.FootNote2;
               this.footnote3 = footnote.FootNote3;
               this.footnote4 = footnote.FootNote4;
               this.footnote5 = footnote.FootNote5;
            }else{
               this.footnote1 = '';
               this.footnote2 = '';
               this.footnote3 = '';
               this.footnote4 = '';
               this.footnote5 = '';

            }
       


         },
         test_data(varinat=null) {
           let selectedVariant = this.activeVariant.includes(varinat);
            if(selectedVariant){

               for (let i = 0; i < this.initialData.VariantsValues.length; i++) {
                  if(this.initialData.VariantsValues[i].VariantEanId==varinat){
                     this.formatted=[];
                     this.formatted.push({'image':this.initialData.VariantsValues[i].Variants.GlobalEnvironmentSustainabilityGrade,
                                          'CarbonFootPrintvalueperml':this.initialData.VariantsValues[i].Variants.CarbonFootPrintvalueperml,
                                          'CarbonFootprintUnit':this.initialData.VariantsValues[i].Variants.CarbonFootprintUnit,
                                          'CarbonFootprintPerDosageUse':this.initialData.VariantsValues[i].Variants.CarbonFootprintPerDosageUse,
                                          'CarbonFootPrintvalueperdosageuse':this.initialData.VariantsValues[i].Variants.CarbonFootPrintvalueperdosageuse,
                                          'CarbonFootprintperml':this.initialData.VariantsValues[i].Variants.CarbonFootprintperml,
                                          'WaterFootPrintvalueperdosageuse':this.initialData.VariantsValues[i].Variants.WaterFootPrintvalueperdosageuse,
                                          'waterFootPrintvalueperml':this.initialData.VariantsValues[i].Variants.waterFootPrintvalueperml,
                                          'WaterFootprintperml':this.initialData.VariantsValues[i].Variants.WaterFootprintperml,
                                          'MadeinaResponsiblePlant':this.initialData.VariantsValues[i].Variants.MadeinaResponsiblePlant,
                                          'MadeatSubcontractorPlant':this.initialData.VariantsValues[i].Variants.MadeatSubcontractorPlant,
                                          'SubcontractorManufacturingConditions':this.initialData.VariantsValues[i].Variants.SubcontractorManufacturingConditions,
                                          'WasteRecovery':this.initialData.VariantsValues[i].Variants.WasteRecovery,
                                          'ReneweableEnergy':this.initialData.VariantsValues[i].Variants.ReneweableEnergy,
                                          'CardboardPaperCertification':this.initialData.VariantsValues[i].Variants.CardboardPaperCertification,
                                          'PackagingTypeValue':this.initialData.VariantsValues[i].Variants.PackagingTypeValue,
                                          'PartOfCycledMaterial':this.initialData.VariantsValues[i].Variants.PartOfCycledMaterial,
                                          'Recycable':this.initialData.VariantsValues[i].Variants.Recycable,
                                          'ReffilableorRechargeable':this.initialData.VariantsValues[i].Variants.ReffilableorRechargeable,
                                          'supportinclusivesuppliersvalue':this.initialData.VariantsValues[i].Variants.supportinclusivesuppliersvalue,
                                          'FootNote1':this.initialData.VariantsValues[i].Variants.FootNote1,
                                          'FootNote2':this.initialData.VariantsValues[i].Variants.FootNote2,
                                          'FootNote3':this.initialData.VariantsValues[i].Variants.FootNote3,
                                          'FootNote4':this.initialData.VariantsValues[i].Variants.FootNote4,
                                          'FootNote5':this.initialData.VariantsValues[i].Variants.FootNote5,
                                          'CarbonFootPintGrade':this.initialData.VariantsValues[i].Variants.CarbonFootPintGrade,
                                          'WaterFootPintGrade':this.initialData.VariantsValues[i].Variants.WaterFootPintGrade,
                                          'IsRefillable':this.initialData.VariantsValues[i].Variants.IsRefillable,
                                          'AlternateProductOption':this.initialData.VariantsValues[i].Variants.AlternateProductOption,
                                          'FootNotes':this.initialData.VariantsValues[i].Variants.FootNotes
                                    
                                          });
                  }
               }
            }
                     
        },
     
       variantloop(){
           Array.prototype.forEach.call(this.variantData, item => {       
            if(item.ShowSustainability==true){
               this.activeVariant.push(item.VariantEanId);
            }
        
         });
      },
      getDefaultVariant(){
           Array.prototype.forEach.call(this.variantData, item => {   
            if(item.IsDefaultVariant=== "true"){
               this.defaultVaraintValue = item.VariantEanId;
               return item.VariantEanId;
            }
        
         });
      }
       
    }
  };
</script>
<style lang="scss" src="./sustainability-module.scss"></style>
