<template>
  <div class="product-header">
    <h2 class="heading--style"><strong v-html="title"></strong><br> 
    <p v-html="descripion"></p>
    </h2>
  </div>
</template>
<script>

  export default {
    name: 'product-header',
    props: {
      title: {
        type: String,
        required: false,
        default: ''
      },
      descripion: {
        type: String,
        required: false,
        default: ''
      }
    },
  };
</script>
<style lang="scss" src="./product-header.scss"></style>
