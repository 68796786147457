<template>
  <div class="component before-after-Component">
    <div class="component-content container--is-fullwidth">
      <div class="container--is-maxwidth container--padded-hor container--padded">
        <h2 class="before-after-Component__heading" v-html="title" />
        <carousel
          :bullets="false"
          :navigation="true"
          :item-per-page-desktop="1"
          page-category="product page"
          next-panel-text="Next panel"
          previous-panel-text="previous panel"
          :before-after="true"
          :product-name="productName"
        >
          <div
            class="before-after-Component__content"
            v-for="(item, index) in imageDetails"
            :key="index"
          >
            <div
              class="before-after-Component__content--left"
              :class="[imageDetails.length == 1 && isanimationMode == 'true' ? 'leftcard animatedFadeInUp' : '']"
            >
              <div class="content-left">
                <p
                  :style="{ color: colorTheme }"
                  v-html="item.BeforeTitle"
                  class="before-after-Component__title"
                />
                <p v-html="item.BeforeDescription" class="before-after-Component__description" />
              </div>
              <responsive-image :srcset="item.BeforeImagesrc" animate="">
                <img src="/frontend/master/images/placeholder.png" alt="placeholder" />
                <noscript>
                  <img :src="item.BeforeImagesrc[0]" alt="" />
                </noscript>
              </responsive-image>
            </div>
            <div
              class="before-after-Component__content--right"
              :class="[imageDetails.length == 1 && isanimationMode == 'true' ? 'rightcard animatedFadeInUp' : '']"
            >
              <responsive-image :srcset="item.AfterImagesrc" animate="">
                <img src="/frontend/master/images/placeholder.png" alt="placeholder" />
                <noscript>
                  <img :src="item.AfterImagesrc[0]" alt="" />
                </noscript>
              </responsive-image>
              <div class="content-right">
                <p
                  :style="{ color: colorTheme }"
                  v-html="item.AfterTitle"
                  class="before-after-Component__title"
                />
                <p v-html="item.AfterDescription" class="before-after-Component__description" />
              </div>
            </div>
            <div v-if="item.DisclaimerText" class="before-after-Component__content--footer">
              <p v-html="item.DisclaimerText" />
            </div>
          </div>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from '@Feature/Carousel/code/Scripts/components/carousel.vue';
import ResponsiveImage from '@Feature/ResponsiveImage/code/Scripts/components/responsive-image.vue';

export default {
  name: 'before-after-v2',
  components: { Carousel, ResponsiveImage },
  props: {
    colorTheme: { type: String, required: true, default: '#009fe3' },
    disclaimerText: { type: String, default: '' },
    title: { type: String, default: '' },
    imageDetails: {
      type: [Array, String],
      required: false,
      default: undefined,
    },
    productName: { type: String, required: false, default: '' },
  },

  data() {
    return {
      animationCount: 0,
      isanimationMode :localStorage.getItem('animationMode')
    };
  },
  mounted() {
    if (this.imageDetails.length === 1 && this.isanimationMode == 'true') {
      window.addEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    handleScroll() {
      if (this.animationCount === 0) {
        // Get the bounding client rectangle position in the viewport
        var element = document.querySelectorAll('.animatedFadeInUp');
        if (element) {
          var bounding = element[0].getBoundingClientRect();
          if (bounding.top <= (window.innerHeight || document.documentElement.clientHeight)) {
            this.animationCount += 1;
            element.forEach(function (e) {
              e.classList.remove('not-fading-up');
              e.classList.add('fadeInUp');
            });
          } else {
            element.forEach(function (e) {
              e.classList.add('not-fading-up');
              e.classList.remove('fadeInUp');
            });
          }
        }
      }
    },
  },
};
</script>
