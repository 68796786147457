<template>
  <section class="routine-wrapper">
    <h3 class="main-title" v-html="title"></h3>
    <div class="tabs">
      <div class="tabs__nav" v-if="routineData.length > 1">
        <ul class="routine-section">
          <li v-for="(tab, index) in routineData"
              :key="index"
              class="tabs__nav--item"
              :class="{'tabs--expanded': (index == selectedIndex)}"
              @click="gotoTab($event, tab.tabId,index),tabProductTagging(index),tabTagging(tab.RoutineTitle)">
            <span v-html="tab.RoutineTitle" />
          </li>
        </ul>
      </div>
      <div class="tabs__content" id="benefits">
        <div class="content-wrapper"
             v-for="(tab, index) in routineData"
             :key="index"
             :class="{'tabs--expanded': (index == selectedIndex)}">
          <swiper :options="swiperOption" class="swiper" ref="swiper" :key="index" @slideChange="routineTagging(index)">
            <swiper-slide v-for="(item, index1) in tab.Benefits" :data-index="index1" :key="index1 + 0"
                          :class="{ 'swiper-highligher': currentLink === index }">
              <div class="slide-section benefit">
                <div class="benefit-img-section">
                  <div class="heading">
                    <div class="heading-number">{{ item.BenefitNumber }}</div>
                    <div class="heading-title">{{ item.BenefitTitle }}</div>
                  </div>
                  <div class="benefit-img">
                    <img v-if="item.ProductItemID!=null" @click="showRetailer(item)" :src="item.ProductImg" alt="benefit img">
                    <a v-else :href="item.CTALink">
                      <img  @click="learnMoretagging( item, index1)" :src="item.ProductImg" alt="benefit img">
                    </a>
                  </div>
                </div>
                <div class="benefit-packshotname" v-html="item.ProductName" />
                <a v-if="item.ProductItemID!=null" href="#" @click="showRetailer(item)" class="benefit-buynowbutton"> {{ item.CTAText }} </a>
                <a v-if="item.ProductItemID==null" :href="item.CTALink" class="benefit-pharmacyBtn" @click="learnMoretagging( item, index1)"> {{ item.CTAText }} </a>
              </div>
            </swiper-slide>
          </swiper>
          <div v-if="isCommerceConnectorActivated == 'true'">
            <buy-now  v-if="productItemID!=null && desktopScreen  && buynow == true && isCommerceConnectorActivated == 'true'" :product-ean="productEan" :product-id="productId" :pfd-title="itemProductName" :product-name="productName" :tag-event="true" :routine-product="true" :buynow-text="ctatext" :is-online="true" @closepopup="closepopup()" />
            <buy-now  v-if="volumesDetail.length <= 1 && colorList.length <= 1 && productItemID!=null && !desktopScreen  && buynow == true && isCommerceConnectorActivated == 'true'" :product-ean="productEan" :product-id="productId" :pfd-title="itemProductName" :product-name="productName" :tag-event="true" :routine-product="true" :buynow-text="ctatext" :is-online="true" :sticky-buynow-button="false" @closepopup="closepopup()" />
            <buy-now  v-if="((volumesDetail.length >= 0 && colorList.length > 1) || (volumesDetail.length > 1 && colorList.length >= 0)) && productItemID!=null && !desktopScreen && buynow == true && isCommerceConnectorActivated== 'true'" :product-ean="productEan" :product-id="productId" :pfd-title="itemProductName" :product-name="productName" :product-img="productImg" :product-ids="productIds" :color-text="colorText" :capacity-text="capacityText" :color-list="colorList" :colors-details="colorsDetails" :volumes-detail="volumesDetail" :variant-detail="variantDetail" :buynow-text="ctatext" :sticky-buynow-button="true" :tag-event="true" :routine-product="true" :routine-mobile="true" @closepopup="closepopup()" />
          </div>
          <div v-if="isLrpRetailerEnabled">
            <lrp-retailer v-if=" productItemID!=null && desktopScreen  && retailerPopup == true && isLrpRetailerEnabled"  :data-variant-ean="dataVariantEan" :initial-data="initialData" :data-text-lable="dataTextLable" class="sticky__buynow-button"  :data-bynow-tagging="dataBynowTagging" :error-messgage="errorMessgage" :routine-product-dektop="true" :sticky-buynow-button="true"    @closepopup="closepopup()" ></lrp-retailer>
            <lrp-retailer
              v-if="volumesDetail.length <= 1 && colorList.length <= 1 && productItemID!=null && !desktopScreen  && retailerPopup == true && isLrpRetailerEnabled"  :data-variant-ean="dataVariantEan" :initial-data="initialData" :data-text-lable="dataTextLable" class="sticky__buynow-button"  :data-bynow-tagging="dataBynowTagging" :error-messgage="errorMessgage" :routine-product-dektop="true" :sticky-buynow-button="true" :one-variant="true"   @closepopup="closepopup()" ></lrp-retailer>
            <lrp-retailer v-if="((volumesDetail.length >= 0 && colorList.length > 1) || (volumesDetail.length > 1 && colorList.length >= 0)) && productItemID!=null && !desktopScreen  && retailerPopup == true && isLrpRetailerEnabled"  :data-variant-ean="dataVariantEan" :initial-data="initialData" :data-text-lable="dataTextLable" class="sticky__buynow-button"
                          :data-bynow-tagging="dataBynowTagging" :sticky-buynow-button="true" :product-img="productImg" :product-ids="productIds" :product-id="productId" :color-text="colorText" :capacity-text="capacityText" :color-list="colorList" :colors-details="colorsDetails" :volumes-detail="volumesDetail" :variant-detail="variantDetail" :product-ean="productEan" :routine-product-mobile="true" :error-messgage="errorMessgage" :one-variant="false" :pfd-title="itemProductName" :product-name="productName" :tag-event="true"  @closepopup="closepopup()"></lrp-retailer>
          </div>
          <div class="carousel-navigation">
            <div v-if="(desktopScreen && tab.Benefits.length > 3) || !desktopScreen" class="carousel__arrow carousel__arrow--left" slot="button-prev" @click="prev(index)" :class="{ 'disabled': swiperAtBegain }" />
            <div v-if="(desktopScreen && tab.Benefits.length > 3) || !desktopScreen" class="carousel__arrow carousel__arrow--right" slot="button-next" @click="next(index)" :class="{ 'disabled': swiperAtEnd }" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import BuyNow from '@Feature/buynow/code/Scripts/components/buynow.vue';
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
import LrpRetailer from '@Feature/LrpRetailer/code/Scripts/components/lrp-retailer.vue';
export default {
  name: 'routine-v2',
  components: { Swiper, SwiperSlide, BuyNow, LrpRetailer},
  props: {
    routineData: {
      type: Array,
      required: false,
      default: undefined
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    currencyCode: {
      type: String,
      required: false,
      default: ''
    },
    colorTheme: {
      type: String,
      required: false,
      default: '#009FE3'
    },
    capacityText: { type: String, required: false, default: 'Capacity' },
    colorText: { type: String, required: false, default: 'Color' },
    variantDetail: { type: Array, required: false, default: [] },
    volumesDetail: { type: Array, required: false, default: [] },
    colorsDetails: { type: Array, required: false, default: [] },
    colorList: { type: Array, required: false, default: [] },
    productEan: { type: String, required: false, default: '' },
    dataVariantEan: { type: String, required: false, default: '' },
    initialData: { type: Object, required: false, default: [] },
    dataBynowTagging: { type: Object, required: false, default: [] },
    errorMessgage: { type: String, required: false, default: '' },
    dataTextLable: { type: String, required: false, default: '' },
    isCommerceConnectorActivated: { type: String, required: false, default: '' },
    isLrpRetailerEnabled : { type: Boolean, required: false, default: '' },
    productIds: { type: Array, required: false, default: [] },
    productId: { type: String, required: false, default: '' },
    oneVariant : { type: Boolean, required: false, default: '' },
    productName : { type: Boolean, required: false, default: '' },
    pageCategory: {
      type: String, required: false, default: ''
    }
  },
  data() {
    return {
      retailerPopup: false,
      productItemID: '',
      productImg:'',
      ctatext:'buynow',
      itemProductName:'',
      buynow:false,
      currentLink: undefined,
      swiperAtEnd: false,
      swiperAtBegain: true,
      routineArray: [],
      currentTabItem: [],
      getTabId: this.currentTabItem,
      selectedIndex: 0,
      desktopScreen: window.matchMedia('(min-width: 1024px)').matches,
      swiperOption: {
        pagination: '.swiper-pagination',
        spaceBetween: 16,
        navigation: {
          nextEl: '.carousel__arrow carousel__arrow--left',
          prevEl: '.carousel__arrow carousel__arrow--right',
          hiddenClass: '.swiper-button-hidden',
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 1,
          },
          320: {
            slidesPerView: 1,
          },
        },
      },
      isTrigger: true,
      routineDataArray: [],
    };
  },

  created() {
    document.documentElement.style.setProperty('--active-color', this.colorTheme);
    if (window.innerWidth >= 1024) {
      if (this.routineData[0].Benefits.length <= 3) {
        this.swiperOption.allowTouchMove = false;
      } else {
        this.swiperOption.allowTouchMove = true;
      }
    }

    if (this.desktopScreen) {
      for (var i = 0; i <= 3; i++) {
        if(this.routineData[0].Benefits[i] != undefined) {
          this.routineArray.push(this.routineData[0].Benefits[i].TaggingEvents);
        }
      }
    } else {
      this.routineArray.push(this.routineData[0].Benefits[0].TaggingEvents);
    }
  },
  destroyed() {
    document.documentElement.style.removeProperty('--active-color');
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll);
    window.eventBus.$on('lrpretailer', payload => {
      this.retailerPopup =payload;
    });
    window.eventBus.$on('retailerbuynow', payload => {
      this.buynow =payload;
    });
  },
  methods: {
    gotoTab($event,tabId,index) {
      this.selectedIndex = index;
      this.swiperAtBegain = true;
      this.swiperAtEnd = false;
      if (window.innerWidth >= 1024) {
        if (this.routineData[index].Benefits.length <= 3) {
          this.swiperOption.allowTouchMove = false;
        } else {
          this.swiperOption.allowTouchMove = true;
        }
      }
      this.$refs.swiper[index].destroySwiper();
      this.$refs.swiper[index].initSwiper();
    },
    prev(index) {
      this.$refs.swiper[index].$swiper.slidePrev();
      this.swiperAtEnd = this.$refs.swiper[index].$swiper.isEnd;
      this.swiperAtBegain = this.$refs.swiper[index].$swiper.isBeginning;
      //this.whenPageChange(index);
    },
    next(index) {
      this.$refs.swiper[index].$swiper.slideNext();
      this.swiperAtBegain = this.$refs.swiper[index].$swiper.isBeginning;
      this.swiperAtEnd = this.$refs.swiper[index].$swiper.isEnd;
      //this.whenPageChange(index);
    },
    showRetailer(item) {
      this.retailerPopup = true;
      this.productItemID = item.ProductItemID;
      this.productImg= item.ProductImg;
      this.itemProductName= item.ProductName;
      this.buynow=true;
      this.ctatext =item.CTAText;
      var productName = item.TaggingEvents.name;
      var selectedid = item.TaggingEvents.id;
      var dimension48 = item.TaggingEvents.dimension48;
      //this.productBuynowTag(productName, selectedid, dimension48);
    },
    closepopup() {
      this.retailerPopup = false;
      this.buynow = false;
    },
    handleScroll() {
      if (this.observer) return; // Ensure observer is created only once

      const element = document.getElementById('benefits');

      if (!element) return;

      this.observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (this.isTrigger && entry.isIntersecting) {
            console.log(entry.isIntersecting);

            AnalyticsHandler.pushDataLayer({
              event: 'nievent',
              event_name: 'view_item_list',
              eventCategory: 'Ecommerce',
              eventAction: 'Product Impressions',
              eventLabel: 'Product detail page',
              ecommerce: {
                currencyCode: this.currencyCode,
                impressions: this.routineArray
              }
            });

            this.isTrigger = false;
            this.observer.disconnect(); // Disconnect observer to avoid repeated triggers
          }
        });
      });

      this.observer.observe(element);
    },
    productBuynowTag(productName, selectedid, dimension48) {
      AnalyticsHandler.pushDataLayer({
        'event': 'uaevent',
        'event_name': 'buy_now',
        'ecommerce': 'undefined',
        'eventCategory': 'Ecommerce',
        'eventAction': 'click to buy',
        'eventLabel': `${[productName]}::${[dimension48]}`,
        'product_info': `${[productName]}::${[selectedid]}`,
      });
    },

    learnMoretagging(item, index) {
      AnalyticsHandler.pushDataLayer({
        event: 'productClick',
        event_name: 'select_item',
        eventCategory: 'Ecommerce',
        eventAction: 'Product Click',
        eventLabel: `${[item.TaggingEvents.name]}::${[item.TaggingEvents.dimension48]}`,
        ecommerce: {
          click:{
            actionField:{
              list: 'cross-sell'
            },
            products:{                
                'name': `${[item.TaggingEvents.name]}`,
                'id': `${[item.TaggingEvents.id]}`,
                'brand': `${[item.TaggingEvents.brand]}`,
                'category': `${[item.TaggingEvents.category]}`,
                'position': `${[index + 1]}`,
                'variant': `${[item.TaggingEvents.variant]}`,
                'price': `${[item.TaggingEvents.price]}`,
                'dimension35': `${[item.TaggingEvents.dimension35]}`,
                'dimension36': `${[item.TaggingEvents.dimension36]}`,
                'dimension38': `${[item.TaggingEvents.dimension38]}`,
                'dimension48': `${[item.TaggingEvents.dimension48]}`,
                'dimension49': `${[item.TaggingEvents.dimension49]}`,
                'dimension59': `${[item.TaggingEvents.dimension59]}`,
                'dimension91': `${[item.TaggingEvents.dimension91]}`,
                'product_label':`${[item.TaggingEvents.product_label]}`,
                'product_franchise': `${[item.TaggingEvents.product_franchise]}`,
                'product_quantity_range':`${[item.TaggingEvents.product_quantity_range]}`
            }
          }                
        }
        
      });
    },

    routineTagging(index) {
      var indexValue = this.$refs.swiper[index].$swiper.activeIndex;
      let slideTitle = this.decodeHtmlstring(this.routineData[0].Benefits[indexValue].ProductName);
      let maintitle = this.decodeHtmlstring(this.title).toLowerCase();
      AnalyticsHandler.pushDataLayer({
        'event': 'uaevent',
        'ecommerce': 'undefined',
        'event_name': 'slider_button_click',
        'eventCategory': this.pageCategory,
        'eventAction': 'select::slider navigation elements',
        'eventLabel': `${maintitle}::${slideTitle.toLowerCase()}::${indexValue + 1}`,
        'cta_name': `${maintitle}::${slideTitle.toLowerCase()}::${indexValue + 1}`,
        'module_name': `slider navigate::${maintitle}`,
      });
    },
    decodeHtmlstring(html) {
      let div = document.createElement("div");
      div.innerHTML = html;
      let datas=div.innerText.replaceAll('\n', ' ');
      return datas;
    },
    getActiveTabProducts(index){
      this.routineDataArray=[];
      for (var i = 0; i <= this.routineData[index].Benefits.length; i++) {
        if(this.routineData[index].Benefits[i] != undefined) {
          this.routineDataArray.push(this.routineData[index].Benefits[i].TaggingEvents);
        }
      }
    },
    tabTagging(ctaName){
      AnalyticsHandler.pushDataLayer({
        event:'uaevent',
        ecommerce:'undefined',
        event_name: 'body_button_click',
        eventCategory: '',
        eventAction: '',
        eventLabel:'',
        cta_name:ctaName.toLowerCase(),
        link_url:'',
        module_name:'routine component'

      })
    },
    tabProductTagging(index){
     this.getActiveTabProducts(index);
      AnalyticsHandler.pushDataLayer({
              event: 'nievent',
              event_name: 'view_item_list',
              eventCategory: 'Ecommerce',
              eventAction: 'Product Impressions',
              eventLabel: this.pageCategory,
              ecommerce: {
                currencyCode: this.currencyCode,
                impressions: this.routineDataArray
              }
            });
      
    }
  }
};
</script>

<style lang="scss" src="./routine.scss"></style>
