<template>
<div class="article-highlight-push-container">
    <div class="article-highlight-push" :class="[articleHighlightPush.BackgroundColor === 'blue' ? 'blueTheme' : 'greyTheme']">
      <div class="article-highlight-push__left">
      <div class="article-highlight-push__image">
        <img :src="articleHighlightPush.Image" :alt="decodeHtml(articleHighlightPush.Title)" loading="lazy" />
      </div>
      <div  class="article-highlight-push__disclaimer desktop" v-if="articleHighlightPush.Disclaimer && desktopScreen" v-html="decodeHtml(articleHighlightPush.Disclaimer)"></div>
      </div>
      <div class="article-highlight-push__content">
        <div class="article-highlight-push__title highlight" v-if="articleHighlightPush.Title" v-html="truncate(articleHighlightPush.Title, ellipsisLimitTitle)"></div>
        <div class="article-highlight-push__desc highlight" v-if="articleHighlightPush.Text" v-html="truncate(articleHighlightPush.Text, ellipsisLimitDescription)"></div>
        <a :href="articleHighlightPush.CTALink" class="highlight" :class="ctaClass"  v-if="articleHighlightPush.CTALabel" @click="tagging()">
          <span v-html="articleHighlightPush.CTALabel" />
        </a>
      </div>
      <div  class="article-highlight-push__disclaimer mobile" v-if="articleHighlightPush.Disclaimer && !desktopScreen" v-html="decodeHtml(articleHighlightPush.Disclaimer)"></div>
    </div>
  </div>
</template>
<script>
import ResponsiveImage from '@Feature/ResponsiveImage/code/Scripts/components/responsive-image.vue';
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
export default {
    name:"article-highlight-push",
    components: { ResponsiveImage },
    props: {
      articleHighlightPush: { type: Object, required: true, default() {
        return undefined;
      } },
      pageCategory: {
        type: String, required: false, default: ''
      }
    },
    data() {
      return {
        ellipsisLimitTitle: 81,
        ellipsisLimitDescription: 251,
        desktopScreen: window.matchMedia('(min-width: 768px)').matches,
      }
    },
    computed: {
      ctaClass() {
        return this.articleHighlightPush.CtaTypeViewModel.CtaType+' '+this.articleHighlightPush.CtaTypeViewModel.CtaDesign+' '+ this.articleHighlightPush.CtaTypeViewModel.CtaColor
      }
    },
    methods:{
      decodeHtml(html) {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
      },
      truncate(string, number) {
        const txt = string ? string.replace(/<[^>]*>?/gm, '') : '';
        const elli = txt.length > number ? `${txt.slice(0, number - 1)}&hellip;` : txt;
        return elli
      },
      tagging() {
        let mainUrl = window.location.origin;
        let ctalink = this.articleHighlightPush.CTALink;
        let destinationUrl;
        if(ctalink == '/') {
          destinationUrl  = mainUrl;
        }else{
          destinationUrl  = mainUrl+ctalink;
        }
        AnalyticsHandler.pushDataLayer({
          'event': 'uaevent',
          'ecommerce': 'undefined',
          'event_name': 'body_button_click',
          'eventCategory': this.pageCategory,  
          'eventAction': 'select::article highlight',
          'eventLabel': `${this.articleHighlightPush.CTALabel.toLowerCase()}::${destinationUrl ? destinationUrl.toLowerCase() : 'none'}`, 
          'module_name': 'article highlight',
          'cta_name':  this.articleHighlightPush.CTALabel.toLowerCase(),
          'link_url':  `${destinationUrl ? destinationUrl.toLowerCase() : 'none'}`,
          'article_name':this.articleHighlightPush.ArticleName.toLowerCase()
        });
      }
    }
}
</script>
<style lang="scss" src="./article-highlight-push.scss"></style>