import { VueModule } from '@Foundation/utilities';
import Slider from './code/Scripts/components/slider/slider.vue';
import Slide from './code/Scripts/components/slide/slide.vue';

export default VueModule({
  components: {
    Slider,
    Slide
  }
});
