<script>
  import { AnalyticsHandler } from '@Foundation/analyticsHandler';
  import { srcset } from '@Foundation/directives/srcset/srcset';

  export default {
    name: 'before-after-slider',

    directives: { srcset },

    props: {
      a11yButtonAriaLabel: { type: String, required: false, default: 'Visual separator before/after application of: [Product name(s)]' },
      imgAfterAlt: { type: String, required: false, default: 'After' },
      imgAfterSrcset: { type: [Object, String], required: true, default: undefined },
      imgBeforeAlt: { type: String, required: false, default: 'Before' },
      imgBeforeSrcset: { type: [Object, String], required: true, default: undefined },
      pageCategory: { type: String, required: true, default: 'Page category' }
    },

    data() {
      return {
        userInteraction: false,
        width: 50,
      };
    },

    watch: {
      /**
       * Restrains width between 0 and 100
       */
      width() {
        if (this.width < 0) {
          this.width = 0;
        } else if (this.width > 100) {
          this.width = 100;
        }
      }
    },

    /**
     * Sets up event listeners for the button
     */
    mounted() {
      this.$el.querySelector('.before-after-slider__button').addEventListener('touchstart', this.resizeInit, false);
      this.$el.querySelector('.before-after-slider__button').addEventListener('touchstart', () => {
        this.onUserInteraction();
      }, { once: true });

      this.$el.querySelector('.before-after-slider__button').addEventListener('mousedown', this.resizeInit, false);
      this.$el.querySelector('.before-after-slider__button').addEventListener('mousedown', () => {
        this.onUserInteraction();
      }, { once: true });
    },

    methods: {
      /**
       * Handles button key events
       * @param {object} event - Key press event
       * @returns {undefined}
       */
      onKeydown(event) {
        const keyRightArrow = 37;
        const keyLeftArrow = 39;
        const keyHome = 36;
        const keyEnd = 35;

        if (event.keyCode === keyRightArrow) {
          this.widthUpdate(-10);
        }

        if (event.keyCode === keyLeftArrow) {
          this.widthUpdate(10);
        }

        if (event.which === keyHome) {
          event.preventDefault();
          this.widthUpdate(-100);
        }

        if (event.which === keyEnd) {
          event.preventDefault();
          this.widthUpdate(100);
        }
      },

      /**
       * Toggle a boolean once an user interaction occurs
       * @returns {undefined}
       */
      onUserInteraction() {
        if (!this.userInteraction) {
          this.userInteraction = true;
          this.tmPush();
        }
      },

      /**
       * Updates width based on resize events
       * @param {object} event - Touch- or mouse event
       * @returns {undefined}
       */
      resize(event) {
        const wrapperOffsetLeft = this.$el.querySelector('.before-after-slider__wrapper').offsetLeft;
        const wrapperWidth = this.$el.querySelector('.before-after-slider__wrapper').clientWidth;
        let xCoordinate;

        if (event.type === 'touchmove') {
          xCoordinate = event.targetTouches[0].pageX;
        }

        if (event.type === 'mousemove') {
          xCoordinate = event.clientX;
        }

        this.width = (100 / wrapperWidth) * (xCoordinate - wrapperOffsetLeft);
      },

      /**
       * Adds event listeners
       * @returns {undefined}
       */
      resizeInit() {
        window.addEventListener('touchmove', this.resize, false);
        window.addEventListener('touchend', this.resizeStop, false);
        window.addEventListener('mousemove', this.resize, false);
        window.addEventListener('mouseup', this.resizeStop, false);
      },

      /**
       * Removes event listeners
       * @returns {undefined}
       */
      resizeStop() {
        window.removeEventListener('touchmove', this.resize, false);
        window.removeEventListener('touchend', this.resizeStop, false);
        window.removeEventListener('mousemove', this.resize, false);
        window.removeEventListener('mouseup', this.resizeStop, false);
      },

      /**
       * Pushes an object to the dataLayer
       * (Unable to use v-tag directive because it should only push an object once)
       * @returns {undefined}
       */
      tmPush() {
        AnalyticsHandler.pushDataLayer({
          'ecommerce': undefined,
          'event': 'uaevent',
          'eventAction': 'select::before after',
          'eventCategory': this.pageCategory,
          'eventLabel': 'before after',
        });
      },

      /**
       * Adds a value
       * @param {number} percentage - Value to add
       * @returns {undefined}
       */
      widthUpdate(percentage) {
        this.width += percentage;
        this.onUserInteraction();
      },
    },
  };
</script>

<style lang='scss' src="./before-after-slider.scss"></style>
