<template>
  <div class="ingredient">
    <div class="images-mosaic-intro">
      <div class="images-mosaic-intro-title">
        <div class="rich-text">
          <h2 v-html="title"></h2>
        </div>
      </div>
      <p class="images-mosaic-intro-description" v-html="description"></p>
    </div>
    <ImagesMosaic  :is-show-num="true" break-point="1024px">
      <template v-for="(image, idx) in imgGroup">
        <div class="images-mosaic-items-list-item" :key="idx">
          <a href="#">
            <img :src="image.BackgroundImage" v-srcset="{ '0': image.BackgroundImage }" alt="">
            <noscript>
              <img :src="image.BackgroundImage" :alt="image.BackgroundImage.match(/.*\/(.*)$/)[1]">
            </noscript>
            <p v-html="image.MosaicDescription"></p>
          </a>
        </div>
      </template>
    </ImagesMosaic>
    <div class="btn">
      <a class="btn__wrapper" :href="ctaLink">
        {{ ctaText }}
      </a>
    </div>
  </div>
</template>

<script>
  import ImagesMosaic from '@Feature/ImagesMosaic/code/Scripts/components/ImagesMosaic';
  export default {
    name: 'ingredient-stared',
    components: {
      ImagesMosaic
    },
    props: {
      title: {
        type: String,
        required: false,
        default: undefined
      },
      description: {
        type: String,
        required: false,
        default: undefined
      },
      ctaLink: {
        type: String,
        required: false,
        default: undefined
      },
      ctaText: {
        type: String,
        required: false,
        default: undefined
      },
      imgGroup: {
        type: Array,
        required: false,
        default: undefined
      }
    },
  };
</script>
