export async function LogoutCall(api, url) {
  const response = await fetch(`${window.location.origin}/${api}`);
  const responseStatus = await response.json();

  /* istanbul ignore else */
  if (responseStatus.status) {
    window.location.replace(url);
  }
};

export const logout = { // eslint-disable-line
  name: 'logout',
  bind: (el, binding) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      LogoutCall(binding.value, el.getAttribute('href'));
    });
  }
};
