<template>
  <div class="glossary-filter">
    <ul class="filter" ref="containerFilter">
      <template v-for="(groupAlpha, idx) in apiData.Ingredientcategories">
        <li
          @click="(selectedCategory = groupAlpha.Category), (currentPage = 1), (page = 1)"
          :class="{ selected: selectedCategory == groupAlpha.Category }"
          :key="idx"
        >
          <p>{{ groupAlpha.Category }}</p>
        </li>
      </template>
    </ul>
    <template v-for="(data, dataIdx) in ingredientData">
      <div :key="dataIdx">
        <Ingredient
          v-show="data.Description != ''"
          :title="data.Title"
          :index="data.Index"
          :data-group="data.Group"
          :description="data.Description"
          :cta-link="data.CtaLink"
          :page-category="pageCategory"
        />
        <IngredientStared
          v-if="data.IsStar"
          :index="data.Index"
          :data-group="data.Group"
          :title="data.ImgGroup.Title"
          :description="data.ImgGroup.Description"
          :img-group="data.ImgGroup.MosaicItemsList"
          :cta-text="data.ImgGroup.StarCtaText"
          :cta-link="data.ImgGroup.StarCtaLink"
        />
      </div>
    </template>
    <template>
      <div class="col-xs-12">
        <nav class="ingredient-pagination">
          <ul v-show="numberOfPages > 1">
            <!-- previous arrow -->
            <li class="pagination-previous arrow" v-if="currentPage !== 1">
              <button
                name="li-btn"
                type="button"
                class="li-pagination-btn"
                @click="
                  page = page - 1;
                  scrollTo();
                "
              >
                ❮ prev
              </button>
            </li>
            <!-- previous arrow -->
            <!-- 4 or less -->
            <li
              class="number list"
              v-show="currentPage < maxPagesForSimpleView"
              v-for="(pageNumber, pageidx) in pages.slice(0, 3)"
              :key="'l-' + pageidx"
            >
              <button
                :id="`btn-${pageNumber}`"
                name="li-btn"
                type="button"
                class="li-pagination-btn"
                :class="{ 'li-btn-active': currentPage === pageidx + 1 }"
                @click="
                  page = pageNumber;
                  scrollTo();
                "
              >
                {{ pageNumber }}
              </button>
            </li>
            <!-- 4 or less -->
            <!-- more than 5 -->
            <li class="number list" v-if="currentPage >= maxPagesForSimpleView" @click="page">
              <button
                :id="`btn-1`"
                name="li-btn"
                type="button"
                class="li-pagination-btn"
                @click="
                  page = 1;
                  scrollTo();
                "
              >
                1
              </button>
            </li>

            <li class="number list" v-if="currentPage >= maxPagesForSimpleView">...</li>

            <li
              v-show="currentPage >= maxPagesForSimpleView"
              :key="pageNext"
              v-for="(pageNumber, pageNext) in getPagesToShow()"
            >
              <button
                :id="`btn-${pageNumber}`"
                name="li-btn"
                type="button"
                class="li-pagination-btn"
                :class="{ 'li-btn-active': currentPage === pageNumber }"
                @click="
                  page = pageNumber;
                  scrollTo();
                "
              >
                {{ pageNumber }}
              </button>
            </li>

            <li
              class="number list"
              v-show="numberOfPages > maxPagesForSimpleView && currentPage < numberOfPages - 2"
            >
              ...
            </li>

            <li v-show="numberOfPages > maxPagesForSimpleView">
              <button
                :id="`btn-${numberOfPages}`"
                name="li-btn"
                type="button"
                class="li-pagination-btn"
                :class="{ 'li-btn-active': currentPage === numberOfPages }"
                @click="
                  page = numberOfPages;
                  scrollTo();
                "
              >
                {{ numberOfPages }}
              </button>
            </li>
            <!-- more than 5 -->
            <!-- next arrow  -->
            <li class="pagination-next arrow" v-if="currentPage !== numberOfPages">
              <button
                name="li-btn"
                type="button"
                class="li-pagination-btn"
                @click="
                  page = page + 1;
                  scrollTo();
                "
              >
                next ❯
              </button>
            </li>
            <!-- next arrow -->
          </ul>
        </nav>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import Ingredient from './ingredient';
import IngredientStared from './ingredientStared';

export default {
  name: 'ingredient-list',
  components: {
    Ingredient,
    IngredientStared,
  },
  props: {
    content: {
      type: String,
      required: false,
      default: '',
    },
    pageCategory: {
        type: String,
        default: ''
    }
  },
  data() {
    return {
      componentLoaded: false,
      apiData: [],
      selectedCategory: '',
      pages: [],
      page: 1,
      perPage: 10,
      numberOfPages: 1,
      currentPage: 1,
      arrObj: [],
      maxPagesForSimpleView: 4,
    };
  },
    computed: {
    filteredAlpha() {
      if (this.componentLoaded) {
        const category = this.selectedCategory;
        return this.apiData.Ingredientcategories.filter(function (alpha) {
          return alpha.Category === category;
        });
      }
    },
    // this is not shown all data from filteredAlpha
    ingredientData() {
      if (this.componentLoaded) {
        this.arrObj = [];
        for (let i = 0; i <= this.filteredAlpha[0].IngredientGroupList.length - 1; i++) {
          for (
            let j = 0;
            j <= this.filteredAlpha[0].IngredientGroupList[i].IngredientList.length - 1;
            j++
          ) {
            this.arrObj.push(this.filteredAlpha[0].IngredientGroupList[i].IngredientList[j]);
          }
        }
        this.numberOfPages = Math.ceil(this.arrObj.length / this.perPage);
        this.pages = [];
        for (let index = 1; index <= this.numberOfPages; index++) {
          this.pages.push(index);
        }
        return this.paginate(this.arrObj);
      }
    },
  },
  created() {
    this.apiCall();
  },
  methods: {
    async apiCall() {
      const headers = { 'Content-Type': 'application/json' };
      const url = this.content;
      axios.get(url, { headers }).then((result) => {
        this.apiData = JSON.parse(result.data);
        this.componentLoaded = true;
        this.selectedCategory = this.apiData.Ingredientcategories[0].Category;
      });
      return this.apiData;
    },
    paginate(pro) {
      this.currentPage = this.page;
      const page = this.page;
      const perPage = this.perPage;
      const from = page * perPage - perPage;
      const to = page * perPage;
      return pro.slice(from, to);
    },
    getPagesToShow() {
      if (this.numberOfPages > this.maxPagesForSimpleView) {
        if (this.currentPage < this.maxPagesForSimpleView) {
          return [2];
        } else if (this.currentPage > this.numberOfPages - 2) {
          return [this.numberOfPages - 1];
        }
        return [this.currentPage - 1, this.currentPage, this.currentPage + 1];
      }
      return this.numberOfPages;
    },
    scrollTo() {
      const element = this.$refs.containerFilter,
        offset = element.offsetTop;

      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    },
  },
};
</script>
<style lang="scss" src="./glossary.scss"></style>
