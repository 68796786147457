<template>
    <div class="whatsapp" id="livechat" :class="[{'hidewhatsapp': isInactive},{ 'is-active': active }]">
        <img alt="close" class="closeIcon desktopview" :src="closeIcon" @click="hidewhatsapp()"/>
        <img alt="close" class="closeIcon mobileview" :src="closeIconMobile" @click="hidewhatsapp()"/>
        <div class="whatsapp-container">
            <a :href="link" target="_blank" @click="gaTagging()">
              <div class="title">
                <p>{{title}}</p>
              </div>
              <div class="whatsappicon">
                <img alt="whatsapp" v-if="defaultSrc" class="defaultImage" :src="defaultSrc" />
                <img alt="whatsapp" v-if="hoverSrc" class="hoverImage" :src="hoverSrc" />
              </div>
            </a>
        </div>
    </div>

</template>
<script>
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
import backtotopWhatsappScroll from '@Foundation/utilities/scroll/backtotopWhatsappScroll.js';
export default {
    name:"whatsapp",
    data () {
      return {
        isInactive:false,
        active: false,
        prev: window.pageYOffset
      }
    },
    mixins: [backtotopWhatsappScroll],
    props: {
      title: {
        type: String,
        default: undefined
      },
      link: {
        type: String,
        default: undefined
      },
      defaultSrc: {
        type: String,
        default: undefined
      },
      hoverSrc: {
        type: String,
        default: undefined
      },
      closeIcon: {
        type: String,
        default: undefined
      },
      closeIconMobile: {
        type: String,
        default: undefined
      },
      taggingCountry:{
        type: Boolean,
        default: false,
      }
    },
    mounted() {
      this.setFingerPrintPos();
      window.addEventListener('resize', this.setFingerPrintPos);
    },
    methods: {
      hidewhatsapp() {
        this.isInactive = true;
      },
      setFingerPrintPos() {
        let calc =  document.querySelector('#main-container').getBoundingClientRect();
        let elem = document.getElementById('main-container');
        let style = getComputedStyle(elem); 
        let isRTL = style.direction === 'rtl'
        if(isRTL) {
              document.getElementById('livechat').style.right = `${calc.x}px`;
        }else {
            document.getElementById('livechat').style.left = `${calc.x}px`;
        }
           
      },
      gaTagging(){
       if(this.taggingCountry=== true){
          AnalyticsHandler.pushDataLayer({
          'event': 'uaevent',
          'ecommerce': '',
          'event_name': 'Click to Whatsapp',
          'eventCategory': 'floating navigation',
          'eventAction': 'click_to_whatsapp',
          'eventLabel': 'whatsapp'          
        });
       }else{
          AnalyticsHandler.pushDataLayer({
          'event': 'uaevent',
          'ecommerce': 'undefined',
          'event_name': 'start_live_chat',
          'eventCategory': 'main menu navigation',
          'eventAction': 'select::live chat',
          'eventLabel': 'start'          
        });
      }
    }
  }
}
</script>

<style>

</style>