<template>
  <div class="how-to-apply">
    <h2 class="how-to-apply-title" v-html="mainTitle"/>
    <div class="how-to-apply-container">
      <div class="how-to-apply-container-visual">
        <div class="visual-wrapper">
          <img v-if="!youtubeId" :src="howtoapplyImage" :alt="imgAlt" class="imageclass">
          <generic-banner-youtube-player v-else-if="youtubeId && popUp === 'true'" :youtube-id="youtubeId"
                                         :poster="howtoapplyImage"
                                         :autoplay="autoplay"
                                         aria-label="Play video"
                                         :newplay-button="true"
                                         :play-text="playbuttontext"/>
          <youtube-player v-else-if="youtubeId && popUp === 'false'" :youtube-id="youtubeId"
                          :poster="howtoapplyImage"
                          :autoplay="autoplay"
                          aria-label="Play video"
                          :newplay-button="true"
                          :play-text="playbuttontext"/>
        </div>
      </div>
      <div class="how-to-apply-container-label">
        <ul>
          <li v-for="(item,index) in labelData.LabelDetails" :key="index">
            <img :src="item.Icon" :alt="imgAlt">
            <div class="label-wrapper">
              <div class="label-wrapper-name" v-html="item.LabelTitle"/>
              <div class="label-wrapper-description" v-html="item.LabelDescription"/>
            </div>
          </li>
        </ul>
      </div>
      <div class="how-to-apply-container-collapse">
        <collapse v-show="youtubeId" class="transcript__collapse" :aria-label="'show transcript'">
          <template slot="title" slot-scope="scoped">
            <template v-if="scoped.collapsed">
              <span class="show-transcript" v-html="showTranscript"/><span class="arrow down"/></template>
            <template v-else>
              <span class="hide-transcript" v-html="hideTranscript"/><span class="arrow up"/></template>
          </template>
          <span v-html="transcriptText"/>
        </collapse>
      </div>
    </div>
  </div>
</template>
<script>
  import GenericBannerYoutubePlayer from '@Feature/GenericBannerYoutubePlayer/code/Scripts/components/generic-banner-youtube-player.vue';
  import YoutubePlayer from '@Feature/YoutubePlayer/code/Scripts/components/youtube-player.vue';
  import Collapse from '@Feature/Collapse/code/Scripts/components/collapse.vue';
  export default {
    name: 'how-to-apply',
    components: {
      GenericBannerYoutubePlayer,
      YoutubePlayer,
      Collapse
    },
    props: {
      labelData: {
        type: Array / Object,
        default: undefined
      },
      colorTheme: {
        type: String,
        required: false,
        default: '#009FE3'
      },
      mainTitle: { type: String, required: true, default: undefined },
      youtubeId: { type: String, required: false, default: undefined },
      howtoapplyImage: { type: String, default: undefined },
      playbuttontext: { type: String, required: false, default: undefined },
      popUp: { type: String, required: false, default: undefined },
      autoplay: { type: Boolean, required: false, default: undefined },
      ariaLabel: { type: String, default: 'play pause', required: false },
      showTranscript: { type: String, default: 'show transcript', required: false },
      hideTranscript: { type: String, default: 'hide transcript', required: false },
      transcriptText: { type: String, default: '', required: false },
      imgAlt: { type: String, required: false, default: '' },
    },
    created() {
      document.documentElement.style.setProperty('--theme-color', this.colorTheme);
    },
    destroyed() {
      document.documentElement.style.removeProperty('--theme-color');
    },
  };
</script>

<style lang="scss" src="./how-to-apply.scss"></style>
