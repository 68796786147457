<template>
  <span>
    <div class="pg_hydraphase">
      <div class="ctn_landing clearfix">
        <div class="col_left">
          <div v-if="StepTwoUrlshow">
            <template v-for="productlist in initialData">
              <div class="sec-content">
                <div class="ctn_header" v-if="productlist.PanelKey===selectedPanelKeyUrl">
                  <h2><strong><span v-html="productlist.PanelTopTitle"/></strong></h2>
                  <h3 v-html="productlist.PanelTopDiscription"/>
                  <input type="hidden" id="productlistEAN" :value="productlist.EAN">
                </div>
                <div class="ctn_soin">
                  <div class="str_soin-product" v-if="productlist.PanelKey===selectedPanelKeyUrl" v-for="(sampletlist, sampleindex) in productlist.SampleInformation" :key="sampleindex">
                    <div class="ctn_soin-product">
                      <p class="t_title" v-html="sampletlist.Title"/>
                      <h2 v-html="sampletlist.Title2" v-if="sampletlist.Title2"/>
                      <p v-html="sampletlist.Discription" class="t_desc"/>
                      <p class="t_ingredients" v-html="sampletlist.Ingrident"/>
                    </div>
                    <img class="background" :src="sampletlist.Image.Url" :alt="sampletlist.Image.AltText">
                  </div>
                </div>
              </div>
            </template>
          </div>

          <div v-else>
            <div class="ctn_header">
              <h2><strong><span v-html="data.Title"/></strong></h2>
              <h3 v-html="data.Discription"/>
            </div>
            <div class="ctn_soin">
              <div class="str_soin-product" v-for="(productlist, index) in initialData" :key="index">
                <div class="ctn_soin-product">
                  <p class="t_title" v-html="productlist.Title"/>
                  <h2 v-html="productlist.Title2"/>
                  <p v-html="productlist.Discription" class="t_desc"/>
                  <p class="t_ingredients" v-html="productlist.Ingrident"/>
                </div>
                <img class="background" :src="productlist.Image.Url" :alt="productlist.Image.AltText">
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="pg_hydraphase" v-if="!StepTwoUrlshow">
      <div class="obj_form obj_bloc clearfix a">
        <div class="ctn_step step-1 step-dispatch step_1">
          <p class="t_form" v-html="data.FormBoxText"/>
          <fieldset>
            <div class="ctn_form">
              <div class="ctn_col">
                <div class="ctn_radio var_col">
                  <div class="form-title" v-html="data.ContinueText"/>
                  <div class="ctn_form-element" v-for="(productlist, index) in initialData" :key="index">
                    <input type="radio" :id="productlist.PanelKey" name="PanelKey" :value="productlist.PanelKey" @change="selectedPanel(productlist.PanelKey,index)" required="required" >
                    <span class="checkmark"/>
                    <label :for="productlist.PanelKey"><span v-html="productlist.Title"/><p v-html="productlist.Title2"/></label>
                  </div>
                </div>
                <p class="generic__error" v-if="ValidationFalse">{{ data.ValidationMessage }}</p>
                <p class="ctn_btn"><a class="obj_btn" @click="redirection" target="_blank">{{ data.SubmitLabel }}</a></p><br>
                <p class="generic__error" v-if="ValidationFalse">{{ data.ContinueText }}</p>
              </div>
            </div></fieldset>
        </div>
      </div>
    </div>
  </span>
</template>
<script>
  export default {
    name: 'hub-sampleform',
    props: {
      data: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        initialData: [],
        StepTwoUrl: '',
        selectedPanelKey: '',
        selectedPanelIndex: 0,
        StepTwoUrlshow: false,
        selectedPanelKeyUrl: '',
        ValidationFalse: false,
      };
    },
    created() {
      this.initialData = this.data.PanelItem;
      this.StepTwoUrl = this.data.StepTwoUrl.Url;

      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      this.selectedPanelKeyUrl = params.StepTwoUrlshow;
      if (this.selectedPanelKeyUrl !== undefined) {
        this.StepTwoUrlshow = true;
      }
    },
     methods: {
      removeTags(str) {
        if ((str===null) || (str===''))
        return false;
        else
        str = str.toString();
        return str.replace( /(<([^>]+)>)/ig, '');
      },
      selectedPanel(selected,index) {
        this.selectedPanelKey = selected;
        this.selectedPanelIndex = index;
      },
      redirection() {       
        let selectedValue1 = this.data.PanelItem[this.selectedPanelIndex].Title;
        let selectedValue2 = this.data.PanelItem[this.selectedPanelIndex].Title2;
        let value1 = this.removeTags(selectedValue1);
        let value2 = this.removeTags(selectedValue2);
        let newValue = encodeURIComponent(value1+' '+value2);

        if (this.selectedPanelKey !== '') {
          window.location.href = `${this.StepTwoUrl }?StepTwoUrlshow=${this.selectedPanelKey}&title=${newValue}`;
        } else {
          this.ValidationFalse = true;
        }

      },
    },
  };
</script>
