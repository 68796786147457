<template>
  <div class="article-section">

    <div class="article-section__wrapper" :class="containerClass" ref="stickySidebar">
      <slot v-if="smallScreen" name="article-header"/>

      <nav v-if="this.$scopedSlots.nav"
           role="navigation"
           aria-label="Table of contents"
           class="article-section__sidebar"

           :class="[{'sticky-block__sidebar--right': sidebarPosition === 'right'}, positionClass]">

        <div class="article-section__sidebar-content"
             :class="[{'fixed': !smallScreen && fixed }, { 'shifted-top': shiftedTop && fixed }]"
             ref="sticky">
          <slot name="nav" :links="links" :current-anchor="currentAnchor"/>
        </div>
      </nav>


      <div class="article-section__content" ref="content">
        <slot v-if="!smallScreen" name="article-header" />
        <slot/>
      </div>
    </div>
  </div>
</template>
<script>
  import { eventBus } from '@EventBus';
  import { tag } from '@Foundation/analyticsHandler';
  import { inViewport, scrollPosition, smoothScroll } from '@Foundation/utilities';

  export default {
    name: 'article-section',
    directives: {
      tag
    },
    props: {
      sidebarPosition: {
        type: String,
        default: 'left'
      },
      headingSelector: {
        type: String,
        default: 'h2'
      },
      containerClass: {
        type: String,
        default: ''
      },
      linkWithHtml: {
        type: Boolean,
        default: false
      },
      hasFixed: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        fixed: false,
        shiftedTop: false,
        positionClass: '',
        links: [],
        currentAnchor: undefined,
        smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
        lastScrollTop: window.pageYOffset || document.documentElement.scrollTop
      };
    },

    mounted() {
      this.links = this.findLinks();

      this.scrollListener = this.onScroll.bind(this);
      window.addEventListener('scroll', this.scrollListener);

      this.resizeListener = this.stickyPosition.bind(this);
      window.addEventListener('resize', this.resizeListener);

      this.screenCheck();
      this.stickyPosition();

      this.$root.$on('menu-hidden', (payload) => {
        payload ? this.shiftedTop = false : this.shiftedTop = true;
      });
    },

    destroyed() {
      window.removeEventListener('scroll', this.scrollListener);
      window.removeEventListener('resize', this.resizeListener);
    },

    methods: {
      findLinks() {
        const hash = {};

        return Array.prototype.map
          .call(this.$refs.content.querySelectorAll(this.headingSelector), (elTitle, index) => {
            const linkLabel = this.linkWithHtml ? (elTitle.innerHTML).trim().replace(/\s{2,}/g, ' ') : (elTitle.innerText).trim().replace(/\s{2,}/g, ' ');
            let title = this.linkWithHtml ? (elTitle.innerHTML).replace(/\s{2,}/g, ' ') : (elTitle.innerText).replace(/\s{2,}/g, ' ');

            let tagging;
            const taggingString = title;

            // Strip html code, `(`, `)` and numbers
            // Replace duplicate spaces by a single one
            title = title
              .replace(/<(?:.|\n)*?>/gm, '')
              .replace(/\(/g, '')
              .replace(/\)/g, '')
              .replace(/\d/g,'')
              .replace(/\s\s+/g, ' ');
            title = title.trim();

            const id = title.toLowerCase().replace(/[^\w\s\n]/g, '').replace(/\s/g, '-');

            hash[id] = hash[id] === undefined ? 0 : hash[id]++;

            elTitle.classList.add('heading--anchor');
            elTitle.setAttribute('id', id);
            elTitle.setAttribute('tabindex', '-1');

            if (this.$refs.stickySidebar.classList.contains('article-section--search')) {
              const taggingLabel = [];
              taggingString.match(/<strong>(.*?)<\/strong>/g).map(function(val) {
                if (val.match(/\d/g) !== null) {
                  taggingLabel.push(val.match(/\d/g).join(''));
                }
              });
              tagging = {
                action: title,
                label: taggingLabel[0]
              };
            }

            return {
              href: `#${id}${hash[id] || ''}`,
              label: linkLabel.replace('\n', ' '),
              tagging,
              scrollTo: (event) => {
                this.scrollTo(elTitle)
                  .then(() => {
                    this.currentAnchor = this.links.findIndex((element) => {
                      return element.href === event.target.getAttribute('href');
                    });
                  });
              }
            };
          });
      },

      scrollTo(el) {
        return new Promise((resolve) => {
          smoothScroll(el);
          /* istanbul ignore next */
          setTimeout(() => {
            resolve();
          }, 500);
        });
      },

      scrollDirection() {
        let direction;
        const st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        if (st > this.lastScrollTop) {
            direction = 'down';
        } else {
            direction = 'up';
        }
        this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        return direction;
      },

      addFloating(target, position) {
        const targetStart = target.offsetTop;
        const targetEnd = (targetStart + target.clientHeight);
        let sideBarEnd;
        this.scrollDirection() === 'up' ? sideBarEnd = this.$refs.sticky.clientHeight + window.pageYOffset + 100 : sideBarEnd = this.$refs.sticky.clientHeight + window.pageYOffset;
        return (targetStart < position && sideBarEnd < targetEnd);
      },

      removeFloating(target, position) {
        const targetStart = target.offsetTop;
        const targetEnd = (targetStart + target.clientHeight);
        const sideBarEnd = this.$refs.sticky.clientHeight + window.pageYOffset;
        return (targetEnd < sideBarEnd || targetStart > position) ? { remove: true, position: targetEnd <= sideBarEnd + 95 ? 'bottom' : '' } : { remove: false };
      },

      getPaddings(elem) {
        const padLeft = parseInt(window.getComputedStyle(document.querySelector(elem), null).paddingLeft, 10);
        const padRight = parseInt(window.getComputedStyle(document.querySelector(elem), null).paddingRight, 10);
        return padLeft + padRight;
      },

      stickyPosition() {
        const stickyPos = this.$refs.stickySidebar.getBoundingClientRect();
        const paddings = this.getPaddings('.article-section__wrapper');

        /*istanbul ignore else*/
        if (this.fixed && !this.smallScreen) {
          this.$refs.sticky.style.width = `${stickyPos.width - this.$refs.content.clientWidth - paddings}px`;
        }
      },

      onScroll() {
        if (this.smallScreen) {
          return;
        }
        /*istanbul ignore else*/
        if (this.hasFixed) {
          this.updateSticky();
          this.updateHighlightedAnchor();
        }
      },

      updateSticky() {
        /*istanbul ignore else*/
        if (this.addFloating(this.$refs.content, scrollPosition())) {
          this.fixed = true;
          this.$nextTick(() => this.stickyPosition());
        } else if (this.removeFloating(this.$refs.content, scrollPosition()).remove) {
          this.positionClass = this.removeFloating(this.$refs.content, scrollPosition()).position;
          this.fixed = false;
        }
      },

      updateHighlightedAnchor() {
        Array.prototype.map
        .call(this.$refs.content.querySelectorAll(this.headingSelector), (elTitle, index) => {

          const oRect = elTitle.getBoundingClientRect();
          const oTop = oRect.top;

          if (oTop > 0 && oTop <= window.innerHeight * 15 / 100 && inViewport(elTitle) && this.currentAnchor !== index) {
            this.currentAnchor = index;
          }
        });
      },

      screenCheck() {
        eventBus.$on('mediaquery::changed', mq => {
          this.smallScreen = !!(mq.size === 'small' || mq.size === 'medium');
        });
      }
    }
  };
</script>
<style lang='scss' src="./article-section.scss"></style>
