<template>
  <div v-if="onSubmitSuccess" :class="['generic-form', cssClassForm]">
    <div class="generic-form__success_message" v-html="submit.successMessage"/>
  </div>
  <div v-else-if="onSubmitFalse" :class="['generic-form', cssClassForm]">
    <div class="generic-form__success_message" v-html="submit.errorMessage"/>
  </div>
  <form v-else
        :id="formId"
        ref="form"
        :action="formAction"
        :class="['generic-form', cssClassForm]"
        :name="formId"
        novalidate
        @submit.prevent="submitEvent">
    <span v-if="this.recaptchaData.hasRecaptcha">
      <load-script :src="this.recaptchaData.captchUrl"/>
    </span>
    <!-- Header -->
    <slot v-if="$slots.header" name="header"/>

    <!-- Row -->
    <div class="generic-form__row">
      <!-- Sections / Columns -->
      <section
        v-for="(section, index) in validatedSections"
        v-show="!section.hidden"
        :key="index"
        class="generic-form__section">
        <!-- Section Title -->
        <component
          :is="section.titleTag"
          v-if="section.title"
          class="generic-form__section-title">
          {{ section.title }}
        </component>

        <!-- Fields -->
        <div
          v-for="(field, fIndex) in section.fields"
          :key="fIndex"
          class="generic-form__field">
          <component
            :is="typeMap[field.type]"
            v-model="formData[field.id]"
            v-bind="field"
            :readonly="field.readOnly"
            @input="field.edited = true"
            :citiesoptions="citiesoptions"
            :zipcodeoptions="zipcodeoptions"
            @response="mapSelectResponse($event)">
            <template v-if="field.label">
              {{ field.label }}
            </template>
            <template v-if="field.helpText" slot="helperText">
              <div class="generic-form__helper-text" v-html="field.helpText"/>
            </template>
          </component>
        </div>

        <!-- Subsections -->
        <div class="generic-form__subsection">
          <div
            v-for="(subSection, sIndex) in section.subSections"
            :key="sIndex">
            <component
              :is="subSection.titleTag"
              v-if="subSection.title"
              class="generic-form__wrapper__title">
              {{ subSection.title }}
            </component>
            <div
              v-for="(subSectionField, sFIndex) in subSection.fields"
              :key="sFIndex"
              class="generic-form__field">
              <component
                :is="typeMap[subSectionField.type]"
                v-model="formData[subSectionField.id]"
                v-bind="subSectionField"
                :readonly="subSectionField.readOnly"
                @input="subSectionField.edited = true">
                <template v-if="subSectionField.label">
                  {{ subSectionField.label }}
                </template>
                <template v-if="subSectionField.helpText" slot="helperText">
                  <div
                    class="generic-form__helper-text"
                    v-html="subSectionField.helpText"
                  />
                </template>
              </component>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!--recapctha-->
    <div class="generic-form__row">
      <section class="generic-form__section" />
      <section class="generic-form__section" />
      <section class="generic-form__section">
        <div class="generic__captcha" :class="{ 'error': hasErrorCapcha }" v-if="this.recaptchaData.hasRecaptcha" align="center">
          <div id="letter-recaptcha" class="g-recaptcha"/>

        </div><p v-if="hasErrorCapcha" class="generic__error generic-errors">{{ this.recaptchaData.errorMessage }}</p>
      </section>
    </div>

    <!-- Footer -->
    <slot v-if="$slots.footer" name="footer"/>

    <div class="generic-form__control submit-button">
      <button
        :class="['generic-form__submit', cssClassButton, submit.customClass]"
        :disabled="submitDisabled">
        <span class="oap-button__label">
          {{ buttonLabel }}
        </span>
      </button>
    </div>

    <!-- error messages -->
    <ul v-show="errorMessageList && !showToasted" aria-live="polite" class="generic-form__error-messages">
      <li v-for="(errors, $key) in errorMessageList"
          :key="$key"
          v-html="errors"/>
    </ul>
  </form>
</template>

<script>
  import Vue from 'vue';

  import GenericInput from './generic-input.vue';
  import GenericOptions from './generic-options.vue';
  import GenericDatepicker from './generic-datepicker.vue';
  import GenericSelect from './generic-select.vue';
  import LoadScript from '@Foundation/loadscript/loadscript';
  export default {
    name: 'generic-form',
    components: {
      LoadScript
    },
    props: {
      data: {
        type: Object,
        required: true
      },
      dataEan: {
        type: Object,
        required: true
      },
      dataAttribute: {
        type: Object,
        required: true
      },
      cssClassForm: {
        type: String,
        required: false,
        default: '',
      },
      cssClassButton: {
        type: String,
        required: false,
        default: 'button-secondary',
      },
      messageTiming: {
        type: Number,
        required: false,
        default: 5,
      },
      dataForm: {
        type: String,
        required: true
      },
      isSingleNotification: {
        type: Boolean,
        default: false
      },
    },
    settings: {
      toastNotificationEventName: 'oap-toast-notification:open'
    },
    data() {
      return {
        richTextError: undefined,
        components: {
          GenericInput,
          GenericOptions,
          GenericDatepicker,
          GenericSelect
        },
        errorMessageList: null,
        formData: {},
        isFormValid: true,
        items: [],
        typeMap: {},
        object: {},
        recaptchaData: {},
        buttonLabel: null,
        submitDisabled: false,
        hasErrorCapcha: false,
        citiesoptions: [],
        zipcodeoptions: [],
        onSubmitSuccess: false,
        onSubmitFalse: false,
        productlistEANValue: '',
      };
    },
    computed: {
      formId() {
        return this.object.formId;
      },
      submit() {
        return this.object.submit;
      },
      formAction() {
        return this.submit.apiUrl;
      },
      validatedSections: {
        get: function () {
          return this.object.sections;
        },
        set: function (nSections) {
          this.object.sections = nSections;
        },
      },
      showToasted() {
        return !this.submit.successPage;
      }
    },
    watch: {
      formData: {
        handler() {
          this.validateFormData(false);
        },
        deep: true,
      },
    },
    created() {
      this.captchaResponse = localStorage.captchaResponse;
      this.loadTypeMap();
      this.init();
      if (this.recaptchaData.hasRecaptcha) {
        window.onCaptchaAPIReady = this.onCaptchaAPIReady;
      }
      setTimeout(() => {
        this.productlistEANValue = document.getElementById("productlistEAN").value;
      }, 200);
    },

    methods: {
      onCaptchaAPIReady() {
        if (this.recaptchaData.hasRecaptcha) {
          setTimeout(() => {
            if (typeof grecaptcha === 'undefined' && typeof grecaptcha.render !== 'function') {
              this.onCaptchaAPIReady();
            } else {
              grecaptcha.render('letter-recaptcha', {
                sitekey: this.recaptchaData.siteKey,
                callback: this.checkCaptcha
              });
            }
          }, 200);
        }
      },
      checkCaptcha() {
        /* istanbul ignore next */
        grecaptcha.getResponse().length !== 0 ? this.hasErrorCapcha = false : this.hasErrorCapcha = true;
        this.captchaValue = grecaptcha.getResponse();
      },
      loadTypeMap() {
        const genericInputType = 'generic-input';
        const genericOptionsType = 'generic-options';

        this.typeMap.text = genericInputType;
        this.typeMap.email = genericInputType;
        this.typeMap.password = genericInputType;
        this.typeMap.textarea = genericInputType;
        this.typeMap.radio = genericOptionsType;
        this.typeMap.checkbox = genericOptionsType;
        this.typeMap.datepicker = 'generic-datepicker';
        this.typeMap.dropdown = 'generic-select';
      },
      init() {
        this.object = this.data;
        this.recaptchaData = this.object.recaptcha;
        let fieldArray = [];

        this.object.sections.forEach((section) => {
          fieldArray = [].concat(section.fields, fieldArray);
          section.fields.forEach((field) => {
            this.fixFieldRequiredValidation(field);
          });

          if (section.subSections) {
            section.subSections.forEach((subSection) => {
              fieldArray = [].concat(subSection.fields, fieldArray);
              subSection.fields.forEach((field) => {
                this.fixFieldRequiredValidation(field);
              });
            });
          }
        });
        this.items = fieldArray;

        //set button label
        this.updateSubmitLabel(false);
      },
      fixFieldRequiredValidation(field) {
        if (field.defaultValue) {
          Vue.set(this.formData, field.id, field.defaultValue);
        } else {
          Vue.set(this.formData, field.id, null);
        }

        //Get values from requiredValidation
        if (field.requiredValidation) {
          field.required = field.requiredValidation.isRequired;
          field.requiredMessage = field.requiredValidation.message;
        }

        //Get values from dateValidation
        if (field.dateValidation) {
          field.startYear = field.dateValidation.startYear;
          field.minAge = field.dateValidation.minAge;
          field.minAgeValidationMessage = field.dateValidation.minAgeValidationMessage;
        }
      },
      getUserIdentifier(payload) {
        const fieldIdentifier = this.items.find(field => field.isUserId);
        const userIdFromPayload = fieldIdentifier ? payload.fields.find(field => field.id == fieldIdentifier.id) : null;

        return userIdFromPayload ? userIdFromPayload.value : '';
      },
       getCurrentPageUrl() {
        const url = window.location.href;
        return url;
      },
      async submitEvent() {
        const payload = this.formatFormData(this.formData);
        if (this.recaptchaData.hasRecaptcha) {
          this.hasErrorCapcha = grecaptcha.getResponse().length !== 0 ? false : true;
        }

        this.validateFormData(true);


        if (!this.isFormValid || this.hasErrorCapcha) {
          this.focusFirstInvalidField();
        } else {
          this.errorMessageList = [];
          this.updateSubmitLabel(true);
          if (this.recaptchaData.hasRecaptcha) {
            grecaptcha.getResponse().length !== 0 ? this.hasErrorCapcha = false : this.hasErrorCapcha = true;
          }
          this.gaTagging();
          payload.userId = this.getUserIdentifier(payload);
          payload.attributes = this.dataAttribute;
          payload.eans = this.dataEan;
          payload.forms = this.dataForm;
          payload.hubEan = this.productlistEANValue;
          payload.currentPageUrl = this.getCurrentPageUrl();
          /* istanbul ignore next */
          fetch(this.formAction, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;charset=utf-8' },
            credentials: 'same-origin',
            body: JSON.stringify(payload),
          })
            .then(this.parseJSON)
            .then((data) => {
              if (data.success) {
                this.onSubmitSuccess = true;
                this.handlerAnalytics(this.data.submit.eventTagging);
                this.handleSuccess(data.redirectUrl);
                this.updateSubmitLabel(false);

                if (this.showToasted) {
                  eventBus.$emit(this.$options.settings.toastNotificationEventName, {
                    type: 'success',
                    message: this.data.submit.successMessage,
                  });
                }
                return;
              }
              this.handleValidationErrorMessages(data);
              this.updateSubmitLabel(false);
              if (!data.success && this.showToasted) {
                eventBus.$emit(this.$options.settings.toastNotificationEventName, {
                  type: 'error',
                  message: this.data.submit.errorMessage,
                });
              }
              if (!data.success) {
                this.onSubmitFalse = true;
              }
              return;
            })
            .catch((error) => {
              console.error('Could not load answer', error); // eslint-disable-line no-console
              this.submitErrorMessages();
              this.updateSubmitLabel(false);
              if (this.showToasted) {
                eventBus.$emit(this.$options.settings.toastNotificationEventName, {
                  type: 'error',
                  message: this.data.submit.errorMessage,
                });
              }
            });
        }
      },
      submitErrorMessages() {
        this.errorMessageList = [];
        this.errorMessageList.push(this.submit.errorMessage);
      },
      handleValidationErrorMessages(data) {
        const hasValidationErrors = data.validationMessages && data.validationMessages.length > 0;

        if (hasValidationErrors) {
          this.errorMessageList = [];

          data.validationMessages.forEach((msg) => {
            this.errorMessageList.push(msg);
          });
        } else {
          this.submitErrorMessages();
        }
      },
      formatFormData(formData) {
        const mappedFields = [];
        Object.keys(formData).forEach((key) => {
          const item = this.items.find((item)=> item.id === key);
          mappedFields.push({ id: key, value: formData[key], apikey: item.ApiKey });
        });
        return {
          id: this.object.formId,
          sitecoreContextItemId: this.object.sitecoreContextItemId,
          fields: mappedFields,
        };
      },
      parseJSON(response) {
        return response.json();
      },
      handleSuccess(redirectPage) {
        if (this.submit.successPage) {
          window.location.href = redirectPage ? redirectPage : this.submit.successPage;
        }
      },
      validateFormData(forceErrorVerification) {
        if (!this.items) {
          return;
        }

        //reset form valid value
        this.isFormValid = true;
        //iterate all items
        this.items.forEach((item) => {
          this.validateFormItem(item, forceErrorVerification);
        });
      },
      errorsBasedOnRegex(itemValue, item) {
        if (!(item.validations && itemValue)) {
          return [];
        }

        const errorMessages = [];
        item.validations.forEach((validation) => {
          const regexp = new RegExp(validation.regex);
          if (!itemValue.match(regexp)) {
            errorMessages.push(validation.message);
          }
        });

        return errorMessages.length > 0 ? errorMessages : [];
      },
      errorsBasedOnEquality(itemValue, item) {
        const hasEqualityCheck = item.equalsValidation
          && item.equalsValidation.equalsTo
          && item.equalsValidation.message
          && item.equalsValidation.message.length > 0;

        if (!hasEqualityCheck) {
          return [];
        }

        const referenceFieldId = item.equalsValidation.equalsTo;
        const referenceFieldValue = this.formData[referenceFieldId];
        const valid = itemValue === referenceFieldValue;

        return referenceFieldValue && !valid ? item.equalsValidation.message : [];
      },
      dateStringToObject(hasDateSelected, itemValue) {
        if (!hasDateSelected) {
          return false;
        }
        const dateValues = itemValue.split('/');
        const month = parseInt(dateValues[1]),
              day = parseInt(dateValues[2]),
              year = parseInt(dateValues[0]);
        return { day: day, month: month, year: year };
      },
      errorsForDatepicker(itemValue, item) {
        if (item.type !== 'datepicker') {
          return [];
        }

        const errorMessages = [];
        const hasDateSelected = itemValue != null && typeof itemValue === 'string';
        const date = this.dateStringToObject(hasDateSelected, itemValue);
        const hasMissingDate = !date || (date && (!date.day || !date.month || !date.year));
        const isRequired = item.required && item.requiredMessage && item.edited && hasMissingDate;
        const hasAgeErrorVerification = hasDateSelected && item.minAge && item.minAge > 0 && item.minAgeValidationMessage;

        if (isRequired) {
          errorMessages.push(item.requiredMessage);
        }

        if (hasAgeErrorVerification) {
          const calculatedYear = date.year + item.minAge;
          const calculatedMonth = date.month - 1;
          const currentDate = new Date();
          const selectedDate = new Date(
            calculatedYear,
            calculatedMonth,
            date.day
          );

          if (currentDate.getTime() < selectedDate.getTime()) {
            errorMessages.push(item.minAgeValidationMessage);
          }
        }

        return errorMessages;
      },
      errorsForRequiredFields(itemValue, item) {
        if (item.type === 'datepicker') {
          return [];
        }

        const hasRequiredMessage = item.required && item.requiredMessage;
        const isEmptyOrNull = (!itemValue || itemValue.length === 0);

        return item.edited && hasRequiredMessage && isEmptyOrNull ? item.requiredMessage : [];
      },
      validateFormItem(item, forceErrorVerification) {
        const itemValue = this.formData[item.id];
        let errorMessages = [];

        //force error verification
        if (!item.edited) {
          item.edited = forceErrorVerification;
        }

        errorMessages = [].concat(this.errorsBasedOnRegex(itemValue, item), errorMessages);
        errorMessages = [].concat(this.errorsBasedOnEquality(itemValue, item), errorMessages);
        errorMessages = [].concat(this.errorsForDatepicker(itemValue, item), errorMessages);
        errorMessages = [].concat(this.errorsForRequiredFields(itemValue, item), errorMessages);

        Vue.set(item, 'errorMessages', errorMessages);

        if (errorMessages && errorMessages.length) {
          this.isFormValid = false;
        }
      },
      async focusFirstInvalidField() {
        this.$nextTick(() => {
          const firstInvalidField = this.$refs.form.querySelectorAll('[aria-invalid="true"]')[0];

          if (firstInvalidField) {
            firstInvalidField.focus();
          }
        });
      },
      updateSubmitLabel(start) {
        this.buttonLabel =
          start && this.submit.requestLabel
            ? this.submit.requestLabel
            : this.submit.label;
        this.submitDisabled = start;
      },
      handlerAnalytics(eventTagging) {
        if (!eventTagging) {
          return;
        }

        if (
          !eventTagging.category ||
          !eventTagging.action ||
          !eventTagging.label
        ) {
          console.warn(
            `Form Event Tagging: Some tagging properties are missing.`
          );
          return;
        }

        AnalyticsHandler.getAnalyticsHandler().push({
          type: eventTagging.type ? eventTagging.type : 'userActionEvent',
          ecommerce: eventTagging.ecommerce
            ? eventTagging.ecommerce
            : 'undefined',
          category: eventTagging.category,
          action: eventTagging.action,
          label: eventTagging.label,
        });
      },
      isEmpty(value) {
        return typeof value === 'undefined' || value == null;
      },
      mapSelectResponse(event) {
        if (event.name === 'cities') {
          this.citiesoptions = event.data;
        }
        if (event.name === 'zipcodes') {
          this.zipcodeoptions = event.data;
        }
      },
      gaTagging(){
        const dataurl = new URL(window.location.href);
        let eventLableValue = decodeURIComponent(dataurl.searchParams.get('title').toLowerCase());
        window.dataLayer.push({
                        event:	'uaevent',
                        ecommerce: 'undefined',
                        eventCategory:	'sample',
                        eventAction:	'select::request sample',
                        eventLabel: eventLableValue                   
                     }); 
     }
    },
  };
</script>
