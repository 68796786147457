<template>
  <div :class="['generic-select generic-form__component', computedClasses]">
    <label
      class="generic-select__label"
      :for="`select-${id}`">
      {{ label }}
      <span v-if="required" class="required" aria-hidden="true">*</span>
    </label>
    <div class="generic-select-dropdown">
      <div v-if="readonly" class="read-only">{{ defaultValue ? defaultValue : label }}</div>      
      <select v-else
              class="generic-select__element form-control" :class="ApiKey"
              v-model="selectedOption"
              @change="handleOnChange"
              @focus="handleFocus(true)"
              @blur="handleFocus(false)"
              :id="`select-${id}`"
              :aria-describedby="ariaDescriptionIds"
              :aria-invalid="materialHasErrors"
              :disabled="disabled"
              :required="required">
        <option
          :value="null"
          disabled="disabled"
          selected>
          {{ placeholder }}
        </option>
        <option v-for="(option, index) in getSelectOptions()" :key="index" :value="option">
          {{ option }}
        </option>
      </select>
      <div class="generic-select-dropdown__arrow"/>
    </div>
    

    <div v-show="hasDescription" :id="`description-${id}`">
      <div class="generic-errors">
        <div v-for="(error, key) in computedErrors" class="generic-errors__item" :key="key">
          {{ error }}
        </div>
      </div>
      <slot name="helperText"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'generic-select',
    props: {
      id: {
        type: String,
        default: null,
      },
      name: {
        type: String,
        default: null,
      },
      type: {
        type: String,
        default: 'text',
      },
      value: {
        type: String,
        default: null,
      },
      label: {
        type: String,
        default: null,
      },
      cssClass: {
        type: String,
        default: null,
      },
      placeholder: {
        type: String,
        default: null,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: true,
      },
      errorMessages: {
        type: [Array, String],
        default: null,
      },
      options: {
        type: Object,
        default: null,
      },
      citiesoptions: {
        type: Object,
        default: null
      },
      zipcodeoptions: {
        type: Object,
        default: null
      },
      ApiKey: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        selectedOption: this.value,
        validateState: {
          invalid: false,
          notDefined: false,
        },
        focus: false,
        hadFocus: false,
        cities: [],
        zipcodes: [],
        cityValues: [],
        zipcodeValues: [],
        postCodeEnabled: true,
      };
    },
    computed: {
      computedErrors() {
        return typeof this.errorMessages === 'string'
          ? [this.errorMessages]
          : this.errorMessages;
      },
      ariaDescriptionIds() {
        return this.hasDescription ? `description-${this.id}` : null;
      },
      hasDescription() {
        return this.errorMessages || this.$slots.helperText;
      },
      materialHasErrors() {
        return this.validateState.invalid || this.hasErrorMessages;
      },
      hasErrorMessages() {
        return this.errorMessages !== null && this.errorMessages.length > 0;
      },
      computedClasses() {
        return {
          'generic--active': this.focus,
          'generic--disabled': this.disabled,
          'generic--readonly': this.readonly,
          'generic--has-errors': this.hasErrorMessages,
          'generic-select--not-selected': !this.selectedOption,
        };
      },
      defaultValue() {
        return this.options[this.value];
      },

    },
    created() {
      //console.log('this.options', this.options);
      localStorage.setItem('RegionName', '');
      localStorage.setItem('ZipcodeName', '');
      localStorage.setItem('cityValues', {});
      localStorage.setItem('zipcodeValues', '');
    },
    methods: {
      handleFocus (focused) {
        this.focus = focused;
        this.hadFocus = true;

        if (!focused) {
          this.handleValidation();
        }
      },
      handleOnChange() {
        this.$emit('input', this.selectedOption);
        this.handleValidation();
        switch (this.ApiKey) {
          case 'region':
            this.$emit('response', { name: 'cities', data: null, value: null });
            this.$emit('response', { name: 'zipcodes', data: null, value: null });
            this.getCityDetails(this.selectedOption);
            break;
          case 'city':
            this.$emit('response', { name: 'zipcodes', data: null, value: null });
            this.getZipDetails(this.selectedOption);
            break;
        }
      },
      handleValidation() {
        this.validateState.invalid = this.required && !this.selectedOption;
      },
      getCityDetails(selectedOption) {
        const cityApiurl = `https://${window.location.hostname}/apioap/genericform/city?regionName=` + selectedOption;
        fetch(cityApiurl, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json;charset=utf-8' },
          credentials: 'same-origin',
        })
          .then(response => response.json())
          .then(data => {
            this.citiesoptions = data;
            localStorage.setItem('RegionName', selectedOption);
            this.$emit('response', { name: 'cities', data: data || [] });
          });
        setTimeout(() => {
          document.getElementsByClassName('city')[0].value = '';
          document.getElementsByClassName('zipCode')[0].value = '';
        }, 200);
      },

      getZipDetails(selectedOption) {
        const RegionName = localStorage.getItem('RegionName');
        const windowLoc = window.location.hostname;

        const cityApiurl = 'https://' + windowLoc + '/apioap/genericform/postal?regionName=' + RegionName + '&cityName=' + selectedOption;
        fetch(cityApiurl, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json;charset=utf-8' },
          credentials: 'same-origin',
        })
          .then(response => response.json())
          .then(data => {
            this.zipcodeoptions = data;
            localStorage.setItem('ZipcodeName', selectedOption);
            localStorage.setItem('zipcodeValues', this.zipcodeoptions);
            this.$emit('response', { name: 'zipcodes', data: data || [] });
          });
        setTimeout(() => {
          document.getElementsByClassName('zipCode')[0].value = '';
        }, 200);
      },
      getSelectOptions() {
        if (this.cssClass === 'city') {
          return this.citiesoptions;
        } else if (this.cssClass === 'postCode_label') {
          return this.zipcodeoptions;
        } else {
          return this.options;
        }
      },
    },
  };
</script>
