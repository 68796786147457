<template>
  <div class="key-ingredients-component">
    <div class="key-ingredients-component-main-div">
      <h2 class="key-ingredients-component-title-class" v-html="title"></h2>
      <div class="key-ingredient1" v-if="!smallScreen">
        <div class="text-data-class -first">
          <div class="title-div">
            <span class="ingredient-title -first-title" v-html="decodeHtml(keyIngredientsArray[0].Title)"/>
          </div>
          <p class="ingredient-description -first-description" v-html="decodeHtml(keyIngredientsArray[0].Description)"/>
        </div>
        <div class="img-class half-circle-image1" v-show="keyIngredientsArray[0].Keyimage">
          <div class="line1"/>
          <img :src="keyIngredientsArray[0].Keyimage" :alt="keyIngredientsArray[0].Title" class="ingredient-image1">
        </div>
      </div>
      <div class="key-ingredient2" v-if="!smallScreen">
        <div class="img-class half-circle-image2" v-show="keyIngredientsArray[1].Keyimage">
          <div class="line2"/>
          <img :src="keyIngredientsArray[1].Keyimage" :alt="keyIngredientsArray[1].Title" class="ingredient-image2">
        </div>
        <div class="text-data-class -second">
          <div class="title-div">
            <span class="ingredient-title -second-title" v-html="decodeHtml(keyIngredientsArray[1].Title)"/>
          </div>
          <p class="ingredient-description -second-description" v-html="decodeHtml(keyIngredientsArray[1].Description)"/>
        </div>
      </div>
      <div class="key-ingredient3" v-if="keyIngredientsArray.length >= 3 && !smallScreen">
        <div class="text-data-class -third">
          <div class="title-div">
            <span class="ingredient-title -third-title" v-html="decodeHtml(keyIngredientsArray[2].Title)"/>
          </div>
          <p class="ingredient-description -third-description" v-html="decodeHtml(keyIngredientsArray[2].Description)"/>
        </div>
        <div class="img-class half-circle-image3" v-show="keyIngredientsArray[2].Keyimage ">
          <div class="line3"/>
          <img :src="keyIngredientsArray[2].Keyimage" :alt="keyIngredientsArray[2].Title" class="ingredient-image3">
        </div>
      </div>
      <div class="main-image-div" v-if="!smallScreen && packshotImageDesktop">
        <img :src="packshotImageDesktop" :alt="imgAlt" class="packshot-image">
      </div>
      <div v-if="smallScreen">
        <div class="mobile-ingredient-class">
          <div v-for="item in keyIngredientsArray" :key="item">
            <div class="mobile-title-div">
              <span class="mobile-ingredient-title" v-html="decodeHtml(item.Title)"/>
              <div class="mobile-line"/>
              <img :src="item.Keyimage" :alt="item.Title" class="mobile-key-ingredient-image">
            </div>
            <p class="mobile-ingredient-description" v-html="decodeHtml(item.Description)"/>
          </div>
        </div>
        <div class="mobile-image-main-div">
          <div class="mobile-img-class1">
            <img :src="keyIngredientsArray[0].Keyimage" :alt="keyIngredientsArray[0].Title" class="mobile-ingredient-image first">
          </div>
          <div class="mobile-img-class2">
            <img :src="keyIngredientsArray[1].Keyimage" :alt="keyIngredientsArray[1].Title" class="mobile-ingredient-image second">
          </div>
          <div class="mobile-img-class3" v-if="keyIngredientsArray.length >= 3">
            <img :src="keyIngredientsArray[2].Keyimage" :alt="keyIngredientsArray[2].Title" class="mobile-ingredient-image third">
          </div>
          <div v-else class="empty-image"/>
          <div v-if="packshotImageMobile" :class="keyIngredientsArray.length >= 3 ? 'main-image-div' : 'mobile-image'">
            <img :src="packshotImageMobile" :alt="imgAlt" class="packshot-image">
          </div>
        </div>
      </div>
    </div>
    <div class="dropdown-div">
      <p class="static-text" v-html="validityText"/>
      <collapse class="transcript__collapse" :aria-label="'show transcript'" v-if="otherIngredient">
        <template slot="title" slot-scope="scoped">
          <template v-if="scoped.collapsed">
            <button @click="pushTagging(showText)" class="showdiv">
              <span class="show-transcript" v-html="showText"/>
              <span v-show="showText" class="arrow down"/>
            </button>
          </template>
          <template v-else>
            <button @click="pushTagging(hideText)" class="hidediv">
              <span class="hide-transcript" v-html="hideText"/>
              <span v-show="hideText" class="arrow up"/>
            </button>
          </template>
        </template>
        <button class="no-js collapse-title-btn" aria-label=""/>
        <div v-html="otherIngredient" class="transrcript-class ingredient__list-all" data-ioplist=""/>
        <br >
        <p class="ingredient__filcode">{{ ingredientFilcode }}</p>
      </collapse>
    </div>
  </div>
</template>
<script>
  import Collapse from '@Feature/Collapse/code/Scripts/components/collapse.vue';
  import { AnalyticsHandler } from '@Foundation/analyticsHandler';
  export default {
    name: 'key-ingredients',

    components: {
      Collapse
    },

    props: {
      keyIngredientsArray: { type: Array / Object, required: true, default: '' },
      title: {
        type: String,
        default: ''
      },
      backgroundColor: {
        type: String,
        required: false,
        default: '#009FE3'
      },
      lineColor: {
        type: String,
        required: false,
        default: '#fff'
      },
      packshotImageDesktop: {
        type: String,
        required: false,
        default: ''
      },
      packshotImageMobile: {
        type: String,
        required: false,
        default: ''
      },
      ingredientFilcode: {
        type: String,
        required: false,
        default: ''
      },

      showText: { type: String, default: 'See full list of ingredients', required: false },
      hideText: { type: String, default: 'hide all ingredients', required: false },
      otherIngredient: { type: String, default: '', required: false },
      validityText: { type: String, default: '', required: false },
      productName: { type: String, required: false, default: '' },
      productId: { type: String, required: false, default: '' },
      imgAlt: { type: String, required: false, default: 'Technology and filters' },
    },
    data() {
      return {
        smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
      };
    },
    mounted() {
      if (this.youtubeId === '' && this.starDataImage === '') {
        this.textleft = true;
      }
      if (this.classic === '' || this.classic === 'false') {
        this.anthelios = true;
      }
    },
    created() {
      document.documentElement.style.setProperty('--background-color', this.backgroundColor);
      document.documentElement.style.setProperty('--line-color', this.lineColor);
    },

    destroyed() {
      document.documentElement.style.removeProperty('--background-color');
      document.documentElement.style.removeProperty('--line-color');
    },

    methods: {
      pushTagging(title) {
        AnalyticsHandler.pushDataLayer({
          'event': 'uaevent',
          'event_name': 'product_info_click',
          'ecommerce': 'undefined',
          'eventCategory': 'product detail page',
          'eventAction': title,
          'eventLabel': this.productName,
          'product_tab': title,
          'product_info': `${[this.productName]}::${[this.productId]}`,
        });
      },
      decodeHtml(html) {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
      },
    },
  };
</script>
<style lang="scss" src="./key-ingredients.scss"></style>
