<template>
  <div class="country-switcher" v-show="showModal">
    <div id="countryswitcher" class="overlay" :style="[showModal2 ? {'visibility':'hidden'} : {'visibility':'visible'}]">
      <div class="popup">
        <div class="popup-header">
          <div class="has-text-centered "><img class="logoicon" v-bind:src="fieldCountryLogo.Src" alt="lrp-logo" /></div>
          <span @click="showModal = false" class="close">×</span>
        </div>
        <div class="popup1img">
          <div class="content" v-html="fieldDescription" />
            <div class="flex">
            <div class="flex-item" v-for="continentList in fieldCountryList">
              <a href="#continentList" @click.prevent="showModal2 = true">
                <div v-on:click="selectCountry(continentList.countryname)">
                  <img v-bind:src="continentList.countryimage.Src" :alt='continentList.countryname' />
                  <div class="countrylabel">{{continentList.countryname}}</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="popin-overlay" @click="showModal = false"></div>
    </div>
    <div id="continentList" class="overlay" :style="[showModal2 ? {'visibility':'visible'} : {'visibility':'hidden'}]">
      <div class="regionpopup">
        <div class="popup-header">
          <a href="#countryswitcher" @click.prevent="showModal2 = false" class="back-icon"><em class="arrow left"></em></a>
          <div class="has-text-centered "><img class="logoicon" v-bind:src="fieldCountryLogo.Src" alt="lrp-logo" /></div>
          <span @click="showModal = false, showModal2 = false" class="close">×</span>
        </div>
        <div class="row">
          <div v-if="setCountryname == countrynameList.countryname" v-for="countrynameList in fieldCountryList">
            <div class="column first">
              <div id="countryimg">
                <span class="imgfordevice"> <img v-bind:src="countrynameList.countryimage.Src" alt='lrp' /></span>
                <div v-for="(regionlabelName,index) in countrynameList.countrysections">
                  <div v-if="index==0" class="sectionlabel">
                    {{regionlabelName.sectiontitle}}
                  </div>
                </div>
              </div>
            </div>
            <div class="column second">
              <div class="columndiv" v-for="(regionlabelName,index) in countrynameList.countrysections">

                <div v-for="linksData in regionlabelName.countrylinks" v-if="index==0 && regionlabelName.countrylinks.length>10">

                  <span class="labellist">
                    <p style="margin-top: 5px;">
                      <a :href="linksData.alternatelink.Url" v-tag:useractionevent="{
                          action: 'select::popincountry', 
                          category: 'main menu navigation', 
                          label: `${regionlabelName.sectiontitle.toLowerCase()}::${linksData.countrycode.toLowerCase()}`,
                          event_name: 'body_button_click',
                          cta_name: `${regionlabelName.sectiontitle.toLowerCase()}::${linksData.countrycode.toLowerCase()}`,
                          link_url: linksData.alternatelink.Url ? linksData.alternatelink.Url : 'none',
                          module_name: 'popincountry'
                        }" 
                        v-if="linksData.alternatelabel!=null" :target="linksData.alternatelink.Target"><span>{{ linksData.alternatelabel }} | </span></a>
                      <a :href="linksData.link.Url" :target="linksData.link.Target" v-tag:useractionevent="{
                        action: 'select::popincountry', 
                        category: 'main menu navigation', 
                        label: `${regionlabelName.sectiontitle.toLowerCase()}::${linksData.countrycode.toLowerCase()}`,
                        event_name: 'body_button_click',
                        cta_name: `${regionlabelName.sectiontitle.toLowerCase()}::${linksData.countrycode.toLowerCase()}`,
                        link_url: linksData.link.Url,
                        module_name: 'popincountry'
                     }">
                     <span>{{linksData.label}}</span>
                        <span class="shopicon_list" v-if="linksData.shopicon!=null && linksData.isshoppingsite">
                          <img  :alt="linksData.shopicon.Alt" v-bind:src="linksData.shopicon.Src" />
                        </span>
                      </a>
                    </p>
                  </span>
                </div>
              </div>
              <div class="columnnodiv" v-for="(regionlabelName,index) in countrynameList.countrysections">

                <div v-for="linksData in regionlabelName.countrylinks" v-if="index==0 && regionlabelName.countrylinks.length<10">

                  <span class="labellist">
                    <p style="margin-top: 5px;">
                      <a :href="linksData.alternatelink.Url" v-if="linksData.alternatelabel!=null" :target="linksData.alternatelink.Target"  v-tag:useractionevent="{
                        action: 'select::popincountry', 
                        category: 'main menu navigation', 
                        label: `${regionlabelName.sectiontitle.toLowerCase()}::${linksData.countrycode.toLowerCase()}`,
                        event_name: 'body_button_click',
                        cta_name: `${regionlabelName.sectiontitle.toLowerCase()}::${linksData.countrycode.toLowerCase()}`,
                        link_url: linksData.alternatelink.Url,
                        module_name: 'popincountry'
                        }"><span>{{ linksData.alternatelabel }} | </span></a>
                      <a :href="linksData.link.Url" :target="linksData.link.Target"  v-tag:useractionevent="{
                        action: 'select::popincountry', 
                        category: 'main menu navigation', 
                        label: `${regionlabelName.sectiontitle.toLowerCase()}::${linksData.countrycode.toLowerCase()}`,
                        event_name: 'body_button_click',
                        cta_name: `${regionlabelName.sectiontitle.toLowerCase()}::${linksData.countrycode.toLowerCase()}`,
                        link_url: linksData.link.Url,
                        module_name: 'popincountry'
                        }"><span>{{ linksData.label }}</span>
                        <span class="shopicon" v-if="linksData.shopicon!=null && linksData.isshoppingsite">
                          <img  :alt="linksData.shopicon.Alt" v-bind:src="linksData.shopicon.Src" />
                        </span>
                      </a>
                    </p>
                  </span>
                </div>
              </div>
            </div>
            <div class="column third">
              <div v-for="(regionlabelName,index)  in countrynameList.countrysections">
                <div v-if="index>0" class="divisionlabel">
                  {{regionlabelName.sectiontitle}}
                </div>
                <div v-for="linksData in regionlabelName.countrylinks" v-if="index>0" class="columndiv">
                  <span class="labellist">
                    <p>
                      <a :href="linksData.alternatelink.Url" v-if="linksData.alternatelabel!=null" :target="linksData.alternatelink.Target" v-tag:useractionevent="{
                        action: 'select::popincountry', 
                        category: 'main menu navigation', 
                        label: `${regionlabelName.sectiontitle.toLowerCase()}::${linksData.countrycode.toLowerCase()}`,
                        event_name: 'body_button_click',
                        cta_name: `${regionlabelName.sectiontitle.toLowerCase()}::${linksData.countrycode.toLowerCase()}`,
                        link_url: linksData.alternatelink.Url,
                        module_name: 'popincountry'
                        }"><span>{{ linksData.alternatelabel }} | </span></a>
                      <a :href="linksData.link.Url" :target="linksData.link.Target" v-tag:useractionevent={
                        action: 'select::popincountry', 
                        category: 'main menu navigation', 
                        label: `${regionlabelName.sectiontitle.toLowerCase()}::${linksData.countrycode.toLowerCase()}`,
                        event_name: 'body_button_click',
                        cta_name: `${regionlabelName.sectiontitle.toLowerCase()}::${linksData.countrycode.toLowerCase()}`,
                        link_url: linksData.link.Url,
                        module_name: 'popincountry'
                        }"><span>{{ linksData.label }}</span>
                        <span v-if="linksData.shopicon!=null && linksData.isshoppingsite">
                          <img :alt="linksData.shopicon.Alt" v-bind:src="linksData.shopicon.Src" />
                        </span>
                      </a>
                    </p>
                  </span>
              </div>
              </div>
            </div>
          </div>
          <div>
          </div>
        </div>
      </div>
      <div class="popin-overlay" @click="showModal = false, showModal2 = false"></div>
    </div>
  </div>
</template>
<script>
import { tag } from '@Foundation/analyticsHandler';

export default {
  name: 'country-switcher',
  directives: { tag
  },
  props: {
    fieldDescription: { type: String, required: true, default: undefined },
    fieldCountryLogo: { type: Object, required: true, default: undefined },
    fieldCountryList: { type: Array, required: true },
  },
  data() {
    return {
      showModal: false,
      showModal2: false,
      filteredCountryData: [],
      setCountryname: '',
      slectedCountryData: []
    }
  },

  mounted() {
    const main = document.querySelector('.linkCountry')
    if(main) {
      main.addEventListener('click', (e) => {
        e.preventDefault();
        this.showModal = true
      })
    }
  },

  methods: {
    selectCountry(getCountryname) {
      this.setCountryname = getCountryname
    },
  },
}
</script>
<style lang='scss' src="./country-switcher.scss"></style>
