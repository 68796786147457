<template>
  <fieldset :class="['generic-select generic-form__component', computedClasses]">
    <legend
      class="generic-select__label"
      v-show="isLabelVisible"
      :id="`dropdown-group-${id}`">
      {{ label }}
      <span v-if="required" class="required" aria-hidden="true">*</span>
    </legend>
    <div class="generic-select-dropdowns">
      <div class="generic-select-dropdown">
        <div :id="`label-day-${selectDayId}`" class="generic-select__label">
          {{ dateFieldPlaceholders.dayPlaceholder }}
        </div>
        <select
          class="generic-select__element"
          v-model="selectedDate.day"
          @focus="handleFocus(true, selectDayId)"
          @blur="handleFocus(false)"
          :id="selectDayId"
          :aria-labelledby="`label-day-${selectDayId} dropdown-group-${id}`"
          :aria-describedby="ariaDescriptionIds"
          :disabled="disabled || readonly"
          :required="required"
          :aria-invalid="isInvalid">
          <option
            v-if="defaultDateLabels && !defaultDateLabels.day"
            :value="null"
            disabled="disabled"
            :selected="!defaultDateLabels.day">
            {{ dateFieldPlaceholders.dayPlaceholder }}
          </option>
          <option :value="null" disabled="disabled">dd</option>
          <option
            v-for="(option, key) in date.days"
            :key="key"
            :value="option.value">
            {{ option.label }}
          </option>
        </select>
        <div class="generic-select-dropdown__arrow"/>
        <input type="hidden" name="Day" :value="selectedDate.day" >
      </div>

      <div class="generic-select-dropdown">
        <div :id="`label-month-${selectMonthId}`" class="generic-select__label">
          {{ dateFieldPlaceholders.monthPlaceholder }}
        </div>
        <select
          class="generic-select__element"
          v-model="selectedDate.month"
          @focus="handleFocus(true, selectMonthId)"
          @blur="handleFocus(false)"
          :id="selectMonthId"
          :aria-labelledby="`label-month-${selectMonthId} dropdown-group-${id}`"
          :aria-describedby="ariaDescriptionIds"
          :disabled="disabled || readonly"
          :required="required"
          :aria-invalid="isInvalid">
          <option
            v-if="defaultDateLabels && !defaultDateLabels.month"
            :value="null"
            disabled="disabled"
            :selected="!defaultDateLabels.month">
            {{ dateFieldPlaceholders.monthPlaceholder }}
          </option>
          <option :value="null" disabled="disabled">mm</option>
          <option
            v-for="(option, key) in date.months"
            :key="key"
            :value="option.value">
            {{ option.value }}
          </option>
        </select>
        <div class="generic-select-dropdown__arrow"/>
        <input type="hidden" name="Month" :value="selectedDate.month" >
      </div>

      <div class="generic-select-dropdown">
        <div :id="`label-year-${selectYearId}`" class="generic-select__label">
          {{ dateFieldPlaceholders.yearPlaceholder }}
        </div>
        <select
          class="generic-select__element"
          v-model="selectedDate.year"
          @focus="handleFocus(true, selectYearId)"
          @blur="handleFocus(false)"
          :id="selectYearId"
          :aria-labelledby="`label-year-${selectYearId} dropdown-group-${id}`"
          :aria-describedby="ariaDescriptionIds"
          :disabled="disabled || readonly"
          :required="required"
          :aria-invalid="isInvalid">
          <option
            v-if="defaultDateLabels && !defaultDateLabels.year"
            :value="null"
            disabled="disabled"
            :selected="!defaultDateLabels.year">
            {{ dateFieldPlaceholders.yearPlaceholder }}
          </option>
          <option :value="null" disabled="disabled">yyyy</option>
          <option
            v-for="(option, key) in date.years"
            :key="key"
            :value="option.value">
            {{ option.label }}
          </option>
        </select>
        <div class="generic-select-dropdown__arrow"/>
        <input type="hidden" name="Year" :value="selectedDate.year" >
      </div>
    </div>
    <div v-show="hasDescription" :id="`description-${id}`">
      <div class="generic-errors">
        <div
          v-for="(error, key) in errorMessages"
          class="generic-errors__item"
          :key="key">
          {{ error }}
        </div>
      </div>
      <slot name="helperText"/>
    </div>
  </fieldset>
</template>

<script>
  export default {
    name: 'generic-datepicker',
    props: {
      id: {
        type: String,
        default: null,
      },
      name: {
        type: String,
        default: null,
      },
      type: {
        type: String,
        default: 'text',
      },
      value: null,
      label: {
        type: String,
        default: null,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      required: {
        type: Boolean,
        default: true,
      },
      errorMessages: {
        type: [Array, String],
        default: null,
      },
      options: {
        type: [Array, String],
        default: null,
      },
      startYear: {
        default: 1940,
      },
      dateFieldPlaceholders: {
        type: Object,
        default() {
          return {
            dayPlaceholder: 'Day',
            monthPlaceholder: 'Month',
            yearPlaceholder: 'Year',
          };
        },
      },
    },
    data() {
      return {
        selectedDate: this.generateSelectDate(),
        date: {
          days: this.generateDays(),
          months: this.generateMonths(),
          years: this.generateYears(),
        },
        isInvalid: false,
        focus: false,
        hadFocus: false,
        defaultDateLabels: null
      };
    },
    computed: {
      computedClasses() {
        return {
          'generic--active': this.focus,
          'generic--disabled': this.disabled,
          'generic--readonly': this.readonly,
          'generic--has-errors': this.isInvalid,
        };
      },
      isLabelVisible() {
        return this.label != null && this.label !== '';
      },
      ariaDescriptionIds() {
        return this.hasDescription ? `description-${this.id}` : null;
      },
      hasDescription() {
        return this.errorMessages || this.$slots.helperText;
      },
      selectDayId() {
        return `day-${this.id}`;
      },
      selectMonthId() {
        return `month-${this.id}`;
      },
      selectYearId() {
        return `year-${this.id}`;
      },
    },
    watch: {
      selectedDate: {
        handler(newValue) {
          this.copyValue(newValue);
        },
        deep: true,
      },
      errorMessages: {
        handler() {
          this.handleValidation();
        },
        deep: true,
      },
    },
    beforeMount() {
      // Here we are following the Vue2 convention on custom v-model:
      // https://github.com/vuejs/vue/issues/2873#issuecomment-223759341
      this.copyValue(this.value);
    },
    mounted() {
      this.defaultDateLabels = this.value;
      this.setDefaultValues();
    },
    methods: {
      handleFocus(focused) {
        this.focus = focused;
        this.hadFocus = true;

        if (!focused) {
          this.handleValidation();
        }
      },
      setDefaultValues() {
        let formatedDate = null;
        if (this.selectedDate) {
          formatedDate = this.formatDate();
          formatedDate = formatedDate.includes('null') || formatedDate.includes('undefined') ? null : formatedDate;
          if (this.hadFocus || this.hasYearMonthDay()) {
            this.$emit('input', formatedDate);
          }
          if (formatedDate) {
            this.defaultDateLabels = null;
          }
        }
      },
      hasYearMonthDay() {
        return this.selectedDate.year && this.selectedDate.month && this.selectedDate.day;
      },
      formatDate() {
        return `${this.selectedDate.year}/${this.roundNumber(this.selectedDate.month)}/${this.roundNumber(this.selectedDate.day)}`;
      },
      handleOnChange() {
        this.setDefaultValues();
        this.handleValidation();
      },
      handleValidation() {
        this.isInvalid = this.isInvalidState();
      },
      isInvalidState() {
        return (this.errorMessages != null && this.errorMessages.length > 0);
      },
      copyValue(value) {
        if (!value) {
          this.selectedDate = this.generateSelectDate();
        } else {
          this.selectedDate = value;
          this.handleOnChange();
        }
      },
      roundNumber(number) {
        return !number ? null : `0${number}`.slice(-2);
      },
      generateDays() {
        const defaultDay = this.value ? this.value.day : null;
        const dObject = [];

        for (var d = 1; d < 32; d++) {
          const dateValue = this.roundNumber(d);
          dObject.push({
            selected: defaultDay && defaultDay == d,
            value: dateValue,
            label: dateValue,
          });
        }

        return dObject;
      },
      generateMonths() {
        const defaultMonth = this.value ? this.value.month : null;
        const dObject = [];

        for (var m = 0; m < 12; m++) {
          const date = new Date(2009, m, 10);
          const month = date.toLocaleString(navigator.language, {
            month: 'long',
          });
          const calculatedMonth = m + 1;
          const monthValue = this.roundNumber(calculatedMonth);
          dObject.push({
            selected: defaultMonth && defaultMonth == calculatedMonth,
            value: monthValue,
            label: month,
          });
        }

        return dObject;
      },
      generateYears() {
        const d = new Date();
        const currentYear = d.getUTCFullYear();
        const dObject = [];

        const defaultYear = this.value ? parseInt(this.value.year) : null;

        for (var y = currentYear; y >= this.startYear; y--) {
          dObject.push({
            selected: defaultYear && defaultYear == y,
            value: y,
            label: y,
          });
        }

        return dObject;
      },
      generateSelectDate() {
        return {
          day: null,
          month: null,
          year: null,
        };
      },
    }
  };
</script>
