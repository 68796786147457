<template>
  <div>
    <div class="realted-manual-article-v2">
        <carousel :bullets="true" :navigation="true" :item-per-page-desktop="3" page-category="product page" :next-panel-text="options.NextPanelText" :previous-panel-text="options.PreviousPanelText">
            <div slot="header" class="title__wrapper">
                <h3 class="heading" v-if="options.ArticleTitle" v-html="options.ArticleTitle"/>
            </div>
            <div v-for="(list, index) in options.RelatedPages" :key="index" >
                <div class="article-push">
                    <div class="article-push__content">
                        <responsive-image aspect-ratio="69" :srcset="list.SrcSet">
                            <!-- Accessibility -->
                            <img src="/frontend/master/images/placeholder.png" alt="placeholder" loading="lazy">
                            <noscript>
                                <!-- SEO-->
                                <img :src="list.ImageSrc" :alt="list.ImageAltText" loading="lazy">
                            </noscript>
                        </responsive-image>
                        <a :href="list.Link"
                            class="article-push__link btn__outside-elm"
                            tabindex="0" v-html="list.Title"
                            v-tag:useractionevent="{
                                event_name: 'body_button_click',
                                category: JSON.parse(list.Tagging).category, 
                                action: 'select::related article',
                                label:  JSON.parse(list.Tagging).label, 
                                cta_name: list.CtaLabel,
                                module_name: 'related article',
                                link_url:currentUrl() + list.Link,
                                article_name:  JSON.parse(list.Tagging).label
                            }">
                        </a>
                        <div :class="options.ctaClass">
                            <span class="btn__wrapper">
                                {{list.CtaLabel}}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </carousel>
    </div>
  </div>
</template>

<script>
import { tag } from '@Foundation/analyticsHandler';
import ResponsiveImage from '@Feature/ResponsiveImage/code/Scripts/components/responsive-image.vue';
import Carousel from '@Feature/Carousel/code/Scripts/components/carousel.vue';
export default {
name: 'related-manual-article',
    props:{
        options: {
            type: Object,
            default() {
            return undefined;
            }
        }
    },
    methods: {      
      currentUrl(){
          return window.location.origin;
      },
     },
    directives: {
      tag
    },
    components: { Carousel,ResponsiveImage }

}
</script>

<style>

</style>