<template>
  <div class="star-data-component"
       :class="[{'anthelios': classic === 'false'}, {'classic':classic === 'true'},{'textleft':textleft === 'true'}, {'textright':textleft === 'false'}]">
    <div class="star-data-component-background-div" :style= "[ smallScreen ? { backgroundImage: 'url(' + mobileBackgroundImage + ')'} : { backgroundImage: 'url(' + backgroundImage + ')'} ]">
      <div class="main-div">
        <h2 class="title-div" v-html="title"/>
        <div class="star-data-component-main-div">
          <div class="star-data-component-text-div">
            <div class="star-data-component-text-data-div">
              <div class="data-percentage-div" v-html="starDataPercentage"/>
              <div class="star-title-div" v-html="starTitleText"/>
              <hr v-show="starDataDescription">
              <div class="star-description-div" v-html="starDataDescription"/>
            </div>
          </div>
          <div class="star-data-component-image-div">
            <img v-if="!youtubeId" :src="starDataImage" :alt="imgAlt" class="imageclass">
            <generic-banner-youtube-player v-else-if="youtubeId && videoPopup === 'true'" :youtube-id="youtubeId"
                                           :poster="poster"
                                           autoplay="true"
                                           aria-label="Play video"
                                           newplay-button="true"
                                           :play-text="playText"/>
            <youtube-player v-else-if="youtubeId && videoPopup === 'false'" :youtube-id="youtubeId"
                            :poster="poster"
                            autoplay="true"
                            aria-label="Play video"
                            :newplay-button="true"
                            :play-text="playText"/>
            <collapse v-show="youtubeId" class="transcript__collapse" :aria-label="'show transcript'">
              <template slot="title" slot-scope="scoped">
                <template v-if="scoped.collapsed">
                  <span class="show-transcript" v-html="showTranscript"/><span class="arrow down"/></template>
                <template v-else>
                  <span class="hide-transcript" v-html="hideTranscript"/><span class="arrow up"/></template>
              </template>
              <button class="no-js collapse-title-btn" aria-label=""/>
              <div v-html="transcriptText" class="transrcript-class"/>
            </collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import GenericBannerYoutubePlayer from '@Feature/GenericBannerYoutubePlayer/code/Scripts/components/generic-banner-youtube-player.vue';
  import YoutubePlayer from '@Feature/YoutubePlayer/code/Scripts/components/youtube-player.vue';
  import Collapse from '@Feature/Collapse/code/Scripts/components/collapse.vue';
  export default {
    name: 'star-data',

    components: {
      GenericBannerYoutubePlayer,
      YoutubePlayer,
      Collapse
    },
    props: {
      backgroundImage: {
        type: String,
        default: undefined
      },
      mobileBackgroundImage: {
        type: String,
        default: undefined
      },
      classic: {
        type: String,
        required: false,
        default: undefined
      },
      title: {
        type: String,
        default: undefined
      },
      textleft: {
        type: String,
        required: false,
        default: undefined
      },
      starDataPercentage: {
        type: String,
        required: false,
        default: undefined
      },
      starTitleText: {
        type: String,
        required: false,
        default: undefined
      },
      starDataDescription: {
        type: String,
        required: false,
        default: undefined
      },
      starDataImage: {
        type: String,
        required: false,
        default: undefined
      },
      imgAlt: {
        type: String,
        required: false,
        default: undefined
      },
      themeColor: {
        type: String,
        default: '#009FE3'
      },
      playText: {
        type: String,
        required: false,
        default: undefined
      },
      youtubeId: { type: String, required: false, default: undefined },
      poster: { type: String, default: undefined },
      videoPopup: { type: String, required: false, default: undefined },
      autoplay: { type: Boolean, required: false, default: undefined },
      ariaLabel: { type: String, default: 'play pause', required: false },
      showTranscript: { type: String, default: 'show transcript', required: false },
      hideTranscript: { type: String, default: 'hide transcript', required: false },
      transcriptText: { type: String, default: 'Lorem ipsum dolor, sit amet consectetur adipisicing elit. Aut aliquam maxime perferendis molestias sed aliquid necessitatibus impedit, repellat consectetur sit repudiandae eius sint illo laborum.', required: false }
    },
    data() {
      return {
        smallScreen: !window.matchMedia('(min-width: 1024px)').matches,
      };
    },
    mounted() {
      if (this.youtubeId === '' && this.starDataImage === '') {
        this.textleft = true;
      }
      if (this.classic === '' || this.classic === 'false') {
        this.anthelios = true;
      }
    },
    created() {
      document.documentElement.style.setProperty('--theme-color', this.themeColor);
    },

    destroyed() {
      document.documentElement.style.removeProperty('---theme-color');
    },
    methods: {
    }
  };
</script>
<style lang="scss" src="./star-data.scss"></style>
