var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"realted-manual-article-v2"},[_c('carousel',{attrs:{"bullets":true,"navigation":true,"item-per-page-desktop":3,"page-category":"product page","next-panel-text":_vm.options.NextPanelText,"previous-panel-text":_vm.options.PreviousPanelText}},[_c('div',{staticClass:"title__wrapper",attrs:{"slot":"header"},slot:"header"},[(_vm.options.ArticleTitle)?_c('h3',{staticClass:"heading",domProps:{"innerHTML":_vm._s(_vm.options.ArticleTitle)}}):_vm._e()]),_vm._v(" "),_vm._l((_vm.options.RelatedPages),function(list,index){return _c('div',{key:index},[_c('div',{staticClass:"article-push"},[_c('div',{staticClass:"article-push__content"},[_c('responsive-image',{attrs:{"aspect-ratio":"69","srcset":list.SrcSet}},[_c('img',{attrs:{"src":"/frontend/master/images/placeholder.png","alt":"placeholder","loading":"lazy"}}),_vm._v(" "),_c('noscript',[_c('img',{attrs:{"src":list.ImageSrc,"alt":list.ImageAltText,"loading":"lazy"}})])]),_vm._v(" "),_c('a',{directives:[{name:"tag",rawName:"v-tag:useractionevent",value:({
                              event_name: 'body_button_click',
                              category: JSON.parse(list.Tagging).category, 
                              action: 'select::related article',
                              label:  JSON.parse(list.Tagging).label, 
                              cta_name: list.CtaLabel,
                              module_name: 'related article',
                              link_url:_vm.currentUrl() + list.Link,
                              article_name:  JSON.parse(list.Tagging).label
                          }),expression:"{\n                              event_name: 'body_button_click',\n                              category: JSON.parse(list.Tagging).category, \n                              action: 'select::related article',\n                              label:  JSON.parse(list.Tagging).label, \n                              cta_name: list.CtaLabel,\n                              module_name: 'related article',\n                              link_url:currentUrl() + list.Link,\n                              article_name:  JSON.parse(list.Tagging).label\n                          }",arg:"useractionevent"}],staticClass:"article-push__link btn__outside-elm",attrs:{"href":list.Link,"tabindex":"0"},domProps:{"innerHTML":_vm._s(list.Title)}}),_vm._v(" "),_c('div',{class:_vm.options.ctaClass},[_c('span',{staticClass:"btn__wrapper"},[_vm._v("\n                              "+_vm._s(list.CtaLabel)+"\n                          ")])])],1)])])})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }