<template>
  <div class="proven-wrapper" :style="[backgroundImage ? {'background-image': 'url(' + backgroundImage + ')'} : {'background':bgColor}]">
    <h2 class="h1" :style="{'color':textColor}" v-html="title"/>
    <div class="grid-container" :class="[cards && cards.length > 3 ? 'more-cols':'']">
      <div class="grid-col" v-for="(item, index) in cards" :key="index">
        <hr class="horizontal-line">
        <span class="proven-head" :style="{'color':textColor}" v-html="item.BenefitsTitle"/>
        <p class="proven-desc" :style="{'color':textColor}" v-html="item.BenefitsDescription"/>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'proven-benefits',

    props: {
      activeColor: { type: String, required: true, default: '#009fe3' },
      title: { type: String, default: '' },
      cards: {
        type: [Array, String],
        required: false,
        default: undefined
      },
      bgColor: { type: String, required: true, default: '#fff' },
      bgImage: { type: String, required: true, default: '' },
      textColor: { type: String, required: true, default: '#000' },
    },
    data() {
      return {
        smallScreen: !window.matchMedia('(min-width: 1023px)').matches,
        backgroundImage: ''
      };
    },
    created() {
      document.documentElement.style.setProperty('--active-color', this.activeColor);
      if (!this.smallScreen) {
        this.backgroundImage = this.bgImage;
      } else {
        this.backgroundImage = '';
      }
    },
    destroyed() {
      document.documentElement.style.removeProperty('--active-color');
      document.querySelector('.grid-container').classList.remove('more-cols');
    }
  };
</script>
<style lang="scss" src="./proven-benefits.scss"></style>
