<template>
  <div class="simple-list" ref="simpleList">
    <div slot="header" class="title__wrapper">
      <h2 class="heading" v-html="headingTitle"/>
    </div>
    <ul>
      <li class="simple-list__item" v-for="(item, index) in simpleData" :key="index">
        <a
          :href="item.link" class="simple-list__link" 
          v-tag:useractionevent="{
            category: `${pageCategory}`,
            event_name: 'body_button_click',
            action: 'select::ingredient',
            label: `${concerntext(item.concerntext)}::${item.link != null ? currentUrl() + item.link.toLowerCase() : 'none'}`,
            module_name: 'ingredient',
            cta_name: `${concerntext(item.concerntext)}`,
            link_url: `${item.link != null ? currentUrl() + item.link.toLowerCase() : 'none'}`,
          }"
        >
          <span v-html="item.concerntext"/>
        </a>
      </li>
    </ul>
    <div v-if="simpleData.length > 14" class="btn btn--black btn--back">
      <a class="btn__wrapper" @click="gotoTop()">{{ backTo }}</a>
    </div>
    <br >
    <div v-if="listObject.length !== simpleData.length" class="btn btn--black btn--loadmore">
      <a class="btn__wrapper" @click="loadMoreData()">{{ loadMore }} </a>
    </div>
  </div>
</template>
<script>
  import { tag } from '@Foundation/analyticsHandler';
  export default {
    name: 'simple-list',
    directives: {
      tag
    },
    props: {
      headingTitle: {
        type: String,
        default: 'How can'
      },
      headingSubtitle: {
        type: String,
        default: 'we help you?'
      },
      pageCategory: {
        type: String,
        default: 'content page'
      },
      listObject: {
        type: Array,
        required: true
      },
      loadMore: {
        type: String,
        default: 'load more'
      },
      backTo: {
        type: String,
        default: 'back to top'
      }
    },
    data() {
      return {
        settings: {
          'dots': true,
          'arrows': true,
          'infinite': false,
          'speed': 500,
          'slidesToShow': 3,
          'initialSlide': 0,
          'responsive': [{
            'breakpoint': 992,
            'settings': { 'slidesToShow': 3 }
          }, {
            'breakpoint': 480,
            'settings': { 'slidesToShow': 1 }
          }]
        },
        relSticky: 0,
        visiblePrev: false,
        visibleNext: false,
        active: false,
        headerActive: false,
        currentLink: 0,
        Navlinks: [],
        mobileScreen: window.matchMedia('(max-width: 992px)').matches,
        simpleData: this.listObject
      };
    },
    mounted() {
      this.simpleData = this.listObject;
      this.loadMoreData();
    },
    methods: {
      concerntext(param){
          return param.replace(/<\/?[^>]+(>|$)/g, '').toLowerCase()
      },
      currentUrl(){
          return window.location.origin;
      },
      screenMobile() {
        eventBus.$on('mediaquery::changed', mq => {
          this.mobileScreen = !!(mq.size === 'small' || mq.size === 'medium');
        });
      },
      loadMoreData() {
        this.simpleData = this.simpleData.concat(this.listObject.slice(this.simpleData.length, this.simpleData.length + 14));
      },
      gotoTop() {
        const element = this.$refs.simpleList, offset = element.offsetTop;

        window.scrollTo({
          top: offset-140,
          behavior: "smooth"
        });
      }
    }
  };
</script>
<style lang="scss" src="./simple-list.scss"></style>
