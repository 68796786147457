<template>
  <div>
    <slot name="title"></slot>
    <slot :showpopin="showPopin"></slot>
  </div>
</template>
<script>
  import { tag } from '@Foundation/analyticsHandler';
  import { srcset } from '@Foundation/directives/srcset/srcset';

  export default {
    directives: { tag, srcset },
    methods: {
      /**
       * Triggers the link for the requested product
       * A11Y requires one link wrapped on the product title
       * @param {number} product - Number of which product link to trigger (1 or 2)
       * @returns {undefined}
       */
      triggerLink(product) {
        this.$el.getElementsByClassName('js-link')[product - 1].click();
      },
      showPopin(productId) {
        const ProductInfo = {
          id: productId,
          disableColorTheme: false
        }
        window.eventBus.$emit('openProductPopin', ProductInfo)
      }
    },
  };
</script>

<style lang='scss' src="./product-battle.scss"></style>
