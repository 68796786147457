<template>
  <fieldset
    class="generic-options generic-form__component"
    :class="computedClasses" >
    <legend class="generic-form__label" v-if="$slots.default">
      <slot/>
      <span v-if="required" class="required" aria-hidden="true">*</span>
    </legend>
    <div class="optionsOuter" @mouseout="handleMouseOut()">
      <div class="input-block" v-for="(field, index) in options" :class="{ selectedradio: checkedItems >= index }" :text="index" :checkvalue="checkedItems" :key="index">
        <label class="label-wrapper" :class="{ hoverradio: hovercheckedItems >= index }" @mouseover="handleMouseOver(index)">
          <input
            class="checkbox-input form-control"
            v-model="checkedItems"
            :type="type"
            :value="index"
            :disabled="disabled || readonly"
            :required="required"
            :aria-invalid="materialHasErrors"
            :aria-describedby="ariaDescriptionIds"
            @focus="handleFocus(true)"
            @blur="handleFocus(false)"
            @input="handleModelInput"
          >
          <span v-if="!readonly" class="checkmark"/>
          <span class="checkbox-input__text" v-html="options[index]"/>
        </label>
      </div>
    </div>

    <div v-show="hasDescription" :id="`description-${id}`">
      <div class="generic-errors">
        <div
          v-for="(error, key) in computedErrors"
          class="generic-errors__item"
          :key="key">
          {{ error }}
        </div>
      </div>
      <slot name="helperText"/>
    </div>
  </fieldset>
</template>

<script>
  export default {
    name: 'generic-options',
    props: {
      id: {
        type: String,
        default: null,
      },
      name: {
        type: String,
        default: null,
      },
      type: {
        type: String,
        default: 'text',
      },
      value: {
        default: null,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: null,
      },
      required: {
        type: Boolean,
        default: true,
      },
      errorMessages: {
        type: [Array, String],
        default: null,
      },
      options: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        checkedItems: [],
        hovercheckedItems: 0,
        itemsCopy: [],
        focus: false,
        hadFocus: false,
        valid: true,
        started: false,
      };
    },
    computed: {
      ariaDescriptionIds() {
        return this.hasDescription ? `description-${this.id}` : null;
      },
      hasDescription() {
        return this.errorMessages || this.$slots.helperText;
      },
      computedErrors() {
        return typeof this.errorMessages === 'string'
          ? [this.errorMessages]
          : this.errorMessages;
      },
      materialHasErrors() {
        return Boolean(
          (this.hadFocus && !this.valid) ||
            (this.errorMessages && this.errorMessages.length)
        );
      },
      computedClasses() {
        return {
          'generic--active': this.focus,
          'generic--disabled': this.disabled,
          'generic--readonly': this.readonly,
          'generic--has-errors': this.materialHasErrors,
          'generic--raised': true,
          'generic--invisible-label': !this.isLabelVisible
        };
      },
      isLabelVisible() {
        return Boolean(!this.isEmpty(this.$slots.default));
      },
      notCheckedYet() {
        return this.type == 'checkbox' && !this.checkedItems;
      }
    },
    watch: {
      value(newValue) {
        // This watch works from the code side of the 2-way-binding:
        this.copyValue(newValue);
      },
    },
    beforeMount() {
      // Here we are following the Vue2 convention on custom v-model:
      // https://github.com/vuejs/vue/issues/2873#issuecomment-223759341
      this.copyValue(this.value);
    },
    mounted() {
      if (this.value) {
        this.handleDefaultValue();
      }
    },
    methods: {
      handleDefaultValue() {
        const emittedValue = this.isArrayEmpty(this.value) ? '' : this.getDefaultValue();
        this.$emit('input', emittedValue, undefined);
      },
      getDefaultValue() {
        const hasDefaultValueOnOptions = this.value &&
          !this.isArrayEmpty(this.value) &&
          this.value.length > 0;
        if (!hasDefaultValueOnOptions && this.value.filter(value => this.options[value]).length > 0) {
          return '';
        }

        if (this.value.length <= 1) {
          return this.value[0];
        }
        return typeof this.value === 'string' ? this.value : this.value.join(',');
      },
      isArrayEmpty(arr) {
        if (!arr || !Array.isArray(arr)) {
          return 0;
        }
        /* istanbul ignore next */
        return arr.filter(el => {
          el === '' || el === undefined || el === null;
        }).length;
      },
      handleMouseOut() {
        this.hovercheckedItems = 0;
      },
      handleMouseOver(indexVal) {
        this.hovercheckedItems = indexVal;
      },
      handleModelInput(event) {
        /* istanbul ignore next */
        if (!this.started) {
          this.itemsCopy = this.checkedItems;
        }

        if (this.type == 'radio' || this.notCheckedYet) {
          this.itemsCopy = [];
        }

        this.handleCheckedOptions(event.target);
        this.$emit('input', this.getSelectedOptions(), event);
        this.handleValidation();
        this.started = true;
      },
      getSelectedOptions() {
        return this.itemsCopy.length > 1 ? this.itemsCopy.join(',') : (this.itemsCopy[0] || '');
      },
      handleCheckedOptions(target) {
        if (target.checked) {
          this.itemsCopy.push(target.value);
        } else {
          const index = this.itemsCopy.indexOf(target.value);

          if (index >= 0) {
            this.itemsCopy.splice(index, 1);
          }
        }
      },
      handleFocus(focused) {
        this.focus = focused;
        this.hadFocus = true;

        if (!focused) {
          this.handleValidation();
        }
      },
      handleValidation() {
        this.valid = !this.required || this.itemsCopy.length > 0;
      },
      copyValue(value) {
        if (value && this.type != 'radio') {
          value = Array.isArray(value) ? value : value.split(',');
        }
        this.checkedItems = value;
        this.handleValidation();
      },
      reset() {
        this.hadFocus = false;
        this.valid = true;
        this.focus = false;
      },
      isEmpty(value) {
        return typeof value === 'undefined' || value == null;
      },
    }
  };
</script>
