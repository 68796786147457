import { eventBus } from '@EventBus';

let element;
let item;
let size;
let selectedItems;
let heightArray;
let heighest;

export const equalheight = { // eslint-disable-line
  name: 'equal-height',
  bind: (el, binding) => {
    element = el;
    item = binding.value.selector;
    size = binding.value.media;
    selectedItems = element.querySelectorAll(`.${item}`);

    /* istanbul ignore else */
    if (window.matchMedia(size).matches) {
      setTimeout(() => {
        heightArray = [...selectedItems].map(block => block.clientHeight);
        heighest = Math.max(...heightArray);
        [...selectedItems].forEach(block => block.style.height = `${heighest}px`);
      }, 300);
    }

    eventBus.$on('equalHeight::update', selector => {
      /* istanbul ignore else */
      if (item === selector) {
        [...selectedItems].forEach(block => block.style.height = '');
        setTimeout(() => {
          heightArray = [...selectedItems].map(block => block.clientHeight);
          heighest = Math.max(...heightArray);
          [...selectedItems].forEach(block => block.style.height = `${heighest}px`);
        }, 300);
      }
    });

    eventBus.$on('mediaquery::changed', () => {
      if (window.matchMedia(size).matches) {
        [...selectedItems].forEach(block => block.style.height = '');

        setTimeout(() => {
          heightArray = [...selectedItems].map(block => block.clientHeight);
          heighest = Math.max(...heightArray);
          [...selectedItems].forEach(block => block.style.height = `${heighest}px`);
        }, 300);
      } else {
        [...selectedItems].forEach(block => block.style.height = '');
      }
    });
  }
};
