var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"glossary-filter"},[_c('ul',{ref:"containerFilter",staticClass:"filter"},[_vm._l((_vm.apiData.Ingredientcategories),function(groupAlpha,idx){return [_c('li',{key:idx,class:{ selected: _vm.selectedCategory == groupAlpha.Category },on:{"click":function($event){(_vm.selectedCategory = groupAlpha.Category), (_vm.currentPage = 1), (_vm.page = 1)}}},[_c('p',[_vm._v(_vm._s(groupAlpha.Category))])])]})],2),_vm._v(" "),_vm._l((_vm.ingredientData),function(data,dataIdx){return [_c('div',{key:dataIdx},[_c('Ingredient',{directives:[{name:"show",rawName:"v-show",value:(data.Description != ''),expression:"data.Description != ''"}],attrs:{"title":data.Title,"index":data.Index,"data-group":data.Group,"description":data.Description,"cta-link":data.CtaLink,"page-category":_vm.pageCategory}}),_vm._v(" "),(data.IsStar)?_c('IngredientStared',{attrs:{"index":data.Index,"data-group":data.Group,"title":data.ImgGroup.Title,"description":data.ImgGroup.Description,"img-group":data.ImgGroup.MosaicItemsList,"cta-text":data.ImgGroup.StarCtaText,"cta-link":data.ImgGroup.StarCtaLink}}):_vm._e()],1)]}),_vm._v(" "),[_c('div',{staticClass:"col-xs-12"},[_c('nav',{staticClass:"ingredient-pagination"},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.numberOfPages > 1),expression:"numberOfPages > 1"}]},[(_vm.currentPage !== 1)?_c('li',{staticClass:"pagination-previous arrow"},[_c('button',{staticClass:"li-pagination-btn",attrs:{"name":"li-btn","type":"button"},on:{"click":function($event){_vm.page = _vm.page - 1;
                _vm.scrollTo();}}},[_vm._v("\n              ❮ prev\n            ")])]):_vm._e(),_vm._v(" "),_vm._l((_vm.pages.slice(0, 3)),function(pageNumber,pageidx){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentPage < _vm.maxPagesForSimpleView),expression:"currentPage < maxPagesForSimpleView"}],key:'l-' + pageidx,staticClass:"number list"},[_c('button',{staticClass:"li-pagination-btn",class:{ 'li-btn-active': _vm.currentPage === pageidx + 1 },attrs:{"id":`btn-${pageNumber}`,"name":"li-btn","type":"button"},on:{"click":function($event){_vm.page = pageNumber;
                _vm.scrollTo();}}},[_vm._v("\n              "+_vm._s(pageNumber)+"\n            ")])])}),_vm._v(" "),(_vm.currentPage >= _vm.maxPagesForSimpleView)?_c('li',{staticClass:"number list",on:{"click":_vm.page}},[_c('button',{staticClass:"li-pagination-btn",attrs:{"id":`btn-1`,"name":"li-btn","type":"button"},on:{"click":function($event){_vm.page = 1;
                _vm.scrollTo();}}},[_vm._v("\n              1\n            ")])]):_vm._e(),_vm._v(" "),(_vm.currentPage >= _vm.maxPagesForSimpleView)?_c('li',{staticClass:"number list"},[_vm._v("...")]):_vm._e(),_vm._v(" "),_vm._l((_vm.getPagesToShow()),function(pageNumber,pageNext){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentPage >= _vm.maxPagesForSimpleView),expression:"currentPage >= maxPagesForSimpleView"}],key:pageNext},[_c('button',{staticClass:"li-pagination-btn",class:{ 'li-btn-active': _vm.currentPage === pageNumber },attrs:{"id":`btn-${pageNumber}`,"name":"li-btn","type":"button"},on:{"click":function($event){_vm.page = pageNumber;
                _vm.scrollTo();}}},[_vm._v("\n              "+_vm._s(pageNumber)+"\n            ")])])}),_vm._v(" "),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.numberOfPages > _vm.maxPagesForSimpleView && _vm.currentPage < _vm.numberOfPages - 2),expression:"numberOfPages > maxPagesForSimpleView && currentPage < numberOfPages - 2"}],staticClass:"number list"},[_vm._v("\n            ...\n          ")]),_vm._v(" "),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.numberOfPages > _vm.maxPagesForSimpleView),expression:"numberOfPages > maxPagesForSimpleView"}]},[_c('button',{staticClass:"li-pagination-btn",class:{ 'li-btn-active': _vm.currentPage === _vm.numberOfPages },attrs:{"id":`btn-${_vm.numberOfPages}`,"name":"li-btn","type":"button"},on:{"click":function($event){_vm.page = _vm.numberOfPages;
                _vm.scrollTo();}}},[_vm._v("\n              "+_vm._s(_vm.numberOfPages)+"\n            ")])]),_vm._v(" "),(_vm.currentPage !== _vm.numberOfPages)?_c('li',{staticClass:"pagination-next arrow"},[_c('button',{staticClass:"li-pagination-btn",attrs:{"name":"li-btn","type":"button"},on:{"click":function($event){_vm.page = _vm.page + 1;
                _vm.scrollTo();}}},[_vm._v("\n              next ❯\n            ")])]):_vm._e()],2)])])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }