<template>
  <div/>
</template>
<script>
  export default {
    name: 'question-ans-schema-markup',
    props: {
      dataQuestionAns: { type: Array, required: false },
      dataTrueFalse: { type: Array, required: false },
    },
    data() {
      return {
        newArray: [],
        i: 0 ,
        schema: ''
      };
    },

    mounted() {
      const lengthQa = document.querySelectorAll('.question-ans-schema-markup').length;

      if (this.dataTrueFalse) {
        this.$store.commit('dataTrueFalse', this.dataTrueFalse);
      }

      if (this.dataQuestionAns) {
        this.$store.commit('dataQuestionAns', this.dataQuestionAns);
      }

      if (lengthQa >= 2) {
        this.arraryMerge();
      }

      if (lengthQa === 1) {
        this.singlearray();
      }

      this.generateQa();
    },
    methods: {

      arraryMerge() {

        const cookieData = this.$store.getters.getDataTrueFalse;
        const questionAns = this.$store.getters.getDataQuestionAns;

        this.newArray = [];
        if (cookieData.length > 0 && questionAns.length > 0) {
          this.i = this.i + 1;
          const data1 = questionAns;
          const data2 = cookieData;
          this.newArray = data1.concat(data2);
          this.arrayloop();
        }
      },

      singlearray() {
        this.newArray = [];
        if (this.dataQuestionAns) {
          this.newArray = this.dataQuestionAns;
          this.arrayloop();
        } else if (this .dataTrueFalse) {
          this.newArray = this.dataTrueFalse;
          this.arrayloop();
        }
      },

      arrayloop() {
        if (this.newArray.length > 0) {
          var arraycount = this.newArray.length;
          var arraycomma ='';
          this.newArray.forEach((data ,index) => {
            if(index < arraycount -1){
              arraycomma =',';
            }else{
              arraycomma ='';
            }
            this.schema += '{ ' + '\n' +
              ' "@type": "' + data.Question + '",' + '\n' +
              ' "name": " ' + data.Name + '",' + '\n' +
              ' "acceptedAnswer": {' + '\n' +
              ' "@type": "Answer",' + '\n' +
              ' "text": "' + data.acceptedAnswer.AnswerText + ' " ' + '\n' +
              ' }' + '\n' +
              ' }' + arraycomma + '\n';
          });
        }
      },

      generateQa() {
        if (this.schema !== '') {
          const f =  '\n' + '{' + '\n' +
            ' "@context": "https://schema.org", ' + '\n' +
            ' "@type": "FAQPage",' + '\n' +
            ' "@id": "/faq#1",' + '\n' +
            ' "mainEntity": [ ' + '\n' + this.schema + '\n' +
            ' ] ' + '\n' +
            ' }'  + '\n' ;

          const script = document.createElement('script');
          script.type = 'application/ld+json';
          script.text = f;
          document.head.append(script);
        }
      }
    },
  };
</script>
