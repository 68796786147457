<template>
  <div class="safety-standard-wrapper" :style="{'background':bgColor}">
    <div class="container container--padded-hor">
      <h2 class="title" v-html="title" />
      <div class="grid-container">
        <div class="grid-col" v-for="(item, index) in cards" :key="index">
          <hr class="horizontal-line" :style="{'background':lineColor}">
          <div class="safety-standard-desc" v-html="item.SafetyCardDescription"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'safety-standard',
    props: {
      lineColor: { type: String, required: true, default: '#009fe3' },
      title: { type: String, default: '' },
      cards: {
        type: [Array, String],
        required: false,
        default: undefined
      },
      bgColor: { type: String, required: true, default: '#fff' },
    }
  };
</script>


<style lang='scss' src="./safety-standard.scss"></style>
