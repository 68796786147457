import Vue from 'vue';
import GenericForm from './code/Scripts/Components/generic-form.vue';
import hubSampleform from './code/Scripts/Components/hub-sampleform.vue';
import GenericInput from './code/Scripts/Components/generic-input.vue';
import GenericOptions from './code/Scripts/Components/generic-options.vue';
import GenericDatepicker from './code/Scripts/Components/generic-datepicker.vue';
import GenericSelect from './code/Scripts/Components/generic-select.vue';

Vue.component(GenericForm.name, GenericForm);
Vue.component(hubSampleform.name, hubSampleform);
Vue.component(GenericInput.name, GenericInput);
Vue.component(GenericOptions.name, GenericOptions);
Vue.component(GenericDatepicker.name, GenericDatepicker);
Vue.component(GenericSelect.name, GenericSelect);

export default {
  components: {
    GenericForm,
    hubSampleform,
    GenericInput,
    GenericOptions,
    GenericDatepicker,
    GenericSelect
  },
};
