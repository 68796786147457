<template>
  <section class="pdp-stikcy-bar">
    <div class="sticky-wrapper desktop" :class="[ scrollProduct ? 'show-sticky':'hide-sticky']" v-if="!smallScreen">
      <div class="sticky-bg">
        <div class="innerContainer">
          <div class="sticky__title" v-html="pfdTitle"/>
          <div class="CTAContainer">
            <div class="sticky__select">
              <div class="sticky__select--select_capacity">
                <volume-variant-v2
                  :volumes="volumesDetail"
                  :legend-name="capacityText"
                  a11y-aria-label="legend-volume current-volume"
                  :sfccvolumesdetails="variantDetail" :popup-dropdown="false" :productid="productIds"/>
              </div>
              <div class="sticky__select--select_color" v-if="colorList.length > 0">
                <color-variant-v2 a11y-close-panel-text= "panelText"
                                  a11y-next-panel-text= "nextPanelText"
                                  a11y-previous-panel-text= "previousPanelText"
                                  :colors="colorList"
                                  :legend-name="colorText"
                                  itemprop="color"
                                  :productid="productIds"
                                  :sfcccolorsdetails="colorsDetails" :popup-dropdown="false"/>
              </div>
            </div>
            <div class="buttonwrapper">
              <lrp-retailer :data-variant-ean="dataVariantEan" :initial-data="initialData" :data-text-lable="dataTextLable" class="sticky__buynow-button"  :data-bynow-tagging="dataBynowTagging" :sticky-buynow-button="true" :error-messgage="errorMessgage" v-if="isLrpRetailerEnabled == true" :nav-tagging-label="navTaggingLabel" :page-category="pageCategory"></lrp-retailer>
              <buy-now v-if="isSfccconnectorEnabled != true && isCommerceConnectorActivated == true" :buynow-text="buyonlineText" class="sticky__buynow-button" :is-online="true" :product-ean="productEan" :product-id="productId" :product-name="productName" :tag-event="true"/>
              <div v-if="getData.length >= 1 && isClickandCollectEnable" class="pharmacyBtn sticky__clickandcollect ">
                <template v-for="(item,index) in getData">
                  <div :key="index"  :data-index="index" v-if="item.variantid === selectedVariant">
                    <a  target="_blank" v-bind:key="index" :href="item.pharmacylink" @click="clickandCollectTag()" class="pharmacyBtn sticky__pharmacyBtn"> {{ clickCollectLabel }} </a>
                  </div>
                </template>
              </div>
              <a v-if="findPharmacyStatus" :href="findPharmacyLink" class="pharmacyBtn sticky__pharmacyBtn" @click="findformacyTag"> {{ findPharmacyText }} </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sticky-wrapper mobile" :class="[ scrollProductMobile ? 'show-sticky':'hide-sticky']" v-else>
      <buy-now v-if="volumesDetail.length <= 1 && colorList.length <= 1 && isCommerceConnectorActivated == true" :buynow-text="buyonlineText" class="sticky__buynow-button" :is-online="true" :product-ean="productEan" :product-id="productId" :product-name="productName" :tag-event="true" :sticky-buynow-button="false"/>
      <buy-now v-if="((volumesDetail.length >= 0 && colorList.length > 1) || (volumesDetail.length > 1 && colorList.length >= 0)) && isCommerceConnectorActivated == true" :product-img="productImg" :product-ids="productIds" :color-text="colorText" :capacity-text="capacityText" :color-list="colorList" :colors-details="colorsDetails" :volumes-detail="volumesDetail" :variant-detail="variantDetail" :buynow-text="buyonlineText" class="sticky__buynow-button" :sticky-buynow-button="true" :pfd-title="pfdTitle" :product-ean="productEan" :product-id="productId" :product-name="productName" :tag-event="true"/>
      <lrp-retailer v-if="volumesDetail.length <= 1 && colorList.length <= 1 && isLrpRetailerEnabled  == true" :data-variant-ean="dataVariantEan" :initial-data="initialData" :data-text-lable="dataTextLable" class="sticky__buynow-button"  :data-bynow-tagging="dataBynowTagging" :error-messgage="errorMessgage" :sticky-buynow-button="true" :one-variant="true" :nav-tagging-label="navTaggingLabel" :page-category="pageCategory"></lrp-retailer>
      <lrp-retailer v-if="((volumesDetail.length >= 0 && colorList.length > 1) || (volumesDetail.length > 1 && colorList.length >= 0)) && isLrpRetailerEnabled == true"  :data-variant-ean="dataVariantEan" :initial-data="initialData" :data-text-lable="dataTextLable" class="sticky__buynow-button"
                    :data-bynow-tagging="dataBynowTagging" :sticky-buynow-button="true" :product-img="productImg" :product-ids="productIds" :color-text="colorText" :capacity-text="capacityText" :color-list="colorList" :colors-details="colorsDetails" :volumes-detail="volumesDetail" :variant-detail="variantDetail" :pfd-title="pfdTitle" :product-ean="productEan" :one-variant="false" :product-id="productId" :error-messgage="errorMessgage" :product-name="productName" :tag-event="true" :nav-tagging-label="navTaggingLabel" :page-category="pageCategory"></lrp-retailer>
      <a v-if="findPharmacyStatus" :href="findPharmacyLink" class="pharmacyBtn sticky__pharmacyBtn" @click="findformacyTag"> {{ findPharmacyText }} </a>
      <div v-if="getData.length >= 1 && isClickandCollectEnable" class="pharmacyBtn sticky__clickandcollect ">
        <template v-for="(item,index) in getData">
          <div :key="index"  :data-index="index" v-if="item.variantid === selectedVariant">
            <a  target="_blank" v-bind:key="index" :href="item.pharmacylink" @click="clickandCollectTag()" class="pharmacyBtn sticky__pharmacyBtn"> {{ clickCollectLabel }} </a>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>
<script>
import ColorVariantV2 from '@Feature/ColorVariant-V2/code/Scripts/components/color-variant-v2';
import VolumeVariantV2 from '@Feature/VolumeVariant-V2/code/Scripts/components/volume-variant-v2';
import BuyNow from '@Feature/buynow/code/Scripts/components/buynow.vue';
import LrpRetailer from '@Feature/LrpRetailer/code/Scripts/components/lrp-retailer.vue';
import { AnalyticsHandler } from '@Foundation/analyticsHandler';
export default {
  name: 'sticky-bar',
  components: {
    ColorVariantV2,
    VolumeVariantV2,
    BuyNow,
    LrpRetailer
  },
  props: {
    getData: { type: Array, required: false, default: [] },
    isClickandCollectEnable: { type: Boolean, required: false, default: false },
    isSfccconnectorEnabled: { type: Boolean, required: false, default: false },
    isCommerceConnectorActivated: { type: Boolean, required: false, default: true },
    clickCollectLabel: { type: String, required: false, default: '' },
    maxQuantityOfProduct: { type: String, required: false, default: '10' },
    productImg: { type: String, required: false, default: '' },
    activeColor: { type: String, required: false, default: '#009FE3' },
    pfdTitle: { type: String, required: false, default: '<strong>TOLERIANE</strong> <br/> SENSITIVE <br/>LE TEINT CREME' },
    buyonlineText: { type: String, required: false, default: 'Buy online' },
    findPharmacyText: { type: String, required: false, default: 'Find A pharmacy' },
    findPharmacyLink: { type: String, required: false, default: '' },
    capacityText: { type: String, required: false, default: 'Capacity' },
    colorText: { type: String, required: false, default: 'Color' },
    variantDetail: { type: Array, required: false, default: [] },
    volumesDetail: { type: Array, required: false, default: [] },
    colorsDetails: { type: Array, required: false, default: [] },
    colorList: { type: Array, required: false, default: [] },
    productIds: { type: Array, required: false, default: [] },
    checkouturl: { type: String, required: false, default: '' },
    buttonlabel: { type: String, required: false, default: '' },
    productlabel: { type: String, required: false, default: '' },
    inyourcartlabel: { type: String, required: false, default: '' },
    cartemptymessage: { type: String, required: false, default: '' },
    totalpricelabel: { type: String, required: false, default: '' },
    mybasketlabel: { type: String, required: false, default: '' },
    productName: { type: String, required: false, default: '' },
    productId: { type: String, required: false, default: '' },
    productEan: { type: String, required: false, default: '' },
    dataVariantEan: { type: String, required: false, default: '' },
    initialData: { type: Object, required: false, default: [] },
    dataBynowTagging: { type: Object, required: false, default: [] },
    errorMessgage: { type: String, required: false, default: '' },
    dataTextLable: { type: String, required: false, default: '' },
    isLrpRetailerEnabled : { type: Boolean, required: false, default: '' },
    findPharmacyStatus : { type: Boolean, required: false, default: '' },
    oneVariant : { type: Boolean, required: false, default: '' },
    dataClickandCollectTagging: { type: Array, required: false, default: [] },
    navTaggingLabel: { type: [String, Array], required: false, default: '' },
    pageCategory: { type: String, required: false, default: 'page category' },

  },
  data() {
    return {
      smallScreen: !window.matchMedia('(min-width:  1023px)').matches,
      scrollProduct: false,
      scrollProductMobile: false,
      selectedVariant: ''
    };
  },
  created() {
    document.documentElement.style.setProperty('--active-color', this.activeColor);
  },
  mounted() {
    this.selectedVariant = this.getData.length >= 1 ? this.getData[0].defaultvariantid : ''
    window.addEventListener('scroll',() => {
      var issticky = document.querySelector('.main-menu').classList.contains('is-sticky');
      var el = document.getElementById('showSticky');
      var movEl;
      if(el != null) {
        movEl = el.offsetTop + el.offsetHeight;
      }
      var pageEnd = window.pageYOffset + window.innerHeight > document.body.clientHeight - 10;

      if (issticky) {

        if(this.smallScreen){
          if(pageEnd){
            this.scrollProductMobile =false;
          }else{
            this.scrollProductMobile =true;
          }
        }
        var topNav =  document.querySelector('.main-menu.is-sticky').offsetHeight + 74;
        var actulHeight = movEl - topNav;
        var res = actulHeight - document.documentElement.scrollTop;
        if (res <= 0 && pageEnd == false) {
          this.scrollProduct = true;
        } else {
          this.scrollProduct = false;
        }
      } else {
        res = movEl - document.documentElement.scrollTop;
        if (res <= 0 && pageEnd == false) {
          this.scrollProduct = true;
        } else {
          this.scrollProduct = false;
        }
      }
    });
  },
  destroyed() {
    document.documentElement.style.removeProperty('--active-color');
  },
  methods: {
    clickandCollectTag(){
      AnalyticsHandler.pushDataLayer({ ...this.dataClickandCollectTagging[0].tagging});
    },
    findformacyTag() {
      AnalyticsHandler.pushDataLayer({
        'event': 'uaevent',
        'event_name': 'where_to_buy',
        'ecommerce': 'undefined',
        'eventCategory': 'Ecommerce',
        'eventAction': 'where to buy',
        'eventLabel': `${[this.productName]}::${[this.productEan]}`,
        'product_info': `${[this.productName]}::${[this.productEan]}`
      });
    },
  }
};
</script>
<style lang="scss" src="./sticky-bar.scss"></style>
