<template>
  <div class="field field--radio" role="group">   
        <input class="input"
              type="radio"
              :id="value"
              name='ratings'
              :value="value"
              :checked="value === radioValue"
              @change="handleRadioChange(value)" />
        <!-- <span class="switch" /> -->
        <label class="radio" :for="value">
          {{ displayText }}
        </label>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex';
  import { QueryToObject } from '../../../../LrpProducts/code/Scripts/services/query-to-object';
  export default {
    data() {
      return {
        radioValue: '',
      }
    },
  props: {
      value: {
        type: String,
        required: true,
      },
      displayText: {
        type: String,
        required: true
      }
    },
    beforeMount() {
      /**
       * Handle old query state for new sessions.
       */
      let queries = QueryToObject(window.location.search).sortBy;
      queries && this.handleRadioChange(queries.toString(),true);
    },
    methods: {
      ...mapActions(['sortBy']),

      handleRadioChange(value, isRefresh = false) {
        //Prevent the outside click directive in product filter.
        this.$store.commit('setDropdownRef', true);
        setTimeout(() => {
          this.$store.commit('setDropdownRef', false);
        }, 50)
        
        this.radioValue = value;  
        isRefresh ? this.$store.commit('sortBy',value) : this.sortBy(value)
        
      },
    },

    computed: {
      ...mapGetters({
        sortByValue : 'getSortBy'
      }),
    },

    watch: {
      sortByValue: {
        handler() {
          !this.sortByValue && (this.radioValue = '');
        }
      }
    }
  };
</script>

<style lang='scss' src="./radio-selection.scss"></style>