<template>
  <div class="accordions__wrapper">
    <div class="accordion__list" v-for="(list, index) in listData" :key="index">
      <button class="accordion"><h3 v-html="list.title"> </h3></button>
      <span class="accordion-btn" :class="list.isDefault ? 'is-open' : ''" @click="pushTagging(list.accordionTitleForTagging);toggle(index)"/>
      <div class="accordion-content" :class="list.name === 'text-component' ? 'text-block' : ''">
        <div v-if="list.name === 'text-component'">
          <div v-bind:key="textDataIndex" v-for="(textData, textDataIndex) in list.optionsData">
            <div v-html="textData"></div>
          </div>
        </div>
        <component v-else :is="list.name" :options="list.optionsData" :page-category="pageCategory"/>
      </div>
    </div>
  </div>
</template>

<script>
  import { AnalyticsHandler } from '@Foundation/analyticsHandler';
  import ArticlePushV2 from '@Feature/ArticlePush-V2/code/Scripts/components/article-push-v2.vue';
  import SustainabilityModuleV2 from '@Feature/Sustainability-V2/code/Scripts/components/sustainability-module-v2.vue';
  import Faq from '@Feature/FAQ/code/Scripts/components/faq.vue'
  import RelatedManualArticle from '@Feature/RelatedManualArticle/code/Scripts/components/related-manual-article.vue'
  export default {
    name: 'accordions',
    components: { ArticlePushV2, SustainabilityModuleV2,Faq,RelatedManualArticle},
    props: {
      accordionsData: { type: Object, required: true, default() {
        return undefined;
      } },
      articleData: { type: Object, required: true, default() {
        return undefined;
      } },
      pilInitialData: {
        type: Object,
        default() {
          return undefined;
        }
      },
      pilVariantData: {
        type: Array,
        default() {
          return undefined;
        }
      },
      productName: { type: String, required: false, default: '' },
      productId: { type: String, required: false, default: '' },
      productEan: { type: String, required: false, default: '' },
      faqData: { type: Object, required: true, default() {
        return undefined;
      } },
      pageCategory:{ type: String, required: false, default: '' },
      textData:{type: Array, required: true, default() {
        return undefined;
      }},
      manualArticle:{type: Object, required: false, default() {
        return undefined;
      }}
    },
    data() {
      return {
        listData: [],
        activeVariantData: [],
        defaultVaraintValueData: []
      };
    },
    created() {
      const titleList = this.accordionsData.Cards;
      titleList.forEach(element => {
        const list = {};
        if (element.AccordionComponent === 'PIL') {
          const pilData = {};
          pilData.initialData = this.pilInitialData;
          pilData.variantData = this.pilVariantData;
          this.pilVariantData.forEach(item => {
            if (item.ShowSustainability === true) {
              this.activeVariantData.push(item.VariantEanId);
            }
          });
          this.pilVariantData.forEach(item => {
            if (item.IsDefaultVariant === 'true') {
              this.defaultVaraintValueData = item.VariantEanId;
            }
          });
          const defaultSelected = this.activeVariantData.includes(this.defaultVaraintValueData);
          if (defaultSelected) {
            list.title = element.AccordionTitle;
            list.accordionTitleForTagging = element.AccordionTitleForTagging;
            list.name = 'sustainability-module-v2';
            list.isDefault = element.AccordionByDefault;
            list.optionsData = pilData;
            this.listData.push(list);
          }
          
        } else if (element.AccordionComponent === 'ArticlePush') {
          list.title = element.AccordionTitle;
          list.accordionTitleForTagging = element.AccordionTitleForTagging;
          list.name = 'article-push-v2';
          list.isDefault = element.AccordionByDefault;
          list.optionsData = this.articleData;
          this.listData.push(list);
        }
        else if (element.AccordionComponent === 'FAQ') {
          list.title = element.AccordionTitle;
          list.accordionTitleForTagging = element.AccordionTitleForTagging;
          list.name = 'faq';
          list.isDefault = element.AccordionByDefault;
          list.optionsData = this.faqData;
          this.listData.push(list);
        }
        else if (element.AccordionComponent === 'RecycleIndication') {
          list.title = element.AccordionTitle;
          list.accordionTitleForTagging = element.AccordionTitleForTagging;
          list.name = 'text-component';
          list.isDefault = element.AccordionByDefault;
          list.optionsData = this.textData;
          this.listData.push(list);
        }
        else if (element.AccordionComponent === 'RelatedArticleManual') {
          list.title = element.AccordionTitle;
          list.accordionTitleForTagging = element.AccordionTitleForTagging;
          list.name = 'related-manual-article';
          list.isDefault = element.AccordionByDefault;
          list.optionsData = this.manualArticle;
          this.listData.push(list);
        }
      });
    },
    methods: {
      pushTagging(title) {
        AnalyticsHandler.pushDataLayer({
          'event': 'uaevent',
          'event_name': 'product_info_click',
          'ecommerce': 'undefined',
          'eventCategory': 'product detail page',
          'eventAction': title,
          'eventLabel': this.productName,
          'product_tab': title,
          'product_info': `${[this.productName]}::${[this.productEan]}`,
        });
      },
      toggle(index){
         this.listData[index].isDefault = !this.listData[index].isDefault;
      }
    },
  };
</script>
<style lang="scss" src="./accordions.scss"></style>
